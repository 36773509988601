import { gql } from "@apollo/client";

export const getPublishedCarsMonthly = gql`
  query getPublishedCarsMonthly(
    $dateFrom: Date
  ) {
    getPublishedCarsMonthly(dateFrom: $dateFrom) {
        month
        publishedCars
        soldCars
        allPublishedCars
  }
}
`;

export const getRevenueMontly = gql`
  query getRevenueMontly {
    getRevenueMontly {
      month
      sellingPriceTotal
      purchasedPriceTotal
    }
}`;

export const GET_VEHICLES_LIST = gql`
    query getVehicles {
        cars {
            nodes {
                id
                brandId
                carModelId
                year
                versionId
                fuelType
                doors
                condtion
                mileage
                color
                address
                transmissionType
                controlTraccion
                engine
                carType
                firstOwner
                sellerType
                observations
                sellingPrice
                purchasedPrice
                carAvgPrice {
                  price
                  amountSoldLastMonth
                }
                brand {
                  name
                }
                brand_model {
                  name
                }
                version {
                  name
                }
                auction {
                    id
                    carId
                    initialPrice
                    incrementUnit
                    observations
                    endedAt
                }
            }
        }
    }
`;