import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Breadcrumbs,
  Link,
} from "@material-ui/core";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { OrderForm } from "../components";
import { gql, useMutation, useQuery, useApolloClient } from "@apollo/client";
import { useToastDispatch } from "../../../context/ToastContext";

const CREATE_ORDER = gql`
  mutation createOrder($input: CreateOrderInput!) {
    createOrder(input: $input) {
      id
    }
  }
`;
export const OrderCreate = () => {
  const client = useApolloClient();
  const toastDispatch = useToastDispatch();
  const history = useHistory();
  const [createOrder, {loading: mutationLoading}] = useMutation(CREATE_ORDER);
  
  const handleSubmit = value => {
    const input = {
      carId: parseInt(value.carId),
      customerId: parseInt(value.customerId),
      soldPrice: parseFloat(value.soldPrice),
    }
    createOrder({ variables: { input } }).then(async () => {
      await client.clearStore();
      toastDispatch({
        isOpen: true,
        type: "success",
        message: "La orden de venta fue creada exitosamente",
      });
      history.push("/app/orders");
    });
  };

  return (
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" component={RouterLink} to="/app/orders">
              Order
            </Link>
            <Link
              color="textPrimary"
              component={RouterLink}
              to="/app/orders/create"
              aria-current="page"
            >
              Crear una orden
            </Link>
          </Breadcrumbs>
          <Card>
            <CardHeader title="Crear una orden" />
            <CardContent>
              <OrderForm
                data={{}}
                submitText="Crear"
                onSubmit={handleSubmit}
                loading={mutationLoading}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
  );
};
