import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Breadcrumbs,
  Link,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { UserForm } from "../components";
import { gql, useMutation, useQuery, useApolloClient } from "@apollo/client";
import { useToastDispatch } from "../../../context/ToastContext";

const CREATE_USER = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
    }
  }
`;
const GET_ACCOUNTS_LIST = gql`
  query getAccounts {
    accounts {
      nodes {
        id
        name
      }
    }
  }
`;
export const UserCreate = () => {
  const client = useApolloClient();
  const toastDispatch = useToastDispatch();

  const { data: accounts, loading: loadingQuery, error: errorQuery } = useQuery(
    GET_ACCOUNTS_LIST,
  );
  const [createUser, {loading: mutationLoading}] = useMutation(CREATE_USER);
  
  const handleSubmit = value => {
    const input = {
      name: value.name,
      phone: value.phone,
      email: value.email,
      password: value.password,
      role: value.role,
      active: value.active,
      accountId: value.accountId ? parseInt(value.accountId) : null,
      comments: value.comments,
      setup: value.setup,
    }
    createUser({ variables: { input } }).then(() => {
      client.clearStore();
      toastDispatch({
        isOpen: true,
        type: "success",
        message: "User is successfully created",
      });
    });
  };

  if (loadingQuery) {
    return <p>Loading...</p>;
  }
  if (errorQuery) {
    return <p>Error</p>;
  }
  return (
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" component={RouterLink} to="/admin/users">
              User
            </Link>
            <Link
              color="textPrimary"
              component={RouterLink}
              to="/admin/users/create"
              aria-current="page"
            >
              Crear Una User
            </Link>
          </Breadcrumbs>
          <Card>
            <CardHeader title="Crear Una User" />
            <CardContent>
              <UserForm
                accounts={accounts.accounts.nodes}
                data={{}}
                submitText="Crear"
                onSubmit={handleSubmit}
                loading={mutationLoading}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
  );
};
