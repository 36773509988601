import React from "react";
import {
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";


// context
import { useLayoutState } from "../../context/LayoutContext";
import { AccountsTable, AccountCreate, AccountEdit } from "../../pages/accounts";
import { UsersTable, UserCreate, UserEdit } from "../../pages/users";
import { MessageTable, MessageEdit, MessageCreate} from "../../pages/messages";
import { CityRatesTable , CityRatesCreate , CityRatesEdit } from "../../pages/cityRates";

function LayoutAdminComponent(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
        <>
          <Header history={props.history} />
          <Sidebar />
          <div
            className={classnames(classes.content, {
              [classes.contentShift]: layoutState.isSidebarOpened,
            })}
          >
            <div className={classes.fakeToolbar} />
            <Switch>
              <Route path="/admin/accounts/create" component={AccountCreate} />
              <Route path="/admin/accounts/edit/:id" component={AccountEdit} />
              <Route path="/admin/accounts" component={AccountsTable} />
              <Route path="/admin/users/create" component={UserCreate} />
              <Route path="/admin/users/edit/:id" component={UserEdit} />
              <Route path="/admin/users" component={UsersTable} />
              <Route path="/admin/messages/create" component={MessageCreate} />
              <Route path="/admin/messages/edit/:id" component={MessageEdit} />
              <Route path="/admin/messages" component={MessageTable} />
              <Route path="/admin/cityrates/create" component={CityRatesCreate} />
              <Route path="/admin/cityrates/edit/:id" component={CityRatesEdit} />
              <Route path="/admin/cityrates" component={CityRatesTable} />
            </Switch>
          </div>
        </>
    </div>
  );
}

export const LayoutAdmin = withRouter(LayoutAdminComponent);

