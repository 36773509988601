import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { gql, useQuery } from '@apollo/client';
import NumberFormat from 'react-number-format';
import { useTheme } from "@material-ui/styles";
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  LineChart,
  Line,
  Area,
  PieChart,
  Pie,
  Cell,
  Series,
  YAxis,
  XAxis,
  Bar,
  Tooltip
} from "recharts";

import useStyles from "../../../dashboard/styles";
// components
import PageTitle from "../../../../components/PageTitle/PageTitle";
import Widget from "../../../../components/Widget/Widget";
import { Typography } from "../../../../components/Wrappers/Wrappers";
import Dot from "../../../../components/Sidebar/components/Dot";
import ChartXYData from "../../components/ChartXYData";

const getMostSoldCarsByBrandQuery = gql`
query getMostSoldBrands($fromDate: DateTime, $toDate: DateTime) {
	getMostSoldBrands (fromDate: $fromDate, toDate: $toDate){
	rows {
    totalSoldCars
    totalPriceBrand
   	brand {
      name
    }
  }
  totalCount
}
}
`;

const pieColors = [
  '#52D726',
  '#FFEC00',
  '#FF7300',
  '#007ED6',
  '#7CDDDD'
];

export default function MostSoldCarsByBrands() {
  var classes = useStyles();
  var theme = useTheme();
  const { loading, error, data } = useQuery(getMostSoldCarsByBrandQuery,
    { fetchPolicy: "cache-and-network" }
  );

  if (loading) return 'Cargando...';
  if (error) return `Error! ${error.message}`;
  const soldCarsData = data.getMostSoldBrands.rows;
  const soldCarsDataTop = soldCarsData.slice(0, 5);

  var soldCarsTop = [];
  soldCarsDataTop.map((item, index) => {
    soldCarsTop.push({
      "brand": item.brand.name,
      "soldCarsAmount": parseInt(item.totalSoldCars),
      "color": pieColors[index]
    })
  });


  const columns = [
    {
      name: "Marca"
    },
    {
      name: "Cantidad Vendidos",
      options: { filter: false }
    },
    {
      name: "Capitalización de mercado",
      options:
        { filter: false }
    },
    {
      name: "Precio promedio vehículo",
      options:
      {
        filter: false
      }
    }
  ]
  const datatableOptions = {
    filterType: "checkbox",
    useDisplayedColumnsOnly: true
  };

  const getMostSoldBrandsGroupByMonth = gql`
  query getMostSoldBrandsGroupByMonth($fromDate: DateTime, $toDate: DateTime, $brandId: Int, $modelId: Int, $days: Int) {
    getMostSoldBrandsGroupByMonth (fromDate: $fromDate, toDate: $toDate, brandId: $brandId, modelId: $modelId, days: $days){
      rows {
        totalSoldCars
        soldMonth
        soldYear
          brand {
          name
        }
      }
      totalCount
    }
  }
  `;
  return (
    <>
      <PageTitle title="Tendencia de vehículos más vendidos por marca" />
      <Grid container item xs={12} spacing={2} alignItems="center" justify="center" >
        <ChartXYData
          brand={true}
          mdSize={6}
          xsSize={10}
          query={getMostSoldBrandsGroupByMonth}
          queryField="getMostSoldBrandsGroupByMonth"
          YLegend="Vehículos vendidos"
        />
        <Grid item xs={5}>
          <Widget title="Top 5 de marcas por los últimos 90 días (cantidad de ventas)" upperTitle className={classes.card}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <ResponsiveContainer width="100%" minHeight={365}>
                  <PieChart margin={{ left: theme.spacing(2) }}>
                    <Pie
                      data={soldCarsTop}
                      innerRadius={50}
                      outerRadius={80}
                      dataKey="soldCarsAmount"
                      label
                      isAnimationActive={true}
                      labelLine={true}
                    >
                      {soldCarsTop.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={pieColors[index]}
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </Grid>
              <Grid item xs={4}>
                <div className={classes.pieChartLegendWrapper}>
                  {soldCarsTop.map(({ brand, soldCarsAmount }, index) => (
                    <div key={pieColors[index]} className={classes.legendItemContainer}>
                      <Dot color={pieColors[index]} />
                      <Typography style={{ whiteSpace: "nowrap" }}>
                        &nbsp;{brand}&nbsp;
                      </Typography>
                      <Typography color="text" colorBrightness="secondary">
                        &nbsp;{soldCarsAmount}
                      </Typography>
                    </div>
                  ))}
                </div>
              </Grid>
            </Grid>
          </Widget>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12}>
            &nbsp;
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MUIDataTable
              title="Vehículos vendidos por marca"
              data={soldCarsData.map(item => {
                return [
                  item.brand.name,
                  item.totalSoldCars,
                  <NumberFormat value={item.totalPriceBrand} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={'2'} />,
                  <NumberFormat value={item.totalPriceBrand / item.totalSoldCars} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={'2'} />
                ]
              })}
              columns={columns}
              options={datatableOptions}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
