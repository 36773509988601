import React from "react";
import { TextField, Button, CircularProgress } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: "15px 5px",
    flex: "1 1 45%",
    textAlign: "left",
  },
  buttonWrap: {
    flex: "1 1 100%",
    display: "flex",
    alignItems: "flex-end",
  },
  submitButton: {
    marginTop: "1rem",
  },
  loading: {
    marginLeft: "1rem",
  },
}));

export const ProfileForm = ({ data, submitText, onSubmit, loading }) => {
  const classes = useStyles();

  const { register, handleSubmit, errors } = useForm();

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <TextField
        className={classes.formControl}
        defaultValue={data.name}
        error={errors.name ? true : false}
        helperText={errors.name ? errors.name.message : ""}
        InputLabelProps={{ required: true }}
        inputRef={register({ required: "name is required" })}
        name="name"
        label="Nombre y apellido"
        variant="outlined"
      />
      <TextField
        className={classes.formControl}
        defaultValue={data.phone}
        error={errors.phone ? true : false}
        helperText={errors.phone ? errors.phone.message : ""}
        InputLabelProps={{ required: true }}
        inputRef={register({ required: "phone is required" })}
        label="Teléfono"
        name="phone"
        variant="outlined"
      />
      <TextField
        className={classes.formControl}
        defaultValue={data.email}
        error={errors.email ? true : false}
        helperText={errors.email ? errors.email.message : ""}
        InputLabelProps={{ required: true }}
        inputRef={register({
          required: "email is required",
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "invalid email address",
          },
        })}
        label="Email"
        name="email"
        variant="outlined"
      />
      <TextField
        className={classes.formControl}
        defaultValue={data.comments}
        inputRef={register}
        label="Comentarios"
        name="comments"
        multiline
        rows={3}
        variant="outlined"
      />
      <TextField
        className={classes.formControl}
        defaultValue={data.smtpEmail}
        inputRef={register}
        label="Email SMTP"
        name="smtpEmail"
        rows={3}
        variant="outlined"
      />
        <TextField
        className={classes.formControl}
        defaultValue={data.smtpPassword}
        inputRef={register({
          required: "password is required",
        })}
        label="Contraseña email SMTP"
        name="smtpPassword"
        type='password'
        rows={3}
        variant="outlined"
      />
        <TextField
        className={classes.formControl}
        defaultValue={data.smtpPort}
        inputRef={register}
        label="Puerto SMTP"
        name="smtpPort"
        rows={3}
        variant="outlined"
      />
        <TextField
        className={classes.formControl}
        defaultValue={data.smtpHost}
        inputRef={register}
        label="Host SMTP"
        name="smtpHost"
        rows={3}
        variant="outlined"
      /> 
        <TextField
        className={classes.formControl}
        defaultValue={data.smtpSubject}
        inputRef={register}
        label="Asunto de email"
        name="smtpSubject"
        rows={3}
        variant="outlined"
      /> 
      <TextField
        className={classes.formControl}
        defaultValue={data.emailTemplate}
        inputRef={register}
        label="Plantilla de email"
        multiline
        name="emailTemplate"
        rows={3}
        variant="outlined"
      />
      <div className={classes.buttonWrap}>
        <Button
          className={classes.submitButton}
          disabled={loading}
          variant="contained"
          color="primary"
          type="submit"
        >
          {submitText}
        </Button>
        {loading && <CircularProgress size={35} className={classes.loading} />}
      </div>
    </form>
  );
};
