import React, { useEffect, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import {
  Button,
  createMuiTheme,
  Grid,
  ThemeProvider,
  makeStyles,
  Switch,
  Tooltip,
  Fab,
} from "@material-ui/core";
import {
  Email as EmailIcon,
} from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import NumberFormat from 'react-number-format';
import * as moment from 'moment'

// components
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import  BudgetOneCar  from '../BudgetOneCar';
import BudgetPreview from "../BudgetPreview";
import translation from "../../translations/translations_muidatatable";

const theme = createMuiTheme({
  overrides: {
    MuiTable: {
      root: {
        border: [[1, "solid", "#d3d3d3"]],
        marginBottom:"3rem"
      },
    },
    MuiTableCell: {
      root: {
        borderColor: "#d3d3d3",
      },
      head: {
        background: "lightgrey",
        fontWeight:"bold"
      },
    },
    MuiTableSortLabel: {
      root: {
        alignItems: "flex-start",
      },
    },
    MuiTableFooter: {
      root: {
        background: "lightgrey",
      },
    },
  },
});
const useStyles = makeStyles(theme => ({
  action: {
    minWidth: "163px",
    alignContent:"center"
  },
  margin: {
    position:"fixed",
    right:'10%',
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  title:{
    textAlign:"center",
    marginBottom:"1rem"
  },
  subtitle:{
    marginBottom:"1rem"
  },
}));
const GET_ALL_BUDGET_CARS_WITH_USER = gql`
  query getAllBudgetCarsWithUser ($userId: ID!) {
    getAllBudgetCarsWithUser(userId: $userId){
    	id
      brand
      model
      year
      color
      sellingPrice
      mileage
      allBudgets{
        id
        customerId
        sellingPrice
        createdAt
      }
    }
  }
`;

function BudgetCarTable({setBudgetIdCar , setIsPreview , dataPreview , isPreview }) {
  const classes = useStyles();
  const history = useHistory();
  const [userId , setUser ] = useState(history.location.pathname.split('/')[4])

  const {
    loading,
    error,
    data,
    refetch
  } = useQuery(GET_ALL_BUDGET_CARS_WITH_USER, {
    variables: {
      userId:userId
    },
  });
  const [oneBudgetId , setOneBudgetId] = useState(null);
  const [idCar , setIdCar] = useState([]);
  const [ isOpen , setIsOpen ]= useState(false);
  useEffect(() => {
    setBudgetIdCar(idCar)
    return () => {
      setBudgetIdCar([])
    }
  }, [idCar, setBudgetIdCar])


  let isBudget=(arrayCar , idCar)=>{
    let isbudget = arrayCar.find(element => element === idCar)
    if(isbudget){
      return true;
    }
    return false
  }

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        sortable: false,
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "brand",
      label: "Marca",
  
      options: { sortable: true, filter: true },
    },
    {
      name: "model",
      label: "Modelo",
      options: { sortable: true, filter: true },
    },
    {
      name: "year",
      label: "Año",
      options: { sortable: true, filter: true },
    },
    {
      name: "mileage",
      label: "Kilometraje",
     options: { sortable: true, filter: true },
    },
    {
      name: "color",
      label: "Color",
     options: { sortable: true, filter: true },
    },
    {
      name: "price",
      label: "Precio",
     options: { sortable: true, filter: true },
    },
    {   
      name: "allBudgets",
      label: "isbudget",
        options: { 
          sortable: false, 
          filter: false,
          display: false,
          viewColumns: false,
        }
      },
    {
      name: "actions",
      label: "Operaciones",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (dataIndex, tableMeta, updateValue ) => {
          const id = tableMeta.rowData[0];
          const isSend=tableMeta.rowData[7];
          return (
            <Grid
              container
              spacing={1}
              direction="row"
              className={classes.action}
            >
              <Grid item>
                  <Button
                    id={id}
                    size="small"
                    color="primary"
                    variant="contained"
                    style={{marginRight:"10px"}}
                    onClick={()=>{
                      handleOneBudgetCar(tableMeta.rowData[0])
                    }}
                  >
                    Detalle
                  </Button>
                {
                  isBudget(idCar , tableMeta.rowData[0])
                  ?
                    <Button
                    id={id}
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={()=>{
                      handleBudgetSubstract(tableMeta.rowData[0])
                    }}
                  >
                    Vehiculo Adjuntado
                  </Button>
                :
                  <Button
                    id={id}
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={()=>{
                      handleBudgetAdd(tableMeta.rowData[0])
                    }}
                  >
                    Adjuntar vehículo
                  </Button>
                }
                {
                  isSend &&
                  <Button
                    id={id}
                    size="small"
                  >
                    <Tooltip title = {
                      `
                        Enviado el : ${moment(isSend.createdAt).format('DD-MM-YYYY')},
                        Precio: ${new Intl.NumberFormat('us-US', { style: 'currency', currency: 'USD' }).format(isSend.sellingPrice)}
                      `}
                      aria-label="info" >
                      <EmailIcon style={{color:"#3f50b5"}} />
                  </Tooltip>
                </Button>
                }
                
              </Grid>
            </Grid>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: "none",
    textLabels: translation['textLabels']
  };
  //Add or quit vehicles
  const handleBudgetAdd = id => {
    setIdCar(idCar=>[...idCar , id]);
   };
   const handleBudgetSubstract = id =>{
    setIdCar(idCar=>idCar.filter(element => element !== id ))
  };
  
  if (loading) {
    return <p>Cargando...</p>;
  }
  if (error) {

    return <p>Error</p>;
  }

//return array 
  let setIsBudget =(getAllBudget)=>getAllBudget.map((budget)=>budget.customerId.toString() === userId ? budget : false )

//return last data budgetclient;
  const isTrue = (isTrueInArray)=>{
  
    let isTrueBudget;
    for(let i = 0; i<isTrueInArray.length;i++){
      if(isTrueInArray[i]){
        isTrueBudget = isTrueInArray[i];
      }
    }
    return isTrueBudget;
  }

  //set data to array
  const dataFunction = items =>
   items.map(item => [
      item.id,
      item.brand ,
      item.model,
      item.year,
      item.mileage,
      item.color,
      <NumberFormat value={item.sellingPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={'2'} />,
      isTrue(setIsBudget(item.allBudgets)) 
    ]);

  //set Carid detail 
  const handleOneBudgetCar = id =>{
    setOneBudgetId(id);
    setIsOpen(true)
  }
  //close detail
  const handleCloseDetail = () =>{
    setIsOpen(false);
    setOneBudgetId(null);
  }
 
  return  (
  <>
    {
      oneBudgetId &&
        <BudgetOneCar
          id={oneBudgetId}
          isOpen={isOpen}
          handleClose={()=>handleCloseDetail()}
        />
    }
    {
      dataPreview &&
       <BudgetPreview 
        data={dataPreview} 
        isOpen ={isPreview} 
        handleClose={()=>setIsPreview(false)}
        collectionIdCars={idCar} 
        refetch={refetch}
      />
    }
    <Grid container spacing={4}>
      <Grid item xs={12}>
          <ThemeProvider theme={theme}>
          <MUIDataTable
            title={"Vehículos"}
            columns={columns}
            data = {dataFunction(data.getAllBudgetCarsWithUser)}
            options={options}
          /> 
          </ThemeProvider>
      </Grid>
    </Grid>
    </>
  );
}
export default BudgetCarTable;
