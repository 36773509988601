import React from "react";
import { TextField, Button } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  form: {
    marginTop: "1rem",
  },
  formControl: {
    width: "100%",
    marginBottom: "1rem",
  },
}));

export const RegisterUser = ({ onSubmit }) => {
  const classes = useStyles();
  const { register, handleSubmit, watch, errors } = useForm();

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <TextField
        className={classes.formControl}
        error={errors.name ? true : false}
        helperText={errors.name ? errors.name.message : ""}
        InputLabelProps={{ required: true }}
        inputRef={register({ required: "name is required" })}
        label="Nombre y apellido"
        name="name"
        variant="outlined"
      />
      <TextField
        className={classes.formControl}
        error={errors.email ? true : false}
        helperText={errors.email ? errors.email.message : ""}
        InputLabelProps={{ required: true }}
        inputRef={register({
          required: "email is required",
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "invalid email address",
          },
        })}
        label="Email"
        name="email"
        variant="outlined"
      />

      <TextField
        className={classes.formControl}
        error={errors.password ? true : false}
        helperText={errors.password ? errors.password.message : ""}
        InputLabelProps={{ required: true }}
        inputRef={register({ required: "password is required" })}
        label="Contraseña"
        name="password"
        type="password"
        variant="outlined"
      />
      <TextField
        className={classes.formControl}
        error={errors.retypePassword ? true : false}
        helperText={errors.retypePassword ? errors.retypePassword.message : ""}
        inputRef={register({
          required: "La contraseña es requerida",
          validate: input =>
            input === watch("password", "") || "The passwords do not match",
        })}
        label="Repetir contraseña"
        name="retypePassword"
        variant="outlined"
        type="password"
      />
      <TextField
        className={classes.formControl}
        error={errors.phone ? true : false}
        helperText={errors.phone ? errors.phone.message : ""}
        InputLabelProps={{ required: true }}
        inputRef={register({ required: "phone is required" })}
        label="Teléfono"
        name="phone"
        variant="outlined"
      />
      <div className={classes.creatingButtonContainer}>
        <Button
          type="submit"
          size="large"
          variant="contained"
          color="primary"
          fullWidth
          className={classes.createAccountButton}
        >
          Crear usuario
        </Button>
      </div>
    </form>
  );
};
