import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Button } from "../../../../components/Wrappers/Wrappers";
import GoogleHeatMap from "../../../../components/GoogleHeatMap/GoogleHeatMap";
import * as moment from 'moment'
import DisplayPhoneorWhatsApp from "../../components/DisplayPhoneorWhatsApp";
import PageviewIcon from '@material-ui/icons/Pageview';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { Link } from '@material-ui/core';
import DisplayDetailsLinkButton from '../../../../components/DisplayDetailsLinkButton/DisplayDetailsLinkButton';
import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  LineChart,
  Line,
  Area,
  PieChart,
  Pie,
  Cell,
  Series,
  YAxis,
  XAxis,
} from "recharts";

import PageTitle from "../../../../components/PageTitle/PageTitle";
import TableDisplayData from "../../components/TableDisplayData";

import {
  getFastestSoldQuery,
  getFiveTopMostPublishCarsByModelQuery,
} from "../../components/queries/reports";
import NumberFormat from "react-number-format";

export default function FastestSoldCars() {
  const [openFullScreen, setopenFullScreen] = React.useState(false);
  const [filtersDataTable, setfiltersDataTable] = React.useState();
  const columns = [
    {
      name: "Marca",
      options: {
        display: false,
        filter: true
      }
    },
    {
      name: "Modelo",
      options: {
        filter: true,
        sort: true
      }
    },
    { name: "Año" },
    {
      name: "Version",
      options: {
        filter: true,
        sort: true,
        display: false
      }
    },
    {
      name: "Kilometraje",
      options: {
        sort: true,
        filter: true,
      },
    },
    { name: "Transmisión", 
      options: { filter: true, sort: true, display: false } 
    },
    { name: "Combustible", 
      options: 
      { sort: true, filter: true, display: false } 
    },
    {
      name: "Moneda",
      options: {
        sort: false,
        filter: false,
        display: false
      },
    },
    {
      name: "Precio",
      options: {
        sort: true,
        filter: true
      },
    },
    {
      name: "Precio Promedio",
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      name: "Precio Mínimo",
      options: {
        sort: true,
        filter: false,
        display: false
      },
    },
    {
      name: "Precio Máximo",
      options: {
        sort: true,
        filter: false,
        display: false
      },
    },
    {
      name: "Ganancia",
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      name: "Ganancia Porcentual",
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      name: "Días hasta Venta",
      options: {
        sort: false,
        filter: false,
        display: false
      },
    },
    {
      name: "Fecha publicación",
      options: {
        filter: false,
        display: true
      }
    },
    {
      name: "Teléfono",
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      name: "Provincia",
      options: {
        sort: false,
        filter: true,
        display: false
      }
    },
    {
      name: "Ciudad",
      options: {
        sort: false,
        filter: true,
        display: false
      }
    },
    {
      name: "Financiación",
      options: {
        filter: true,
        display: false,
        download: false,
        viewColumns: false
      }
    },
    {
      name: "Posee Teléfono",
      options: {
        filter: true,
        display: false,
        download: false,
        sort: false,
        viewColumns: false
      }
    },
    {
      name: "Vendedor",
      options: {
        filter: true,
        display: false,
        download: false,
        sort: false
      }
    },
    {
      name: "Operación",
      options: {
        sort: false,
        filter: false,
        download: false
      },
    },
    {
      name: "Fecha de publicación",
      options: {
        filter: true,
        display: false,
        download: false,
        sort: false,
        viewColumns: false
      }
    }
  ];

  var captureFilters = data => {
    setfiltersDataTable(data);
  }
  var dataDisplayFunction = data => {
    return data.map(item => {
      return [
        item.brand.name,
        item.brand_model.name,
        item.year,
        item.version.name,
        item.mileage,
        item.transmissiontype,
        item.fueltype,
        item.currency_code,
        <NumberFormat
          value={item.price}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
          fixedDecimalScale={true}
          decimalScale={"2"}
        />,
        item.carAvgPrice.price ?
          <NumberFormat
            value={item.carAvgPrice.price}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            fixedDecimalScale={true}
            decimalScale={"2"}
          /> : "No hay datos",
        item.carAvgPrice.minPrice ?
          <NumberFormat
            value={item.carAvgPrice.minPrice}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            fixedDecimalScale={true}
            decimalScale={"2"}
          /> : "No hay datos",
        item.carAvgPrice.maxPrice ?
          <NumberFormat
            value={item.carAvgPrice.maxPrice}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            fixedDecimalScale={true}
            decimalScale={"2"}
          /> : "No hay datos",
        <NumberFormat
          value={item.profit}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
          fixedDecimalScale={true}
          decimalScale={"2"}
        />,
        item.profitPercentage !== null ? item.profitPercentage + '%' : "",
        item.carAvgPrice.daysToSell !== -1 ?
        item.carAvgPrice.daysToSell : "No hay datos",
        moment(item.created_at).format('DD-MM-YYYY'),
        <DisplayPhoneorWhatsApp phone={item.phone} link={item.link} />,
        item.address_state,
        item.address_city,
        '',
        '',
        item.tipovendedor,
        <>
          <DisplayDetailsLinkButton id={item.id} url={process.env.REACT_APP_WEB_URL + "#/app/vehicles/detail/" + item.id} />
        </>
      ];
    });
  };
  const sortCol = {
    col: "profitPercentage",
    direction: "desc",
  };
  return (
    <>
      <PageTitle title="Vehículos con mayor rotación" />
      <Grid item xs={12}>
        <Typography variant='h4'>
          Vehículos al mejor precio y con mayor rotación
        </Typography>
        <Typography style={{ "paddingTop": "20px" }} variant='subtitle1'>
          {"Listado de vehículos a la venta"}
        </Typography>
      </Grid>
      <Grid container spacing={3} style={{ "paddingTop": "20px" }} >
        <Grid item xs={12}>
          <TableDisplayData
            title="Vehículos mayor rotacion"
            columns={columns}
            field="getFastestSold"
            query={getFastestSoldQuery}
            dataFunction={dataDisplayFunction}
            sortColumn={sortCol}
            captureFilters={captureFilters}
          />
        </Grid>
      </Grid>
    </>
  );
}
