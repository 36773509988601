import React, { useState, useEffect } from "react";
import { gql, useQuery } from '@apollo/client';
import {
    Grid, Select,
    OutlinedInput,
    MenuItem
} from "@material-ui/core";
import { Typography } from "../../../components/Wrappers/Wrappers";
import Widget from "../../../components/Widget/Widget";
import useStyles from "../../dashboard/styles";
import { useTheme } from "@material-ui/styles";
import CustomTooltip from "./CustomTooltip";
import { BrandForm } from "../../../components/BrandForm/BrandForm";
import { ModelForm } from "../../../components/ModelForm/ModelForm";
import { YearForm } from "../../../components/YearForm/YearForm";
import { VersionForm } from "../../../components/VersionForm/VersionForm";
import { AddressForm } from "../../../components/AddressForm/AddressForm";

import { useForm, useWatch } from "react-hook-form";

import {
    ResponsiveContainer,
    ComposedChart,
    AreaChart,
    LineChart,
    Line,
    Area,
    PieChart,
    Pie,
    Cell,
    Series,
    YAxis,
    XAxis,
    Bar,
    Tooltip
} from "recharts";

export default function ChartXYData(props) {
    var classes = useStyles();
    var theme = useTheme();
    const { brandmodel, displayYear, version, address, mdSize, xsSize, query, lastDays, queryField, title, YLegend, symbol } = props;
    const { errors, control } = useForm();
    const [formState, setFormState] = useState({
        brandId: "",
        carModelId: "",
        versionId: "",
        addressState: "",
        year: ""
    });

    const brandId = useWatch({
        control,
        name: "brandId",
        defaultValue: formState.brandId || null
    });
    const modelId = useWatch({
        control,
        name: "carModelId",
        defaultValue: formState.carModelId || 0
    });
    const versionId = useWatch({
        control,
        name: "versionId",
        defaultValue: formState.versionId || 0
    });
    const addressState = useWatch({
        control,
        name: "addressState",
        defaultValue: formState.addressState || ''
    });
    const year = useWatch({
        control,
        name: "year",
        defaultValue: formState.year || 0,
    });
    useEffect(() => {
        setFormState({
            brandId: brandId,
            modelId: modelId,
            versionId: versionId,
            year: year,
            addressState: addressState
        })
    }, [brandId, modelId, versionId, year, addressState]);

    const { loading: loadingCarsByMonth, error: errorCarsByMonth, data: dataCarsByMonth } = useQuery(query,
        {
            fetchPolicy: "cache-and-network",
            variables: {
                brandId: brandId,
                modelId: modelId,
                versionId: versionId,
                addressState: addressState,
                year: year,
                days: (lastDays === undefined) ? 91 : lastDays
            }
        }
    );

    if (loadingCarsByMonth) return 'Cargando datos historicos...';
    if (typeof dataCarsByMonth === 'undefined') {
        return <div>No hay datos</div>
    }
    var displayMessage = "Aún no tenemos suficiente información sobre esta marca";
    if ((brandId === 0) || (brandId === null)) {
        displayMessage = "Seleccione una marca";
    }
    return (
        <Grid item xs={xsSize} md={mdSize}>
            <Widget
                bodyClass={classes.mainChartBody}
                header={
                    <div className={classes.mainChartHeader}>
                        <Typography
                            variant="h5"
                            color="text"
                            colorBrightness="secondary"
                        >
                            {title ? title : "Historial de ventas"}
                        </Typography>
                        <BrandForm
                            control={control}
                            errors={errors}
                            data={formState}
                        />
                        {brandmodel === true &&
                            (<ModelForm
                                control={control}
                                errors={errors}
                                data={formState}
                            />)
                        }
                        {version === true &&
                            (<VersionForm
                                control={control}
                                errors={errors}
                                data={formState}
                            />)
                        }
                        {displayYear === true &&
                            (<YearForm
                                control={control}
                                errors={errors}
                            />)
                        }
                        {address === true &&
                            (<AddressForm
                                control={control}
                                errors={errors}
                                data={formState}
                            />)
                        }
                    </div>
                }
            >
                <ResponsiveContainer width="90%" minWidth={500} height={350}>
                    {dataCarsByMonth[queryField].totalCount > 0 ?
                        (<ComposedChart
                            margin={{ top: 0, right: -15, left: -15, bottom: 0 }}
                            data={dataCarsByMonth[queryField].rows}
                        >
                            <YAxis
                                dataKey="totalSoldCars"
                                tick={{ fill: theme.palette.text.hint + "80", fontSize: 14 }}
                                stroke={theme.palette.text.hint + "80"}
                                offset={true}
                                tickLine={false}
                                autoSkip={true}
                                padding={{ top: 30 }}
                                width={90}
                            />
                            <XAxis
                                dataKey="soldMonth"
                                tickFormat={function tickFormat(d) {
                                    const date = new Date(d)
                                    return date.toISOString()
                                }}
                                tick={{ fill: theme.palette.text.hint + "80", fontSize: 14 }}
                                stroke={theme.palette.text.hint + "80"}
                                tickLine={false}
                            />
                            <Area
                                type="natural"
                                dataKey="totalSoldCars"
                                fill={theme.palette.background.light}
                                strokeWidth={0}
                                activeDot={false}
                            />
                            <Bar dataKey="totalSoldCars" barSize={40} fill="#413ea0" />
                            <Line
                                type="monotone"
                                dataKey="totalSoldCars"
                                stroke={theme.palette.primary.main}
                                strokeWidth={3}
                                dot={true}
                                activeDot={true}
                            />
                            <Tooltip cursor={false} content={<CustomTooltip YLegend={YLegend} symbol={symbol} />} />
                        </ComposedChart>) : (
                            <div>{displayMessage}</div>
                        )}
                </ResponsiveContainer>
            </Widget>
        </Grid>
    );
}