import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Link } from '@material-ui/core';

// import DriveEtaIcon from '@material-ui/icons/DriveEta';
import {
    Grid
  } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    display: 'flex',
    justifyContent: "center",
    width: 80,
    color: "white",
    alignItems: 'center'
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  }));

export default function BoxDataDisplay(props) {
  const classes = useStyles();

  return (
    <Grid item xs={3}>
        <Card className={classes.root}>
         <Card className={classes.cover} style={{"background":props.iconColor}}>{props.icon}</Card>
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <Typography component="h5" variant="h5">
                {props.title}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {props.amount}
              </Typography>
            </CardContent>
            <div className={classes.controls}>
            <Link href={props.link}>Ver</Link>
            </div>
          </div>
    </Card>
    </Grid>
  );
}