import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Dashboard as DashboardIcon,
  NotificationsNone as NotificationsIcon,
  FormatSize as TypographyIcon,
  FilterNone as UIElementsIcon,
  BorderAll as TableIcon,
  AccountCircle as AccountIcon,
  HelpOutline as FAQIcon,
  ArrowBack as ArrowBackIcon,
  Assessment as AssessmentIcon,
  Person as ProfileIcon,
  Build as BuildIcon,
  EmailOutlined as EmailIcon,
  AttachMoney as AttachMoneyIcon,
  LocationCity as LocationCityIcon,
  Gavel as GavelIcon,
  DriveEta as DriveEtaIcon,
  CloudUpload as CloudUploadIcon,
  Description as DescriptionIcon,
  Equalizer as EqualizerIcon,
  People as PeopleIcon
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import Dot from "./components/Dot";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useUserState } from "../../context/UserContext";

const allStructure = [
  { id: 0, label: "Dashboard", link: "/app/dashboard", icon: <DashboardIcon /> },
  { id: 5, type: "divider" },
  {
    id: 6,
    label: "REPORTES",
    link: "/app/ui",
    icon: <AssessmentIcon />,
    children: [
      { label: "Más Vendidos por Marca", link: "/app/reports/mostsoldbybrand" },
      {
        label: "Más Vendidos por Modelo",
        link: "/app/reports/mostsoldbymodel",
      },
      {
        label: "Progresión de precios",
        link: "/app/reports/priceprogression",
      }
    ],
  },
  { id: 7, type: "divider" },
  {
    id: 8,
    label: "COMPRA VEHÍCULOS",
    link: "/app/ui",
    icon: <EqualizerIcon />,
    children: [
      {
        label: "Vehículos a la venta",
        link: "/app/listing/cars"
      },
      {
        label: "Vehículos en oferta",
        link: "/app/listing/carBuyOpportunities"
      },
      {
        label: "Vehículos recientes",
        link: "/app/listing/carsRecentlyPublished"
      },
      {
        label: "Vehículos precio reducido",
        link: "/app/listing/carsOriginalPriceDiscounted"
      },
      {
        label: "Vehículos mayor rotacion",
        link: "/app/listing/carFastestSold"
      },
    ],
  },
  { id: 10, type: "divider" },
  { id: 11, type: "title", label: "Mi NEGOCIO" },
  {
    id: 13,
    label: "Clientes",
    link: "/app/customers",
    icon: <PeopleIcon />,
  },
  {
    id: 15,
    label: "Mis vehículos",
    link: "/app/vehicles",
    icon: <DriveEtaIcon />,
    children: [
      {
        label: "Mis vehículos",
        exact: true,
        link: "/app/vehicles",
        icon: <TypographyIcon />,
      },
      {
        label: "Publicar",
        exact: true,
        link: "/app/vehicles/create",
        icon: <CloudUploadIcon />
      },
    ],
  },
  {
    id: 16,
    label: "Subastas",
    exact: true,
    link: "/app/auctions",
    icon: <GavelIcon />,
  },
  {
    id: 17,
    label: "Presupuestos",
    link: "/app/budget",
    icon: <AttachMoneyIcon />,
  },
  {
    id: 18,
    label: "Ordenes de ventas",
    link: "/app/orders",
    icon: <DescriptionIcon />,
  },
  {
    id: 19,
    label: "Nuevas funcionalidades",
    link: "/app/functionalities",
    icon: <BuildIcon />,
  },
  { id: 22, type: "divider" },
  { id: 24, type: "title", label: "ADMINISTRADOR" },
  {
    id: 26,
    label: "Mensajes",
    link: "/admin/messages",
    icon: <EmailIcon />,
  },
  {
    id: 28,
    label: "Cuentas",
    link: "/admin/accounts",
    icon: <AccountIcon />,
  },
  {
    id: 30,
    label: "Usuarios",
    link: "/admin/users",
    icon: <AccountIcon />,
  },
  {
    id: 34,
    label: "Ciudad",
    link: "/admin/cityrates",
    icon: <LocationCityIcon />
  },
];

function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  const userState = useUserState();
  const structure = allStructure.filter(link => {
    return (
      (!link.link && link.label !== "ADMINISTRADOR") ||
      (link.link && !link.link.startsWith("/app") && !link.link.startsWith("/admin")) ||
      (link.link && link.link.startsWith("/app") && userState.isAuthenticated) ||
      (link.link && link.link.startsWith("/admin") && userState.isAdmin)
    );
  });
  // local
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
