import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Breadcrumbs,
  Link,
} from "@material-ui/core";
import { Link as RouterLink, useParams, useHistory } from "react-router-dom";
import { gql, useMutation, useQuery, useApolloClient } from "@apollo/client";
import { OrderForm } from "../components";
import { useToastDispatch } from "../../../context/ToastContext";

const GET_USER = gql`
  query getOrder($id: ID!) {
    order(id: $id) {
      id
      carId
      customerId
      soldPrice
    }
  }
`;

const EDIT_USER = gql`
  mutation updateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      id
    }
  }
`;

export const OrderEdit = () => {
  const client = useApolloClient();
  const { id } = useParams();
  const toastDispatch = useToastDispatch();
  const history = useHistory();
  const {
    loading: loadingOrder,
    error: errorLoadingOrder,
    data: order,
  } = useQuery(GET_USER, {
    variables: {
      id,
    },
  });


  const [updateOrder, { loading: mutationLoading }] = useMutation(EDIT_USER);

  if (loadingOrder) {
    return <p>Cargando...</p>;
  }
  if (errorLoadingOrder) {
    return <p>Error</p>;
  }
  const handleSubmit = value => {
    const input = {
      id: id,
      carId: parseInt(value.carId),
      customerId: parseInt(value.customerId),
      soldPrice: parseFloat(value.soldPrice),
    };
    updateOrder({ variables: { input } }).then(async () => {
      await client.clearStore();
      toastDispatch({
        isOpen: true,
        type: "success",
        message: "La orden de venta fue editada exitosamente",
      });
      history.push("/app/orders");
    });
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={8}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" component={RouterLink} to="/app/orders">
            Order
          </Link>
          <Link
            color="textPrimary"
            component={RouterLink}
            to={`/app/orders/edit/${id}`}
            aria-current="page"
          >
            Editar
          </Link>
        </Breadcrumbs>
        <Card>
          <CardHeader title="Editar orden de venta" />
          <CardContent>
            {order.order ? (
              <OrderForm
                data={order.order}
                loading={mutationLoading}
                submitText="Editar"
                onSubmit={handleSubmit}
              />
            ) : (
              <p>Información no encontrada</p>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
