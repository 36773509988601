import React, { useState, useEffect } from "react";
import { gql, useQuery } from '@apollo/client';
import { Grid } from "@material-ui/core";
import { Typography } from "../../../components/Wrappers/Wrappers";
import Widget from "../../../components/Widget/Widget";
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from "@material-ui/styles";
import CustomTooltip from "./CustomTooltip";
import { ModelForm } from "../../../components/ModelForm/ModelForm";
import { YearForm } from "../../../components/YearForm/YearForm";
import { useForm, useWatch } from "react-hook-form";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent'
import { ResponsiveContainer, ComposedChart, Line, Area, YAxis, XAxis, Bar, Tooltip } from "recharts";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginBottom: '32px'
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        display: 'flex',
        justifyContent: "center",
        width: 80,
        color: "white",
        alignItems: 'center'
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(2),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
}));

export default function ChartXYSoldCarsData(props) {
    var classes = useStyles();
    var theme = useTheme();
    const { filter, displayYear, query, queryField, title, YLegend } = props;
    const { errors, control } = useForm();
    const [formState, setFormState] = useState({
        brandId: "",
        carModelId: "",
        year: ""
    });

    const brandId = useWatch({
        control,
        name: "brandId",
        defaultValue: formState.brandId || null
    });
    const modelId = useWatch({
        control,
        name: "carModelId",
        defaultValue: formState.carModelId || 0
    });
    const year = useWatch({
        control,
        name: "year",
        defaultValue: formState.year || 0,
    });
    useEffect(() => {
        setFormState({ brandId: brandId })
    }, [brandId]);

    const { loading: loadingCarsByMonth, error: errorCarsByMonth, data: dataCarsByMonth } = useQuery(query,
        {
            fetchPolicy: "cache-and-network",
            variables: {
                brandId: brandId,
                modelId: modelId,
                year: year
            }
        }
    );

    if (loadingCarsByMonth) return 'Cargando datos historicos...';
    if (typeof dataCarsByMonth === 'undefined') {
        return <div>No hay datos</div>
    }
    return (
        <>
            <Grid item xs={12} md={10}>
                <Widget
                    bodyClass={classes.mainChartBody}
                    header={
                        <div className={classes.mainChartHeader}>
                            <Typography
                                variant="h5"
                                color="text"
                                colorBrightness="secondary"
                            >
                                {title ? title : "Ventas del mercado"}
                            </Typography>
                            {filter === 'brandmodel' &&
                                (<ModelForm
                                    control={control}
                                    errors={errors}
                                    data={formState}
                                />)
                            }
                            {displayYear === true &&
                                (<YearForm
                                    control={control}
                                    errors={errors}
                                />)}
                        </div>
                    }
                >
                    <ResponsiveContainer width="90%" minWidth={500} height={350}>
                        {dataCarsByMonth[queryField].totalCount > 0 ?
                            (<ComposedChart
                                margin={{ top: 0, right: -15, left: -15, bottom: 0 }}
                                data={dataCarsByMonth[queryField].rows}
                            >
                                <YAxis
                                    dataKey="totalSoldCars"
                                    tick={{ fill: theme.palette.text.hint + "80", fontSize: 14 }}
                                    stroke={theme.palette.text.hint + "80"}
                                    offset={true}
                                    tickLine={false}
                                    autoSkip={true}
                                    padding={{ top: 30 }}
                                    width={90}
                                />
                                <XAxis
                                    dataKey="soldMonth"
                                    tickFormat={function tickFormat(d) {
                                        const date = new Date(d)
                                        return date.toISOString()
                                    }}
                                    tick={{ fill: theme.palette.text.hint + "80", fontSize: 14 }}
                                    stroke={theme.palette.text.hint + "80"}
                                    tickLine={false}
                                />
                                <Area
                                    type="natural"
                                    dataKey="totalSoldCars"
                                    fill={theme.palette.background.light}
                                    strokeWidth={0}
                                    activeDot={false}
                                />
                                <Bar dataKey="totalSoldCars" barSize={40} fill="#413ea0" />
                                <Line
                                    type="monotone"
                                    dataKey="totalSoldCars"
                                    stroke={theme.palette.primary.main}
                                    strokeWidth={3}
                                    dot={true}
                                    activeDot={true}
                                />
                                <Tooltip cursor={false} content={<CustomTooltip YLegend={YLegend} />} />
                            </ComposedChart>) : (
                                <div></div>
                            )}
                    </ResponsiveContainer>
                </Widget>
            </Grid>
            <Grid item xs={12} md={2}>
                <Card className={classes.root}>
                    <div className={classes.details}>
                        <CardContent className={classes.content}>
                            <Typography
                                variant="h5"
                                color="text"
                                colorBrightness="secondary">
                                Vendidos últimos 12 meses
                            </Typography>
                            <Typography variant="subtitle1" color="textSecondary">
                                {dataCarsByMonth[queryField].allSold}
                            </Typography>
                        </CardContent>
                    </div>
                </Card>
                <Card className={classes.root}>
                    <div className={classes.details}>
                        <CardContent className={classes.content}>
                            <Typography
                                variant="h5"
                                color="text"
                                colorBrightness="secondary">
                                Mes en curso
                            </Typography>
                            <Typography variant="subtitle1" color="textSecondary">
                                {dataCarsByMonth[queryField].lastMonth}
                            </Typography>
                        </CardContent>
                    </div>
                </Card>
            </Grid>
        </>
    );
}