import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Breadcrumbs,
  Link,
} from "@material-ui/core";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { CityRatesForm } from "../components";
import { gql, useMutation, useQuery, useApolloClient } from "@apollo/client";
import { useToastDispatch } from "../../../context/ToastContext";

const ADD_CITY_RATES = gql`
  mutation addCityRates($input: InputCityRates!) {
    addCityRates(input: $input)
  }
`;
export const CityRatesCreate = () => {
  const client = useApolloClient();
  const toastDispatch = useToastDispatch();
  const history = useHistory();
  const [addCityRates, {loading: mutationLoading}] = useMutation(ADD_CITY_RATES);
  
  const handleSubmit = value => {
    const input = {
      price: parseInt(value.price),
      city_name: value.city_name
    }
    addCityRates({ variables: { input } }).then(async () => {
      await client.clearStore();
      toastDispatch({
        isOpen: true,
        type: "success",
        message: "Ciudad agregada correctamente",
      });
      history.push("/app/cityrates");
    });
  };

  return (
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" component={RouterLink} to="/app/cityrates/">
              Ciudad
            </Link>
            <Link
              color="textPrimary"
              component={RouterLink}
              to="/app/cityrates/create"
              aria-current="page"
            >
              Crear Una Ciudad
            </Link>
          </Breadcrumbs>
          <Card>
            <CardHeader title="Crear Una Ciudad" />
            <CardContent>
              <CityRatesForm
                data={{}}
                submitText="Crear"
                onSubmit={handleSubmit}
                loading={mutationLoading}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
  );
};
