import React from "react";
import { useQuery, gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { makeStyles } from "@material-ui/core";
import NumberFormat from "react-number-format";
import DisplayPhoneorWhatsApp from "../../cars/components/DisplayPhoneorWhatsApp";
import * as moment from 'moment'
import ToInspectButton from "../../../components/ToInspectButton/ToInspectButton";
import StandardMap from "../../../components/StandardMap/StandardMap";
import { useUserState } from "../../../context/UserContext";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  carousel: {
    flex: "1 1 65%",
    marginRight: "2%",
    padding: "1rem",
    border: "1px solid #ccc",
    borderRadius: "7px",
    maxWidth: "500px",
    maxHeight: "500px"
  },
  carouselImg: {
    maxWidth: "350px",
    maxHeight: "350px",
    width: "auto",
    height: "auto"
  },
  priceWrap: {
    flex: "1 1 30%",
    padding: "1rem",
    border: "1px solid #ccc",
    borderRadius: "7px",
  },
  price: {
    fontSize: "2.5rem",
    fontWeight: "bold",
    textAlign: "center",
  },
  detailsWrap: {
    flex: "0 1 100%",
    padding: "1rem",
    marginTop: "2%",
    border: "1px solid #ccc",
    borderRadius: "7px",
  },
  detailGroupWrap: {
    display: "flex"
  },
  detailValue: {
    flex: "1 1 23%",
    marginRight: "2%",
  },
  detailGroup: {
    flex: "1 1 25%",
    marginRight: "2%",
  },
  detailItem: {
    display: "flex",
    justifyContent: "space-between",
    "& p:first-child": {
      fontWeight: "bold",
    },
    "& p:last-child": {
      width: "27rem",
      wordBreak: "break-word",
    },
  },
});


const GET_CAR = gql`
  query car($id: ID!) {
    carListing(id: $id) {
      rows {
        brand {
          name
        }
        brand_model {
          name
        }
        version {
          name
        }
        title
        year
        mileage
        currency_code
        price
        previous_price
        negotiable
        fueltype
        transmissiontype
        condtion
        featured_image
        description
        color
        status
        created_at
        updated_at
        link
        site
        address_state
        address_city
        expira
        phone
        tipovendedor
        listingtype
        neighborhood_name
        geolatitude
        geolongitude
        seller_name
        car_images {
          image
        }
        carAvgPrice {
          price
          minPrice
          maxPrice
        }
      }
    }
  }
  `;
export const VehicleDetail = () => {
  const classes = useStyles();
  const { id } = useParams();
  const userState = useUserState();
  const {
    loading: loadingCar,
    error: errorLoadingCar,
    data: carData,
  } = useQuery(GET_CAR, {
    variables: {
      id,
    },
  });
  if (loadingCar) {
    return "Cargando";
  }
  
  if (errorLoadingCar) {
    return "Error durante la carga";
  }
  const car = carData.carListing.rows[0];
  return (
    <div className={classes.root}>
      {car?.car_images ? (
        <Carousel className={classes.carousel}>
          {car.car_images.map(photo => (
            <div key={photo.id}>
              <img className={classes.carouselImg}
                src={`${photo.image}`}
              />
            </div>
          ))}
        </Carousel>
      ) : (
        <div>No posee fotos</div>
      )}
      <div className={classes.priceWrap}>
        <div className={classes.price}>Precio:  
        <NumberFormat
          value={car.price}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
          fixedDecimalScale={true}
        />
        </div>
        {car.geolongitude?
        (<div style={{width: "500px", height: "350px", marginLeft:"auto", marginRight: "auto"}}>
              <StandardMap geolatitude={car.geolatitude} geolongitude={car.geolongitude} />
        </div>):("")}
      </div>
      <div className={classes.detailsWrap}>
        <h2>Descripción</h2>
        <div className={classes.detailGroupWrap}>
          <div className={classes.detailGroup}>
            <div className={classes.detailItem}>
              <p>Marca</p>
              <p>{car.brand?.name}</p>
            </div>
            <div className={classes.detailItem}>
              <p>Modelo</p>
              <p>{car.brand_model?.name}</p>
            </div>
            {car.version?
            (<div className={classes.detailItem}>
              <p>Versión</p>
              <p>{car.version?.name}</p>
            </div>):""}
            <div className={classes.detailItem}>
              <p>Año</p>
              <p>{car?.year}</p>
            </div>
            {car.fueltype?
            (<div className={classes.detailItem}>
              <p>Combustible</p>
              <p> {car?.fueltype}</p>
            </div>):("")}
            <div className={classes.detailItem}>
              <p>Kilometraje</p>
              <p><NumberFormat
                value={car?.mileage}
                displayType={"text"}
                thousandSeparator={true}
                fixedDecimalScale={true}
              /></p>
            </div>
            <div className={classes.detailItem}>
              <p>Moneda</p>
              <p>{car?.currency_code}</p>
            </div>
            <div className={classes.detailItem}>
              <p>Precio</p>
              <p><NumberFormat
                    value={car.price}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    fixedDecimalScale={true}
                 /></p>
            </div>
            {car.previous_price?
            (<div className={classes.detailItem}>
              <p>Precio Previo</p>
              <p><NumberFormat
                    value={car.previous_price}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    fixedDecimalScale={true}
                 /></p>
            </div>):("")}
            {car.negotiable?
            (<div className={classes.detailItem}>
              <p>Negociable</p>
              <p>{car?.negotiable}</p>
            </div>):("")}
            
            {car.transmissiontype?
            (<div className={classes.detailItem}>
              <p>Transmisión</p>
              <p> {car?.transmissiontype}</p>
            </div>):("")}
            <div className={classes.detailItem}>
              <p>Condición</p>
              <p>{car?.condtion}</p>
            </div>
            {car.color?
            (<div className={classes.detailItem}>
              <p>Color</p>
              <p>{car?.color}</p>
            </div>):("")}
          </div>
          <div className={classes.detailGroup}>
            {car.title?
            ( <div className={classes.detailItem}>
              <p>Título</p>
              <p>{car?.title}</p>
            </div>):("")}
            <div className={classes.detailItem}>
              <p>Enlace</p>
              <p><a target="_blank" rel="noopener noreferrer" href={car.link}>{car?.link}</a></p>
            </div>
            <div className={classes.detailItem}>
              <p>Sitio</p>
              <p>{car?.site}</p>
            </div>
            <div className={classes.detailItem}>
              <p>Tipo de vendedor</p>
              <p>{car?.tipovendedor}</p>
            </div>
            <div className={classes.detailItem}>
              <p>Provincia</p>
              <p>{car?.address_state}</p>
            </div>
            <div className={classes.detailItem}>
              <p>Ciudad</p>
              <p>{car?.address_city}</p>
            </div>
            {car.neighborhood_name?
            (<div className={classes.detailItem}>
              <p>Barrio</p>
              <p>{car?.neighborhood_name}</p>
            </div>):("")}
            <div className={classes.detailItem}>
              <p>Fecha de publicación</p>
              <p>{moment(car.created_at).format('DD-MM-YYYY')}</p>
            </div>
            {car.expira?
            (<div className={classes.detailItem}>
              <p>Fecha de expiración</p>
              <p>{moment(car.expira).format('DD-MM-YYYY')}</p>
            </div>):("")}
            <div className={classes.detailItem}>
              <p>Teléfono</p>
              <DisplayPhoneorWhatsApp phone={car.phone} link={car.link} />
            </div>
            {car.seller_name?
            (<div className={classes.detailItem}>
              <p>Nombre del vendedor</p>
              <p>{car?.seller_name}</p>
            </div>):("")}
            {car.description?
           ( <div className={classes.detailItem}>
              <p>Descripción</p>
              <p>{car?.description}</p>
            </div>):("")}
          </div>
        </div>
        {
          car &&
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <ToInspectButton
              carListingId={id}
              city={car?.address_city}
              province={car?.address_state}
              brand={car.brand?.name}
              model={car.brand_model?.name}
              year={car.year}
              phone={car.phone !== " "? car.phone : null}
              userId={userState.me.id}
              propietary={null}
              linkToCar={`${process.env.REACT_APP_WEB_URL}/#/app/vehicles/detail/${id}`}
            >
              Inspeccionar Vehículo
            </ToInspectButton>
          </div>
        }
      </div>
      </div>
  );
};
