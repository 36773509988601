import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useUserState } from "../../../context/UserContext";
export function NormalRoute({ component, ...rest }) {
  const { isAuthenticated } = useUserState();
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
}
