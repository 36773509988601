import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { gql, useQuery } from "@apollo/client";
import { Card, CardContent, CardMedia, Grid, makeStyles, Typography } from '@material-ui/core';
import NumberFormat from "react-number-format";

import {
    ArrowBack as ArrowBackIcon,
    ArrowForward as ArrowForwardIcon
} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
    action: {
        minWidth: "163px",
        justifyContent:"end"
    },
    root: {
        maxWidth: 645,
    },
    media: {
        height: 290,
    },
    cardContent:{
        width:"100%"
    },
    cardWeing:{
        fontWeight:"bold"
    },
    slideContainer:{
        height:'290px',
        width: '100%',
        overflow: 'hidden',
    },
    notCarImage:{
        height:'190px',
        width:"100%",
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
    }
    })
);



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  
const BUDGET_ONE_CAR = gql`
  query getOneBudgetCar($id: ID!) {
    getOneBudgetCar(id: $id) {
        id
        brand
        model
        year
        mileage
        photos{
            id
            url
            position
        }
        sellingPrice
        transmissionType
        fuelType
    }
  }
`;

export const BudgetOneCar = ({ isOpen, handleClose , id  }) => {

    const classes = useStyles();
    const {
        loading,
        error,
        data
      } = useQuery(BUDGET_ONE_CAR, {
        variables: {
          id
        },
      });
      const [ next , setNext ] = useState(false);
      const [back , setBack] = useState(false);
      if (loading) {
        return <p>Cargando...</p>;
      }
    
      if (error) {
          console.log(error)
        return <p>Error</p>;
      }

  return (
  <div>
    {
        data &&
        <Dialog
            open={isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
        
        <DialogContent>
            <Card className={classes.root} >
                {
                    data.getOneBudgetCar.photos.length > 0 ?
                     <Slider 
                        features={data.getOneBudgetCar.photos} 
                        next={next} 
                        back={back}
                        setNext={setNext}
                        setBack={setBack}
                    />
                    :
                    <div className={classes.notCarImage}>
                      Vehículo sin foto
                    </div>
                }
                <Grid style={{display:'flex',justifyContent:"space-around",marginTop:"1rem"}}>
                    <Grid item>
                        <Button
                        onClick={()=>setBack(true)}
                        >
                            <ArrowBackIcon/>
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                         onClick={()=>setNext(true)}
                        >
                            <ArrowForwardIcon/>
                        </Button>
                    </Grid> 
                </Grid>
                <CardContent>
                <Typography
                    gutterBottom 
                    variant="h2" 
                    component="h2"
                >
                    {data.getOneBudgetCar.brand}
                </Typography>

                <Grid container spacing={2}>
                    <Grid item xs={4} zeroMinWidth>
                        <Typography component='h4' color='primary'>
                            {"Modelo"}
                        </Typography>
                        <Typography component='h3' className={classes.cardWeing}>
                            {data.getOneBudgetCar.model}
                        </Typography>
                        </Grid>

                        <Grid item xs={4} zeroMinWidth>
                        <Typography component='h4' color='primary'>
                            {"Kilómetros"}
                        </Typography>
                        <Typography component='h3' className={classes.cardWeing}>
                            {data.getOneBudgetCar.mileage}
                        </Typography>
                        </Grid>

                        <Grid item xs={4} zeroMinWidth>
                        <Typography component='h4' color='primary'>
                            {"Año"}
                        </Typography>
                        <Typography component='h3' className={classes.cardWeing}>
                            {data.getOneBudgetCar.year}
                        </Typography>
                        </Grid>
                        <Grid item xs={4} zeroMinWidth>
                        <Typography component='h4' color='primary'>
                            {"Transmisión"}
                        </Typography>
                        <Typography component='h3' className={classes.cardWeing}>
                            {data.getOneBudgetCar.transmissionType}
                        </Typography>
                        </Grid>
                        <Grid item xs={4} zeroMinWidth>
                        <Typography component='h4' color='primary'>
                            {"Combustible"}
                        </Typography>
                        <Typography component='h3' className={classes.cardWeing}>
                            {data.getOneBudgetCar.fuelType}
                        </Typography>
                        </Grid>
                        <Grid item xs={4} zeroMinWidth>
                        <Typography component='h4' color='primary'>
                            {"Precio"}
                        </Typography>
                        <Typography component='h1'  className={classes.cardWeing}>
                            <NumberFormat value={data.getOneBudgetCar.sellingPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={'2'} />
                        </Typography>
                        </Grid>
                    </Grid>  
                </CardContent>
            </Card>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary">
                Ok
            </Button>
        </DialogActions>
        </Dialog>
        }
    </div>
  );
};



 const Slider =({ features , next , back , setBack , setNext})=>{

    const classes=useStyles();
    const [position , setPosition ]= useState(0);
    const [ imgFeatures , setImgFeatures  ] = useState(features)

    useEffect(()=>{
        if(next){
            console.log(position)
            if(position < features.length-1){
               setPosition(position+1); 
            }else{
                setPosition(features.length-1)
            }
            setNext(false);
        }
        if(back){
            console.log(position)
            if(position <= features.length-1 && position > 0){
               setPosition(position-1); 
            }else{
                setPosition(0)
            }
            setBack(false);
        }
    },[back, features.length, next, position, setBack, setNext])


    

  return(
    <div className={classes.slideContainer}>
        {
            features &&
            <CardMedia
                className={classes.media}
                image={`${process.env.REACT_APP_SERVER_ROOT_URL + '/images/' + imgFeatures[position].url }`}
                title="car"
            />   
        }
    </div> 
  ) 
};
  