import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Button } from "../../../../components/Wrappers/Wrappers";
import * as moment from 'moment'
import DisplayPhoneorWhatsApp from "../../components/DisplayPhoneorWhatsApp";
import AddBoxIcon from '@material-ui/icons/AddBox';
import { Link } from '@material-ui/core';
import DisplayDetailsLinkButton from '../../../../components/DisplayDetailsLinkButton/DisplayDetailsLinkButton';
import ChartXYData from "../../components/ChartXYData";
import { gql } from '@apollo/client';
import PageTitle from "../../../../components/PageTitle/PageTitle";
import PieChartDisplay from "../../components/PieChartDisplay";
import TableDisplayData from "../../components/TableDisplayData";

import {
  getPublishLiveCarsQuery,
  getFiveTopMostPublishCarsByModelQuery,
} from "../../components/queries/reports";
import NumberFormat from "react-number-format";

export default function AllSellingCars() {
  const [openFullScreen, setopenFullScreen] = React.useState(false);
  const [filtersDataTable, setfiltersDataTable] = React.useState();
  const columns = [
    {
      name: "Marca",
      options: {
        display: false,
        filter: true
      }
    },
    {
      name: "Modelo",
      options: {
        filter: true,
        sort: true
      }
    },
    { name: "Año" },
    {
      name: "Version",
      options: {
        filter: true,
        sort: true,
        display: false
      }
    },
    {
      name: "Moneda",
      options: {
        sort: false,
        filter: false,
        display: false
      },
    },
    {
      name: "Precio",
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      name: "Precio Promedio",
      options: {
        sort: true,
        filter: false
      },
    },
    {
      name: "Precio Mínimo",
      options: {
        sort: true,
        filter: false,
        display: false
      },
    },
    {
      name: "Precio Máximo",
      options: {
        sort: true,
        filter: false,
        display: false
      },
    },
    {
      name: "Kilometraje",
      options: {
        sort: true,
        filter: true,
      },
    },
    { name: "Transmisión", 
      options: { 
        filter: true, 
        display: false } 
    },
    { name: "Combustible" },
    {
      name: "Fecha publicación",
      options: {
        filter: false,
        display: true
      }
    },
    {
      name: "Teléfono",
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      name: "Provincia",
      options: {
        sort: false,
        filter: true,
        display: false
      }
    },
    {
      name: "Ciudad",
      options: {
        sort: false,
        filter: true,
        display: false,
        download: true
      }
    },
    {
      name: "Financiación",
      options: {
        filter: true,
        display: false,
        download: false,
        sort: false,
        viewColumns: false
      }
    },
    {
      name: "Posee Teléfono",
      options: {
        filter: true,
        display: false,
        download: false,
        sort: false,
        viewColumns: false
      }
    },
    {
      name: "Vendedor",
      options: {
        filter: true,
        display: false,
        download: false,
        sort: false
      }
    },
    {
      name: "Operación",
      options: {
        sort: false,
        filter: false,
        download: false
      },
    },
    {
      name: "Fecha de publicación",
      options: {
        filter: true,
        display: false,
        download: false,
        sort: false,
        viewColumns: false
      }
    }
  ];

  var captureFilters = data => {
    setfiltersDataTable(data);
  }
  var dataDisplayFunction = data => {
    return data.map(item => {
      return [
        item.brand.name,
        item.brand_model.name,
        item.year,
        item.version.name,
        item.currency_code,
        <NumberFormat
          value={item.price}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
          fixedDecimalScale={true}
          decimalScale={"2"}
        />,
        item.carAvgPrice.price ?
          <NumberFormat
            value={item.carAvgPrice.price}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            fixedDecimalScale={true}
            decimalScale={"2"}
          /> : "No hay datos",
        item.carAvgPrice.minPrice ?
          <NumberFormat
            value={item.carAvgPrice.minPrice}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            fixedDecimalScale={true}
            decimalScale={"2"}
          /> : "No hay datos",
        item.carAvgPrice.maxPrice ?
          <NumberFormat
            value={item.carAvgPrice.maxPrice}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            fixedDecimalScale={true}
            decimalScale={"2"}
          /> : "No hay datos",
        item.mileage,
        item.transmissiontype,
        item.fueltype,
        moment(item.created_at).format('DD-MM-YYYY'),
        <DisplayPhoneorWhatsApp key={item.id} id={item.id} phone={item.phone} link={item.link} />,
        item.address_state,
        item.address_city,
        '',
        '',
        item.tipovendedor,
        <><DisplayDetailsLinkButton id={item.id} url={process.env.REACT_APP_WEB_URL + "#/app/vehicles/detail/" + item.id} /></>,
        ''
      ];
    });
  };
  const sortCol = {
    col: "created_at",
    direction: "desc",
  };
  const getSellingCarsAmount = gql`
    query getSellingCarsAmount($brandId: Int, $modelId: Int, $year: Int) {
      getSellingCarsAmount(brandId:$brandId, modelId:$modelId, year:$year) {
        rows {
          soldMonth
          totalSoldCars
        }
        totalCount
      }
    }
  `;
  return (
    <>
      <PageTitle title="Vehículos a la venta en el mercado argentino" />
      <Grid item xs={12}>
        <Typography variant='h4'>
          Información sobre el reporte
        </Typography>
        <Typography style={{ "paddingTop": "20px" }} variant='subtitle1'>
          {"Listado de todos los vehículos publicados actualmente en el mercado argentino."}
        </Typography>
      </Grid>
      <Grid container item xs={12} spacing={2} alignItems="center" justify="center" style={{ "paddingTop": "20px" }} >
        <ChartXYData
          brandmodel={true}
          displayYear={true}
          mdSize={6}
          xsSize={10}
          query={getSellingCarsAmount}
          queryField="getSellingCarsAmount"
          title="Historial de vehículos en venta"
          YLegend="Vehículos a la venta"
        />
        <Grid item xs={5}>
          <PieChartDisplay
            title="Top 5 modelos a la venta (cantidad publicados)"
            query={getFiveTopMostPublishCarsByModelQuery}
            field="getFiveTopMostPublishCars"
            dataKey="totalPublishCars"
            displayColumns={["brand_model", "totalPublishCars"]}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12}>
          &nbsp;
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TableDisplayData
            title="Vehículos vendidos por modelo"
            columns={columns}
            field="getPublishLiveCars"
            query={getPublishLiveCarsQuery}
            dataFunction={dataDisplayFunction}
            sortColumn={sortCol}
            captureFilters={captureFilters}
          />
        </Grid>
      </Grid>
    </>
  );
}
