import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Breadcrumbs,
  Link,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { MessageForm } from "../components";
import { gql, useMutation, useApolloClient, useSubscription } from "@apollo/client";
import { useToastDispatch } from "../../../context/ToastContext";

const CREATE_ACCOUNT = gql`
  mutation createMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      id
    }
  }
`;
export const MessageCreate = () => {
  const client = useApolloClient();
  const [createMessage, { loading: mutationLoading }] = useMutation(
    CREATE_ACCOUNT,
  );
  const toastDispatch = useToastDispatch();

  const handleSubmit = value => {
    createMessage({ variables: { input: value } }).then(() => {
      client.clearStore().then(data=>{
        toastDispatch({
          isOpen: true,
          type: "success",
          message: "Message is successfully created",
        }); 
      })
    });
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={8}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" component={RouterLink} to="/admin/messages">
            Messages
          </Link>
          <Link
            color="textPrimary"
            component={RouterLink}
            to="/admin/messages/create"
            aria-current="page"
          >
            Broadcat New Message
          </Link>
        </Breadcrumbs>
        <Card>
          <CardHeader title="Crear Una Cuenta" />
          <CardContent>
            <MessageForm
              data={{}}
              submitText="Crear"
              onSubmit={handleSubmit}
              loading={mutationLoading}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
