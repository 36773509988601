import React, { useState, useEffect } from "react";
import {
    Select,
    InputLabel,
    MenuItem,
    ListItemText,
    FormControl,
    FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery, gql } from "@apollo/client";
import { useForm, Controller, useWatch } from "react-hook-form";

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 650,
    },
    form: {
        display: "flex",
        flexWrap: "wrap",
    },
    formControl: {
        margin: "5px 5px",
        flex: "1 1 30%",
        textAlign: "left",
    },
    formControlSingle: {
        margin: "5px 5px",
        flex: "0 0 32.2%",
        textAlign: "left",
    },
    actionButtons: {
        flex: "1 1 100%",
        display: "flex",
        alignItems: "flex-end",
        marginTop: "1rem",
    },
}));

const GET_ADDRESS = gql`
  query getAddress($carModelId: Int!) {
    getAddress(carModelId: $carModelId) {
      id
      name
      model_id
    }
  }
`;

export function AddressForm(props) {
    const { control, errors, data } = props;
    const carModelId = useWatch({
        control,
        name: "modelId",
        defaultValue: data.modelId || 0,
    });

    const { loading, error, data: addressData, fetchMore } = useQuery(
        GET_ADDRESS,
        {
            variables: {
                carModelId: carModelId,
            },
        },
    );
    const classes = useStyles();

    if (loading) return "Cargando...";

    if (error) return `Error! ${error.message}`;
    const optionValues = carModelId ? addressData.getAddress : [];
    return (
        <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>Zona</InputLabel>
            <Controller
                as={
                    <Select error={errors.addressState ? true : false}>
                        <MenuItem key="0" value=""></MenuItem>
                        {optionValues.map((item, index) => (
                            <MenuItem key={item.name} value={item.name}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </Select>
                }
                control={control}
                defaultValue={data.addressState || ""}
                label="Zona"
                name="addressState"
            />
            <FormHelperText error={true}>
                {errors.addressState && errors.addressState.message}
            </FormHelperText>
        </FormControl>
    );
}
