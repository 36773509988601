import React, { useState, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  createMuiTheme,
  Grid,
  IconButton,
  ThemeProvider,
  Tooltip,
  makeStyles,
  Switch,
  Typography
} from "@material-ui/core";

import MUIDataTable from "mui-datatables";
import AddIcon from "@material-ui/icons/Add";

// components
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { useToastDispatch } from "../../../context/ToastContext";
import translation from "../../translations/translations_muidatatable";
import NumberFormat from 'react-number-format';
import PageTitle from "../../../components/PageTitle/PageTitle";

const theme = createMuiTheme({
  overrides: {
    MuiTable: {
      root: {
        border: [[1, "solid", "#d3d3d3"]],
        marginBottom:"3rem"
      },
    },
    MuiTableCell: {
      root: {
        borderColor: "#d3d3d3",
      },
      head: {
        background: "lightgrey",
        fontWeight:"bold"
      },
    },
    MuiTableSortLabel: {
      root: {
        alignItems: "flex-start",
      },
    },
    MuiTableFooter: {
      root: {
        background: "lightgrey",
      },
    },
  },
});

const useStyles = makeStyles(theme => ({
  action: {
    minWidth: "163px",
    alignContent:"center"
  },
  margin: {
    position:"fixed",
    right:'10%',
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  title:{
    textAlign:"center",
    marginBottom:"1rem"
  },
  subtitle:{
    marginBottom:"1rem"
  },
}));

const GET_ORDERS = gql`
  query getOrders {
    orders {
      nodes {
        id
        customer {
          name
        }
        car {
          title
          year
          brand {
            name
          }
          brand_model {
            name
          }
        }
        soldPrice
        createdAt
      }
    }
  }
`;
const DELETE_ORDER = gql`
  mutation DeleteOrder($input: DeleteOrderInput) {
    deleteOrder(input: $input)
  }
`;

export function OrdersTable() {
  const classes = useStyles();
  const [deleteOrder] = useMutation(DELETE_ORDER);
  const { loading, error, data, refetch } = useQuery(GET_ORDERS);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [id, setId] = useState(null);
  const toastDispatch = useToastDispatch();

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        sortable: false,
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "customer.name",
      label: "Nombre de cliente",
      options: { sortable: true, filter: true },
    },
    {
      name: "car.brand.name",
      label: "Marca",
      options: { sortable: true, filter: true },
    },
    {
      name: "car.brand_model.name",
      label: "Modelo",
      options: { sortable: true, filter: true },
    },
    {
      name: "car.year",
      label: "Año",
      options: { sortable: true, filter: true },
    },
    {
      name: "soldPrice",
      label: "Precio de venta",
      options: { sortable: true, filter: true },
    },
    {
      name: "actions",
      label: "Operaciones",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (dataIndex, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          return (
            <Grid
              container
              spacing={1}
              justify="center"
              direction="row"
              className={classes.action}
            >
              <Grid item>
                <ButtonGroup
                  size="small"
                  aria-label="small outlined button group"
                >
                  <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    component={RouterLink}
                    to={`/app/orders/edit/${id}`}
                  >
                    Editar
                  </Button>
                </ButtonGroup>
              </Grid>
              <Grid item>
                <ButtonGroup
                  size="small"
                  aria-label="small outlined button group"
                >
                  <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleDeleteDialogOpen(tableMeta.rowData[0])}
                  >
                    Borrar
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: "none",
    textLabels: translation['textLabels'],
    customToolbar: () => {
      return (
        <Tooltip title="Crear Una Order">
          <IconButton component={RouterLink} to="/app/orders/create">
            <AddIcon />
          </IconButton>
        </Tooltip>
      );
    },
  };

  const handleDeleteDialogOpen = id => {
    setId(id);
    setIsDeleteConfirmOpen(true);
  };
  const handleDeleteDialogClose = confirm => {
    setIsDeleteConfirmOpen(false);
    if (confirm) {
      deleteOrder({
        variables: {
          input: {
            id: id,
          },
        },
      }).then(() => {
        toastDispatch({
          isOpen: true,
          type: "success",
          message: "Order is successfully deleted",
        });
        refetch();
      });
    }
  };
  const dataFunction = items =>
    items.map(item => [
      item.id,
      item.customer.name,
      item.car.brand.name,
      item.car.brand_model.name,
      item.car.year,
      <NumberFormat value={item.soldPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={'2'} />
    ]);
  if (loading) {
    return <p>Cargando...</p>;
  }
  if (error) {
    return <p>Error</p>;
  }
  return (
    <>
      <ConfirmDialog
        isOpen={isDeleteConfirmOpen}
        handleClose={handleDeleteDialogClose}
      />
       <PageTitle title="Ordenes de venta de vehículos" />
      <Grid item xs={12}>
        <Typography variant='subtitle1'>
        {"Listado de ordenes de ventas de vehículos"}
        </Typography>
      </Grid>
      <Grid container spacing={4} style={{"paddingTop": "20px"}}>
        <Grid item xs={12}>
          <ThemeProvider theme={theme}>
            <MUIDataTable
              title={"Ordenes de venta"}
              columns={columns}
              data={dataFunction(data.orders.nodes)}
              options={options}
            />
          </ThemeProvider>
        </Grid>
      </Grid>
    </>
  );
}
