import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Breadcrumbs,
  Link,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { AccountForm } from "../components";
import { gql, useMutation, useApolloClient } from "@apollo/client";
import { useToastDispatch } from "../../../context/ToastContext";

const CREATE_ACCOUNT = gql`
  mutation createAccount($input: CreateAccountInput!) {
    createAccount(input: $input) {
      id
    }
  }
`;
export const AccountCreate = () => {
  const client = useApolloClient();
  const [createAccount, { loading: mutationLoading }] = useMutation(
    CREATE_ACCOUNT,
  );
  const toastDispatch = useToastDispatch();

  const handleSubmit = value => {
    createAccount({ variables: { input: value } }).then(() => {
      client.clearStore();
      toastDispatch({
        isOpen: true,
        type: "success",
        message: "Account is successfully created",
      });
    });
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={8}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" component={RouterLink} to="/admin/accounts">
            Cuenta
          </Link>
          <Link
            color="textPrimary"
            component={RouterLink}
            to="/admin/accounts/create"
            aria-current="page"
          >
            Crear Una Cuenta
          </Link>
        </Breadcrumbs>
        <Card>
          <CardHeader title="Crear Una Cuenta" />
          <CardContent>
            <AccountForm
              data={{}}
              submitText="Crear"
              onSubmit={handleSubmit}
              loading={mutationLoading}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
