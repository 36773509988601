import React, { useState, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  createMuiTheme,
  Grid,
  IconButton,
  ThemeProvider,
  Tooltip,
  makeStyles,
  Switch,
} from "@material-ui/core";

import MUIDataTable from "mui-datatables";
import AddIcon from "@material-ui/icons/Add";

// components
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { useToastDispatch } from "../../../context/ToastContext";
import translation from "../../translations/translations_muidatatable";

const theme = createMuiTheme({
  overrides: {
    MuiTable: {
      root: {
        border: [[1, "solid", "#d3d3d3"]],
      },
    },
    MuiTableCell: {
      root: {
        borderColor: "#d3d3d3",
      },
      head: {
        background: "lightgrey",
      },
    },
    MuiTableSortLabel: {
      root: {
        alignItems: "flex-start",
      },
    },
    MuiTableFooter: {
      root: {
        background: "lightgrey",
      },
    },
  },
});
const useStyles = makeStyles(theme => ({
  action: {
    minWidth: "163px",
  },
}));

const GET_CUSTOMERS = gql`
  query getCustomers {
    customers {
      nodes {
        id
        name
        phone
        email
        preferredCarType
        preferredYear
        preferredPriceRange
        birthday
        comments
      }
    }
  }
`;
const DELETE_CUSTOMER = gql`
  mutation DeleteCustomer($input: DeleteCustomerInput) {
    deleteCustomer(input: $input)
  }
`;

export function CustomersTable() {
  const classes = useStyles();
  const [deleteCustomer] = useMutation(DELETE_CUSTOMER);
  const { loading, error, data, refetch } = useQuery(GET_CUSTOMERS);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [id, setId] = useState(null);
  const toastDispatch = useToastDispatch();

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        sortable: false,
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "name",
      label: "Nombre y apellido",
      options: { sortable: true, filter: true },
    },
    {
      name: "phone",
      label: "Teléfono",
      options: { sortable: true, filter: true },
    },
    {
      name: "email",
      label: "Email",
      options: { sortable: true, filter: true },
    },
    {
      name: "birthday",
      label: "Fecha nacimiento",
      options: { sortable: false, filter: false },
    },
    {
      name: "preferredCarType",
      label: "Vehículo de preferencia",
      options: { sortable: false, filter: false },
    },
    {
      name: "preferredYear",
      label: "Año de preferencia",
      options: { sortable: false, filter: false },
    },
    {
      name: "preferredPriceRange",
      label: "Rango de precio",
      options: { sortable: false, filter: false },
    },
    {
      name: "actions",
      label: "Operaciones",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (dataIndex, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          return (
            <Grid
              container
              spacing={1}
              direction="row"
              className={classes.action}
            >
              <Grid item>
                <ButtonGroup
                  size="small"
                  aria-label="small outlined button group"
                >
                  <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    component={RouterLink}
                    to={`/app/customers/edit/${id}`}
                  >
                    Editar
                  </Button>
                </ButtonGroup>
              </Grid>
              <Grid item>
                <ButtonGroup
                  size="small"
                  aria-label="small outlined button group"
                >
                  <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleDeleteDialogOpen(tableMeta.rowData[0])}
                  >
                    Borrar
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: "none",
    customToolbar: () => {
      return (
        <Tooltip title="Crear un cliente">
          <IconButton component={RouterLink} to="/app/customers/create">
            <AddIcon />
          </IconButton>
        </Tooltip>
      );
    },
    textLabels: translation['textLabels']
  };

  const handleDeleteDialogOpen = id => {
    setId(id);
    setIsDeleteConfirmOpen(true);
  };
  const handleDeleteDialogClose = confirm => {
    setIsDeleteConfirmOpen(false);
    if (confirm) {
      deleteCustomer({
        variables: {
          input: {
            id: id,
          },
        },
      }).then(() => {
        toastDispatch({
          isOpen: true,
          type: "success",
          message: "El cliente ha sido eliminado correctamente",
        });
        refetch();
      });
    }
  };

  if (loading) {
    return <p>Cargando...</p>;
  }
  if (error) {
    return <p>Error</p>;
  }
  return (
    <>
      <ConfirmDialog
        isOpen={isDeleteConfirmOpen}
        handleClose={handleDeleteDialogClose}
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <ThemeProvider theme={theme}>
            <MUIDataTable
              title={"Clientes"}
              columns={columns}
              data={data.customers.nodes}
              options={options}
            />
          </ThemeProvider>
        </Grid>
      </Grid>
    </>
  );
}
