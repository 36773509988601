import React, { useState , useEffect , useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  createMuiTheme,
  Grid,
  IconButton,
  ThemeProvider,
  Tooltip,
  makeStyles,
  Typography
} from "@material-ui/core";

import MUIDataTable from "mui-datatables";
import AddIcon from "@material-ui/icons/Add";

// components
import { useQuery , useMutation , gql , useApolloClient  } from "@apollo/client";
import PageTitle from "../../../components/PageTitle/PageTitle";

import { useToastDispatch } from "../../../context/ToastContext";
import { FunctionalityDialog } from "../../../components/FunctionalityDialog/FunctionalityDialog";
import { useUserState } from "../../../context/UserContext";
import ConfirmDialogFunctionality from "../../../components/ConfirmDialogFunctionality";
import * as moment from 'moment';
import translation from "../../translations/translations_muidatatable";

const theme = createMuiTheme({
  overrides: {
    MuiTable: {
      root: {
        border: [[1, "solid", "#d3d3d3"]],
        marginBottom:"3rem"
      },
    },
    MuiTableCell: {
      root: {
        borderColor: "#d3d3d3",
      },
      head: {
        background: "lightgrey",
        fontWeight:"bold"
      },
    },
    MuiTableSortLabel: {
      root: {
        alignItems: "flex-start",
      },
    },
    MuiTableFooter: {
      root: {
        background: "lightgrey",
      },
    },
  },
});

const useStyles = makeStyles(theme => ({
  action: {
    minWidth: "163px",
    alignContent:"center"
  },
  margin: {
    position:"fixed",
    right:'10%',
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  title:{
    textAlign:"center",
    marginBottom:"1rem"
  },
  subtitle:{
    marginBottom:"1rem"
  },
}));

const GET_ALL_FUNCTIONALITIES = gql`
    query getAllFunctionality {
      getAllFunctionality {
      id
      title
      description
      state
      quantity
      vote
    	propietary
    	createdAt
      }
    }
`;

const DELETE_VOTE = gql`
  mutation deleteFunctionalityVote($input: DeleteFunctionalityVoteInput) {
    deleteFunctionalityVote(input: $input)
  }
`;

const DELETE_FUNCTIONALITY = gql`
  mutation deleteFunctionality ($input: DeleteFunctionalityInput){
    deleteFunctionality(input:$input)
  }
`;

const ADD_VOTE = gql`
  mutation createFunctionalityVote($input: CreateFunctionalityVoteInput) {
    createFunctionalityVote(input: $input)
  }
`;

export function FunctionalityTable() {
  const classes = useStyles();
  const client = useApolloClient();


  const [ deleteVote ] = useMutation(DELETE_VOTE);
  const [ addVote ] = useMutation(ADD_VOTE);
  const [ deleteFunctionality ] = useMutation(DELETE_FUNCTIONALITY)
  const { loading, error, data, refetch } = useQuery(GET_ALL_FUNCTIONALITIES);

  const toastDispatch = useToastDispatch();
 
  const [id, setId] = useState(null);
  const [ viewId , setViewId ] = useState(null);
  const [ deleteId , setDeleteId ]= useState();
 
  const [isFunctionalityConfirmOpen , setConfirmOpen] = useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [vote, setVote] = useState(null);
  const isAdmin = useUserState().isAdmin;
  
  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        sortable: false,
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "title",
      label: "Título",
      options: { sortable: true, filter: true  },
    },
    {
      name: "createdAt",
      label: "Fecha de creación",
      options: { sortable: true, filter: true },
    },
    {
      name: "state",
      label: "Estado",
       options: {
        sortable: false,
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "vote",
      label: 'Votar',
      options: { 
        sortable: false, 
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "propietary",
      label: "Operaciones",
      options: {
        filter: false,
        sortable: true, 
        customBodyRender: (dataIndex, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          let isOpen =()=>  tableMeta.rowData[3] === "Votando" ? true : false;
          const isVote = tableMeta.rowData[4];
          let isPropietary = tableMeta.rowData[5];
          //swich propietary or admin
          let isPropietaryOrAdmin =( isAdmin , isPropietary )=>{
            if(isAdmin){
              return (
                <ButtonGroup>
                    <VoteButtons 
                      handleActionUser={handleActionUser}
                      id={id} 
                      isVote={isVote}
                      isOpen={isOpen()}
                    />
                  <Button
                      size="small"
                      variant="outlined"
                      color="secondary"
                      component={RouterLink}
                      to={`/app/functionalities/edit/${id}`}
                    >
                      Editar
                    </Button>
                    <Button
                      size="small"
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleDeleteDialogOpen(parseInt(tableMeta.rowData[0]))}
                    >
                      Eliminar
                  </Button>
                </ButtonGroup>
              )
            } else if (isPropietary){
              return (
                <ButtonGroup>
                  <VoteButtons 
                      handleActionUser={handleActionUser}
                      id={id} 
                      isVote={isVote}
                      isOpen={isOpen()}
                    />
                  <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    component={RouterLink}
                    to={`/app/functionalities/edit/${id}`}
                  >
                    Editar
                  </Button>
                </ButtonGroup>
              )
            }
            return (
            <ButtonGroup>
              <VoteButtons 
                handleActionUser={handleActionUser}
                id={id} 
                isVote={isVote}
                isOpen={isOpen()}
              />
            </ButtonGroup>
            )
          }
          //set propietary or admin
          return (
            <>
               <Grid
                  container
                  spacing={1}
                  direction="row"
                  className={classes.action}
                >
                  <Grid item>
                  <ButtonGroup>
                    {
                      isPropietaryOrAdmin(isAdmin , isPropietary)
                    }
                  </ButtonGroup>

                </Grid>
              </Grid>
           </>
          );
        },
      },
    },{
      name: "Detail",
      label: 'Operaciones',
      options: { 
        sortable: true, 
        filter: true,
        customBodyRender:(dataIndex, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          return <Button     
            size="small"
            variant="outlined"
            onClick={()=>handleDetaill(id)}
            >
              Ver funcionalidad 
          </Button>
        }
      },
    },
  ];

  const options = {
    selectableRows: "none",
    customToolbar: () => {
      return (
        <Tooltip title="Crear funcionalidad">
          <IconButton component={RouterLink} to="/app/functionalities/create">
            <AddIcon />
          </IconButton>
        </Tooltip>
      );
    },
    textLabels: translation['textLabels']
  };

  const handleActionUser = (id , vote ) => {
    setId(id);
    setVote(vote);
  };

  const handleFunctionalityDialogClose = confirm => {
    setConfirmOpen(false);
  };

  const handleDetaill = id => {
    setViewId(id);
    setConfirmOpen(true);
  };

  //change buttons of vote
  useEffect(()=>{
      async function handleVote(vote) {
        if(vote){  
        deleteVote({
              variables: {
                input: {
                  functionalityId:parseInt(id),
                },
              }
          }).then(d=> {
            toastDispatch({
            isOpen: true,
            type: "error",
            message: "Quitaste un voto!",
          })
            refetch()
        })}
      else{
        addVote({
            variables: {
              input: {
                functionalityId: parseInt(id),
              },
            },
        }).then(d=> {
          toastDispatch({
          isOpen: true,
          type: "info",
          message: "Votación correcta",
        })
          refetch()
      })}}
    if(id){
      handleVote(vote);
    }
  },[addVote, deleteVote, id, refetch, toastDispatch, vote])

  //set lenguaje state 
  let stateItem = state => {
    switch (state) {
      case 'Open':
        return 'Votando'
      case 'Close':
        return 'Votación cerrada'
      case 'Executing':
        return 'Implementada'
      default:
        break;
    }
  }

//Convert array data
  const dataFunction = items =>
    items.map(item => [
      item.id,
      item.title,
      moment(item.createdAt).format('DD-MM-YYYY'),
      stateItem(item.state),
      item.vote,
      item.propietary,
    ]);

  if (loading) {
    return <p>Cargando...</p>;
  }
  if (error) {
    return <p>Error</p>;
  }

  //delete functionality
  const handleDeleteDialogOpen = id => {
    setDeleteId(id);
    setIsDeleteConfirmOpen(true);
  };

  const handleDeleteDialogClose = confirm => {
    setIsDeleteConfirmOpen(false);
    if (confirm) {
      deleteFunctionality({
        variables: {
          input: {
            id: deleteId,
          },
        },
      }).then(() => {
        toastDispatch({
          isOpen: true,
          type: "success",
          message: "Funcionalidad eliminada correctamente",
        });
        //clear cache...
        client.clearStore()
        .then( data=>
          refetch()
        )
      });
    }
  };

  return (
    <>
    {
      //viewOnefunctionality
      viewId &&
       <FunctionalityDialog
          isOpen={isFunctionalityConfirmOpen}
          handleClose={handleFunctionalityDialogClose}
          id={viewId ? viewId : null}
      />
    }
     {/* {Confirm deleteFunctionalitydialog} */}
    <ConfirmDialogFunctionality
      isOpen={isDeleteConfirmOpen}
      handleClose={handleDeleteDialogClose}
    />    
      <PageTitle title="Funcionalidades" />
      <Grid item xs={12}>
      <Typography variant='h4'>
        Listado de funciones nuevas que implementaremos en la aplicación
      </Typography>
      <Typography style={{"paddingTop": "20px"}} variant='subtitle1'>
      {"¿Podemos mejorar la aplicación?, ¿Necesitas una función que no está implementada? Agregala a la lista cuanto antes así recibe votos y la implementamos lo antes posible."}
      </Typography>
      <Typography variant='subtitle1'>
      {"También podes votar por otras funciones en la lista, las funciones con más votos se implementarán más rápido."}
      </Typography>
      </Grid>
      <Grid container spacing={4} style={{"paddingTop": "20px"}}>
        <Grid item xs={12}>
          <ThemeProvider theme={theme}>
            <MUIDataTable
              title={"Funcionalidades"}
              columns={columns}
              data={
                dataFunction(
                  data.getAllFunctionality 
                  //order Asc
                  .slice()
                  .sort((a,b)=>b.quantity - a.quantity))
                }
              options={options}
            />
          </ThemeProvider>
        </Grid>
      </Grid>
    </>
  );
}

//Vote Buttons
const VoteButtons =( { handleActionUser , id , isVote , isOpen })=>{
  return(
    <Grid item>
   
        {
          isOpen ?
            isVote ?
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                onClick={() => handleActionUser(id, isVote)}
              >
                votado
              </Button>
            :
              <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => handleActionUser(id, isVote)}
                >
              votar
            </Button>
          :
        <></>    
      }
    </Grid>
  )
}
