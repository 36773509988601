import React, { useState } from "react";
import PropTypes from "prop-types";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { Button, makeStyles, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  actionButtons: {
    flex: "1 1 100%",
    display: "flex",
    alignItems: "flex-end",
    marginTop: '1rem',
  },
  loading: {
    marginLeft: "1rem",
  },
}));

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
export const VehiclePhotos = ({
  data,
  pushInput,
  handleBack,
  loading,
  handleSubmit,
}) => {
  const classes = useStyles();
  const [state, setState] = useState({
    files: data.photos.length ? data.photos.map(fileItem => fileItem.file) : [],
    imageIds: [],
  });
  return (
    <>
      <FilePond
        files={state.files}
        allowMultiple={true}
        allowReorder={true}
        maxFiles={10}
        name="files"
        onupdatefiles={fileItems => {
          setState({
            files: fileItems.map(fileItem => fileItem.file),
          });
          pushInput({
            photos: fileItems.map((fileItem, index) => ({
              file: fileItem.file,
              position: index + 1,
            })),
          });
        }}
      />
      <div className={classes.actionButtons}>
        <Button onClick={handleBack}>Atrás</Button>
        <Button
          disabled={loading}
          color="primary"
          variant="contained"
          type="submit"
          onClick={handleSubmit}
        >
          Crear vehículo
        </Button>
        {loading && <CircularProgress size={35} className={classes.loading} />}
      </div>
    </>
  );
};

VehiclePhotos.propTypes = {
  pushInput: PropTypes.func.isRequired,
};
