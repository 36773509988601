import React, { useEffect, useState } from "react";
import NumberFormat from 'react-number-format';
const { useSubscription, gql } = require("@apollo/client");

const AUCTIONS_SUBSCRIPTION = gql`
subscription OnAuctionAdded($auctionId: String!) {
  newBidAuction(auctionId: $auctionId){
    id
    bidPrice
    auctionId
  }
}
`;

export function LatestAuction({ auctionData , setNewPrice  }) {

  const [currentPrice , setCurrentPrice] = useState(auctionData.currentPrice);
 
    const { data, loading , error } = useSubscription(
      AUCTIONS_SUBSCRIPTION,
      { variables: { auctionId:auctionData.id.toString() } }
    );
    useEffect(()=>{
      if(data && data.newBidAuction){
          setCurrentPrice(data.newBidAuction.bidPrice)
        if(setNewPrice){
         setNewPrice(data?.newBidAuction.bidPrice || auctionData)
        } 
      }
    },[loading, data, setNewPrice, auctionData])
  
    if( loading ) {
      return <NumberFormat value={auctionData.currentPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={'2'} />
    }
    if(error){
      return error
    }
    return <NumberFormat value={currentPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={'2'} />
  }
  