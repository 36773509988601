import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Breadcrumbs,
  Link,
} from "@material-ui/core";
import { Link as RouterLink, useParams } from "react-router-dom";
import { gql, useMutation, useQuery, useApolloClient } from "@apollo/client";
import { AccountForm } from "../components";
import { useToastDispatch } from "../../../context/ToastContext";

const GET_ACCOUNT = gql`
  query getAccount($id: ID!) {
    account(id: $id) {
      id
      name
      phone
      contactName
      email
      commercialDataAddress
      commercialDataState
    }
  }
`;

const EDIT_ACCOUNT = gql`
  mutation updateAccount($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      id
    }
  }
`;
export const AccountEdit = () => {
  const client = useApolloClient();
  const { id } = useParams();
  const toastDispatch = useToastDispatch();

  const { loading: loadingQuery, error: errorQuery, data } = useQuery(
    GET_ACCOUNT,
    {
      variables: {
        id,
      },
    },
  );
  const [updateAccount, {loading: mutationLoading}] = useMutation(EDIT_ACCOUNT);

  if (loadingQuery) {
    return <p>Loading...</p>;
  }
  if (errorQuery) {
    return <p>Error</p>;
  }

  const handleSubmit = value => {
    value = { ...value, id };
    updateAccount({ variables: { input: value } }).then(() => {
      client.clearStore();
      toastDispatch({
        isOpen: true,
        type: "success",
        message: "Account is successfully edited",
      });
    });
  };
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={8}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" component={RouterLink} to="/admin/accounts">
            Cuenta
          </Link>
          <Link
            color="textPrimary"
            component={RouterLink}
            to={`/admin/accounts/edit/${id}`}
            aria-current="page"
          >
            Editar Cuenta
          </Link>
        </Breadcrumbs>
        <Card>
          <CardHeader title="Editar cuenta" />
          <CardContent>
            {data.account ? (
              <AccountForm
                data={data.account}
                submitText="Editar"
                onSubmit={handleSubmit}
                loading={mutationLoading}
              />
            ) : (
              <p>No Data Found</p>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
