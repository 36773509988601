import React, {} from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  formControl: {
    margin: "0.5rem 0 !important",
    width: "20rem",
  },
}));

const UPDATE_AUCTION_DETAIL = gql`
    mutation UpdateAuctionDetail($input: UpdateAuctionDetailInput!) {
        updateAuctionDetail(input: $input){
            id
            initialPrice
            incrementUnit
            observations
            endedAt
        }
    }
`;
export const UpdateAuctionDetailDialog = ({ handleClose, data }) => {
  const classes = useStyles();
  const [itemData, setItemData] = React.useState({
    initialPrice: 0,
    incrementUnit: 0,
    observations: "",
  });
  React.useEffect(() => {
    if (data) {
      setItemData({
        initialPrice: data.initialPrice || 0,
        incrementUnit: data.incrementUnit || 0,
        observations: data.observations || "",
      });
      setSelectedDate(+data.endedAt);
    }
  }, [data]);
  const [selectedDate, setSelectedDate] = React.useState(data.endedAt ? new Date(+data.endedAt) : new Date());
  const [updateAuctionDetail] = useMutation(UPDATE_AUCTION_DETAIL);
  const onSubmit = (e) => {
    e.preventDefault();
    updateAuctionDetail({
      variables: {
        input: {
          id: data.id,
          initialPrice: +itemData.initialPrice,
          incrementUnit: +itemData.incrementUnit,
          observations: itemData.observations,
          endedAt: selectedDate,
        },
      },
    }).then(() => handleClose());
  };
  const handleChange = (key) => e => {
    setItemData({
      ...itemData,
      [key]: e.target.value,
    });
  };
  return (
    <Dialog open={!!data} onClose={handleClose} aria-labelledby="form-dialog-title">
      <form className={classes.form} onSubmit={onSubmit}>
        <DialogTitle id="form-dialog-title">Actualizar el detalle de la subasta</DialogTitle>
        <DialogContent>
          <TextField
            className={classes.formControl}
            name="initialPrice"
            label="Precio inicial de la subasta($)"
            variant="outlined"
            type="number"
            step="0.01"
            onChange={handleChange("initialPrice")}
            value={itemData.initialPrice}/>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              name="endDate"
              label="Fecha de finalización"
              value={selectedDate}
              onChange={setSelectedDate}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
          <TextField
            className={classes.formControl}
            name="incrementUnit"
            label="Incremento mínimo($)"
            variant="outlined"
            type="number"
            step="0.01"
            onChange={handleChange("incrementUnit")}
            value={itemData.incrementUnit}/>
          <TextField
            className={classes.formControl}
            name="observations"
            label="Observaciones"
            variant="outlined"
            onChange={handleChange("observations")}
            value={itemData.observations}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancelar</Button>
          <Button color="primary" type="submit">Enviar</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
