import React, { useState } from 'react';
import {
    Button,
  } from "@material-ui/core";
  
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import { useToastDispatch } from '../../context/ToastContext';
import { ConfirmDialog } from './inspectModal';


const TO_INSPECT_CAR = gql`
    mutation toInspectCar($input:InputInspectCar!) {
        toInspectCar(input:$input)
    }
`

const ToInspectButton =(props)=>{
  var {
    userId,
    carListingId ,
    city ,
    province,
    brand,
    model,
    year,
    phone,
    propietary,
    linkToCar
  } = props;

  let aceptedProvinces=["Buenos Aires" , "Cordoba" , "Córdoba", "Capital Federal"];
  carListingId = parseInt(carListingId);
  const toastDispatch = useToastDispatch();
  const [ toInspectCar , {loading}] = useMutation(TO_INSPECT_CAR);
    const handleClick = id =>{
        toInspectCar({
            variables: {
              input: {
                userId: parseInt(userId),
                carListingId ,
                city ,
                province,
                brand,
                model,
                year,
                phone,
                propietary,
                linkToCar
              },
            },
          }).then((data) => {
            toastDispatch({
              isOpen: true,
              type: "success",
              message: "El vehículo se a enviado a inspecciónar correctamente.",
            });
          })
          .catch((errorM)=>{
            toastDispatch({
              isOpen: true,
              type: "error",
              message: `${errorM.message}`,
            });
          });
    }
    
  const [inspectConfirmOpen , setConfirmOpen] = useState(false);

    const handleInspectDialogClose = confirm => {
      setConfirmOpen(false);
      if(confirm === true){
        return handleClick();
      }
    };

    const itsInProvince = aceptedProvinces.find((element)=>element === province);

    return( 
      <>
      <ConfirmDialog
          isOpen={inspectConfirmOpen}
          handleClose={handleInspectDialogClose}
      />
      {
        itsInProvince &&
        <Button
            size="large"
            variant="outlined"
            color="secondary"
            onClick={()=>setConfirmOpen(true)}
            disabled={!loading ? false : true}
        >
            {
              !loading ? `Inspeccionar Auto`:`Enviando inspección`
            }
        </Button>
      }
        </>
    )
} 

export default ToInspectButton;
