import React from "react";
import {
  Select,
  InputLabel,
  MenuItem,
  ListItemText,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery, gql } from "@apollo/client";
import { Controller, useWatch } from "react-hook-form";

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  form: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: "5px 5px",
    flex: "1 1 30%",
    textAlign: "left",
  },
  formControlSingle: {
    margin: "5px 5px",
    flex: "0 0 32.2%",
    textAlign: "left",
  },
  actionButtons: {
    flex: "1 1 100%",
    display: "flex",
    alignItems: "flex-end",
    marginTop: "1rem",
  },
}));

export function YearForm(props) {
  const { control, errors } = props;
  const classes = useStyles();
  var date = new Date();
  var year = date.getFullYear() + 1;
  var menuItems = [];
  for (var i = 1995; i < year; i++) {
    menuItems.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
  }

//  console.log(menuItems);
  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel>Año</InputLabel>
      <Controller
        as={
          <Select>
            {menuItems}
          </Select>
        }
        control={control}
        defaultValue=""
        label="Year"
        name="year"
      />
      <FormHelperText error={true}>
        {errors.yearId && errors.yearId.message}
      </FormHelperText>
    </FormControl>
  );
}
