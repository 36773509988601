import React, { useState, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  createMuiTheme,
  Grid,
  IconButton,
  ThemeProvider,
  Tooltip,
  makeStyles,
  Switch,
  Typography
} from "@material-ui/core";

import MUIDataTable from "mui-datatables";
import AddIcon from "@material-ui/icons/Add";

// components
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import gql from "graphql-tag";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { useToastDispatch } from "../../../context/ToastContext";
import { useUserState } from "../../../context/UserContext";
import translation from "../../translations/translations_muidatatable";
import PageTitle from "../../../components/PageTitle/PageTitle";

const theme = createMuiTheme({
  overrides: {
    MuiTable: {
      root: {
        border: [[1, "solid", "#d3d3d3"]],
        marginBottom:"3rem"
      },
    },
    MuiTableCell: {
      root: {
        borderColor: "#d3d3d3",
      },
      head: {
        background: "lightgrey",
        fontWeight:"bold"
      },
    },
    MuiTableSortLabel: {
      root: {
        alignItems: "flex-start",
      },
    },
    MuiTableFooter: {
      root: {
        background: "lightgrey",
      },
    },
  },
});

const useStyles = makeStyles(theme => ({
  action: {
    minWidth: "161px",
    alignContent:"center"
  },
  margin: {
    position:"fixed",
    right:'10%',
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  title:{
    textAlign:"left",
    marginBottom:"1rem"
  },
  subtitle:{
    marginBottom:"1rem"
  },
}));

const GET_CITIES_RATES = gql`
  query citiesRates{
    citiesRates{
      id
      city_name
      price
    }
  }
`;

const DELETE_ORDER = gql`
  mutation deleteCityRates($input: InputUserCityRates) {
    deleteCityRates(input: $input)
  }
`;

export function CityRatesTable() {
  const classes = useStyles();
  const [deleteCityRates] = useMutation(DELETE_ORDER);
  const client = useApolloClient();
  const { loading, error, data, refetch } = useQuery(GET_CITIES_RATES);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [id, setId] = useState(null);
  const toastDispatch = useToastDispatch();
  const userState = useUserState();
 
  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        sortable: false,
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
     name: "city_name",
     label: "Localidad",
     options: { sortable: true, filter: true },
    },
    {
      name: "price",
      label: "Precio",
      options: { sortable: true, filter: true ,
        customBodyRender: (dataIndex, tableMeta, updateValue) => {
          return `$ ${tableMeta.rowData[2]}`
        }
      },
    },
    {
      name: "actions",
      label: `${ userState.isAdmin ? "Operaciones" : " "}`,
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (dataIndex, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          return (
            <Grid
              container
              spacing={1}
              direction="row"
              className={classes.action}
            >
              {
                 userState.isAdmin &&
                 <>
                  <Grid item>
                    <ButtonGroup
                      size="small"
                      aria-label="small outlined button group"
                    >
                      <Button
                        size="small"
                        variant="outlined"
                        color="secondary"
                        component={RouterLink}
                        to={`/app/cityrates/edit/${id}`}
                      >
                        Editar
                      </Button>
                    </ButtonGroup>
                  </Grid>
                  <Grid item>
                    <ButtonGroup
                      size="small"
                      aria-label="small outlined button group"
                    >
                      <Button
                        size="small"
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleDeleteDialogOpen(tableMeta.rowData[0])}
                      >
                        Borrar
                      </Button>
                    </ButtonGroup>
                  </Grid>
                </>
              }
            </Grid>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: "none",
    customToolbar: (isAdmin) => {
      return (
        
        <Tooltip title="Crear ciudad">
          <IconButton component={RouterLink} to="/app/cityrates/create">
            <AddIcon />
          </IconButton>
        </Tooltip>
      );
    },
    textLabels: {
      body: {
        noMatch: "No hay ciudades cargadas en el sistema",
      },
    },
  };

  const handleDeleteDialogOpen = id => {
    setId(id);
    setIsDeleteConfirmOpen(true);
  };
  const handleDeleteDialogClose = confirm => {
    setIsDeleteConfirmOpen(false);
    if (confirm) {
      deleteCityRates({
        variables: {
          input: {
            id: id,
          },
        },
      }).then(() => {
        client.clearStore().then(data => {
            toastDispatch({
            isOpen: true,
            type: "success",
            message: "Ciudad borrada exitosamente",
          });
          refetch();
        })
      });
    }
  };

  const dataFunction = items =>
    items.map(item => [
      item.id,
      item.city_name,
      item.price,
  ]);

  if (loading) {
    return <p>Cargando...</p>;
  }
  if (error) {
    console.log(error)
    return <p>Error</p>;
  }

  return (
    <>
      <ConfirmDialog
        isOpen={isDeleteConfirmOpen}
        handleClose={handleDeleteDialogClose}
      />
      <PageTitle title="Precios de viaticos de inspecciones" />
      <Grid item xs={12}>
      <Typography variant='subtitle1'>
        Listado del costo extra de viaticos a localidades
      </Typography>
      </Grid>
      <Grid container spacing={4} style={{"paddingTop": "20px"}}>
        <Grid item xs={12}>
          <ThemeProvider theme={theme}>
            <MUIDataTable
              title={"Ciudades"}
              columns={columns}
              data={dataFunction(data.citiesRates)}
              options={userState.isAdmin ? options : {selectableRows: "none", textLabels: translation['textLabels'] }}
            />
          </ThemeProvider>
        </Grid>
      </Grid>
    </>
  );
}
