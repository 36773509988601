import React from "react";
import {
  TextField,
  Switch,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery, gql } from "@apollo/client";

const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  formControl: {
    margin: "5px 5px",
    flex: "1 1 40%",
    textAlign: "left",
  },
  buttonWrap: {
    flex: "1 1 100%",
    display: "flex",
    alignItems: "flex-end",
  },
  submitButton: {
    marginTop: "1rem",
  },
  loading: {
    marginLeft: "1rem",
  },
}));

export const CityRatesForm = ({
  data,
  submitText,
  onSubmit,
  loading,
}) => {
  const classes = useStyles();
  const { register, handleSubmit, watch, errors, control } = useForm();

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
      <TextField
        className={classes.formControl}
        defaultValue={data.city_name}
        error={errors.city_name ? true : false}
        helperText={errors.city_name ? errors.city_name.message : ""}
        InputLabelProps={{ required: true }}
        inputRef={register({ required: "Ciudad  es requerida" })}
        label="Ciudad"
        name="city_name"
        type="text"
        variant="outlined"
      />
      <TextField
        className={classes.formControl}
        defaultValue={data.price}
        error={errors.price ? true : false}
        helperText={errors.price ? errors.price.message : ""}
        InputLabelProps={{ required: true }}
        inputRef={register({ required: "Precio es requerido" })}
        label="Precio"
        name="price"
        type="number"
        variant="outlined"
      />
      <div className={classes.buttonWrap}>
        <Button
          className={classes.submitButton}
          disabled={loading}
          variant="contained"
          color="primary"
          type="submit"
        >
          {submitText}
        </Button>
        {loading && <CircularProgress size={35} className={classes.loading} />}
      </div>
    </form>
  );
};
