import React, { useState } from "react";
import {
  Select,
  InputLabel,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
} from "@material-ui/core";
import { useQuery, gql } from "@apollo/client";

const GET_BRAND_MODELS = gql`
  query getVersions {
    getVersions {
      id
      name
      model_id
    }
  }
`;

export function VersionFilter(props) {
  const { filterList, onChange, index, column, carModelId } = props;
  const { loading, error, data, fetchMore } = useQuery(GET_BRAND_MODELS);
  const [selectedValue, setSelectedValue] = useState(filterList[index] && filterList[index].length > 0 ? filterList[index]: "");
  if (loading) return "Cargando...";
  if (error) return `Error! ${error.message}`;
  const optionValues = data.getVersions.filter(version => version.model_id == carModelId);
  return (
    <FormControl>
      <InputLabel htmlFor="select-multiple-chip">Versión</InputLabel>
      <Select
        value={selectedValue}
        onChange={event => {
          setSelectedValue(event.target.value);
          filterList[index] = [event.target.value];
          onChange(filterList[index], index, column);
        }}
      >
        {optionValues.map((item,key) => (
          <MenuItem key={item.id} value={item.name}>
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
