import React from "react";
import { Grid } from "@material-ui/core";
import { useQuery } from '@apollo/client';
import { useTheme } from "@material-ui/styles";
import useStyles from "../../dashboard/styles";
import { pieColors } from "./queries/reports"; 

import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";

import Widget from "../../../components/Widget/Widget";
import { Typography } from "../../../components/Wrappers/Wrappers";
import Dot from "../../../components/Sidebar/components/Dot";

export default function PieChartDisplay( props ) {
  var classes = useStyles();
  var theme = useTheme();
  const { loading, error, data } = useQuery(
    props.query,
    {
      fetchPolicy: "cache-and-network"
    }
  );

  if (loading) return "Cargando...";
  if (error) return `Error! ${error.message}`;
  return (
          <Widget title={props.title} upperTitle className={classes.card}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <ResponsiveContainer width="100%" minHeight={365}>
                  <PieChart margin={{ left: theme.spacing(2) }}>
                    <Pie
                      data={data[props.field].rows}
                      innerRadius={50}
                      outerRadius={80}
                      dataKey={props.dataKey}
                      label
                      isAnimationActive={true}
                      labelLine={true}
                    >
                    {data[props.field].rows.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill= {pieColors[index]}
                      />
                    ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>
              </Grid>
              <Grid item xs={4}>
                 <div className={classes.pieChartLegendWrapper}>
                   {data[props.field].rows.map((item, index) => (
                    <div key={pieColors[index]} className={classes.legendItemContainer}>
                       <Dot color={pieColors[index]} />
                       <Typography style={{ whiteSpace: "nowrap" }}>
                         &nbsp;{item[props.displayColumns[0]]['name']}
                       </Typography>
                        <Typography color="text" colorBrightness="secondary">
                        &nbsp;{item[props.displayColumns[1]]}
                       </Typography>   
                    </div>
                  ))}
                </div>
              </Grid>
            </Grid>
          </Widget>
  )
}