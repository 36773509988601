import React from "react";
import {
  Select,
  InputLabel,
  MenuItem,
  ListItemText,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery, gql } from "@apollo/client";
import { Controller, useWatch } from "react-hook-form";

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  form: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: "5px 5px",
    flex: "1 1 30%",
    textAlign: "left",
  },
  formControlSingle: {
    margin: "5px 5px",
    flex: "0 0 32.2%",
    textAlign: "left",
  },
  actionButtons: {
    flex: "1 1 100%",
    display: "flex",
    alignItems: "flex-end",
    marginTop: "1rem",
  },
}));

const GET_BRAND_MODELS = gql`
  query getBrandModels($brandId: Int!) {
    getBrandModels(brandId: $brandId) {
      id
      name
      brandName
    }
  }
`;

export function ModelForm(props) {
  const { data, errors, control } = props;
  const brandId = useWatch({
    control,
    name: "brandId",
    defaultValue: data.brandId || 0,
  });
  const { loading, error, data: modelData } = useQuery(
    GET_BRAND_MODELS,
    {
      variables: {
        brandId: parseInt(brandId),
      },
    },
  );
  const classes = useStyles();
  if (loading) return "Cargando...";
  if (error) return `Error! ${error.message}`;
  const optionValues = brandId ? modelData.getBrandModels : [];
  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel>Modelo</InputLabel>
      <Controller
        as={
          <Select error={errors.carModelId ? true : false}>
            {optionValues.map(item => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        }
        control={control}
        defaultValue={data.carModelId}
        label="Modelo"
        name="carModelId"
      />
      <FormHelperText error={true}>
        {errors.carModelId && errors.carModelId.message}
      </FormHelperText>
    </FormControl>
  );
}
