import { gql } from "@apollo/client";

export const getPricingQuery = gql`
query getPricingCars(
  $fromDate: DateTime
  $toDate: DateTime
  $page: Int
  $orderColName: String
  $orderDirection: String
  $rowsPerPage: Int
  $filter: String
) {
  getPricingCars (
    fromDate: $fromDate
    toDate: $toDate
    page: $page
    orderColName: $orderColName
    orderDirection: $orderDirection
    rowsPerPage: $rowsPerPage
    filter: $filter
  ) {
    rows {
      brand {
        id
        name
      }
      brand_model {
        id
        name
      }
      version {
        id
        name
      }
      year
      transmissiontype
      fueltype
      daysToSell
      price
      minPrice
      maxPrice
      address_state
      amountSoldLastMonth
    }
    totalCount
}
}
`;


export const getPricingHistoricalQuery = gql`
  query getPricingCarsHistorical(
    $fromDate: DateTime
    $toDate: DateTime
    $page: Int
    $orderColName: String
    $orderDirection: String
    $rowsPerPage: Int
    $filter: String
  ) {
    getPricingCarsHistorical (
      fromDate: $fromDate
      toDate: $toDate
      page: $page
      orderColName: $orderColName
      orderDirection: $orderDirection
      rowsPerPage: $rowsPerPage
      filter: $filter
    ) {
      rows {
        totalSoldCars
        soldMonth
        soldYear
        brand {
          id
          name
        }
        brand_model {
          id
          name
        }
        version {
          id
          name
        }
        year
        transmissiontype
        fueltype
        daysToSell
        price
        minPrice
        maxPrice
        address_state
        amountSoldLastMonth
        address_state
      }
      totalSum
    }
  }
  `;

export const getMostSoldCarsByModelQuery = gql`
  query GetMostSolbyModels(
    $fromDate: DateTime
    $toDate: DateTime
    $page: Int
    $orderColName: String
    $orderDirection: String
    $rowsPerPage: Int
    $filter: String
  ) {
    getMostSoldbyModels(
      fromDate: $fromDate
      toDate: $toDate
      page: $page
      orderColName: $orderColName
      orderDirection: $orderDirection
      rowsPerPage: $rowsPerPage
      filter: $filter
    ) {
      rows {
        year
        mileage
        price
        fueltype
        transmissiontype
        condtion
        tipovendedor
        listingtype
        totalSoldCars
        totalPriceModel
        address_state
        address_city
        created_at
        avgDaysToSellCar
        trimPriceModel
        brand {
          name
        }
        brand_model {
          name
        }
        version {
          id
          name
          model_id
        }
      }
      totalCount
    }
  }
`;
export const getSoldCarsCoordinates = gql`
  query GetSoldCarsCoordinates(
    $fromDate: DateTime
    $toDate: DateTime
    $page: Int
    $orderColName: String
    $orderDirection: String
    $rowsPerPage: Int
    $filter: String
    $brandId: Int
    $modelId: Int
    $year: Int
    $transmissiontype: String
    $fueltype: String
  ) {
    getSoldCarsCoordinates(
      fromDate: $fromDate
      toDate: $toDate
      page: $page
      orderColName: $orderColName
      orderDirection: $orderDirection
      rowsPerPage: $rowsPerPage
      filter: $filter
      brandId: $brandId
      modelId: $modelId
      year: $year
      transmissiontype: $transmissiontype
      fueltype: $fueltype
    ) {
      rows {
        lat
        lng
      }
      totalCount
    }
  }
`;
export const getFiveTopMostSoldCarsByModelQuery = gql`
  query GetTopFiveMostSoldbyModels($fromDate: DateTime, $toDate: DateTime) {
    getTopFiveMostSoldbyModels(fromDate: $fromDate, toDate: $toDate) {
      rows {
        totalSoldCars
        totalPriceModel
        brand {
          name
        }
        brand_model {
          name
        }
      }
      totalCount
    }
  }
`;

export const getFiveTopMostPublishCarsByModelQuery = gql`
  query GetFiveTopMostPublishCars($fromDate: DateTime, $toDate: DateTime) {
    getFiveTopMostPublishCars(fromDate: $fromDate, toDate: $toDate) {
      rows {
        totalPublishCars
        totalPriceModel
        brand {
          name
        }
        brand_model {
          name
        }
      }
      totalCount
    }
  }
`;

export const getPublishLiveCarsQuery = gql`
  query getPublishCarsQuery(
    $fromDate: DateTime
    $toDate: DateTime
    $page: Int
    $orderColName: String
    $orderDirection: String
    $rowsPerPage: Int
    $filter: String
  ) {
    getPublishLiveCars(
      fromDate: $fromDate
      toDate: $toDate
      page: $page
      orderColName: $orderColName
      orderDirection: $orderDirection
      rowsPerPage: $rowsPerPage
      filter: $filter
    ) {
      rows {
        id
        year
        mileage
        price
        currency_code
        fueltype
        transmissiontype
        condtion
        tipovendedor
        listingtype
        address_state
        address_city
        created_at
        phone
        link
        version {
          id
          name
          model_id
        }
        brand {
          name
        }
        brand_model {
          name
        }
        carAvgPrice {
          price
          minPrice
          maxPrice
        }
      }
      totalCount
    }
  }
`;


export const getCarsOffersQuery = gql`
  query getCarsOffers(
    $fromDate: DateTime
    $toDate: DateTime
    $page: Int
    $orderColName: String
    $orderDirection: String
    $rowsPerPage: Int
    $filter: String
  ) {
    getCarsOffers(
      fromDate: $fromDate
      toDate: $toDate
      page: $page
      orderColName: $orderColName
      orderDirection: $orderDirection
      rowsPerPage: $rowsPerPage
      filter: $filter
    ) {
      rows {
        id
        year
        mileage
        price
        fueltype
        transmissiontype
        condtion
        tipovendedor
        listingtype
        address_state
        address_city
        created_at
        phone
        link
        profit
        profitPercentage
        currency_code
        version {
          id
          name
          model_id
        }
        brand {
          name
        }
        brand_model {
          name
        }
        carAvgPrice {
          price
          minPrice
          maxPrice
        }
      }
      totalCount
    }
  }
`;

export const getRecentlyCarsQuery = gql`
  query getRecentlyCars(
    $fromDate: DateTime
    $toDate: DateTime
    $page: Int
    $orderColName: String
    $orderDirection: String
    $rowsPerPage: Int
    $filter: String
  ) {
    getRecentlyCars(
      fromDate: $fromDate
      toDate: $toDate
      page: $page
      orderColName: $orderColName
      orderDirection: $orderDirection
      rowsPerPage: $rowsPerPage
      filter: $filter
    ) {
      rows {
        id
        year
        mileage
        price
        fueltype
        transmissiontype
        condtion
        tipovendedor
        listingtype
        address_state
        currency_code
        address_city
        created_at
        phone
        link
        profit
        profitPercentage
        version {
          id
          name
          model_id
        }
        brand {
          name
        }
        brand_model {
          name
        }
        carAvgPrice {
          price
          minPrice
          maxPrice
        }
      }
      totalCount
    }
  }
`;

export const getCarsOriginalPriceDiscounted = gql`
  query getCarsReducedOriginalPrice (
    $fromDate: DateTime
    $toDate: DateTime
    $page: Int
    $orderColName: String
    $orderDirection: String
    $rowsPerPage: Int
    $filter: String
  ) {
    getCarsReducedOriginalPrice (
      fromDate: $fromDate
      toDate: $toDate
      page: $page
      orderColName: $orderColName
      orderDirection: $orderDirection
      rowsPerPage: $rowsPerPage
      filter: $filter
    ) {
      rows {
        id
        year
        mileage
        price
        fueltype
        transmissiontype
        condtion
        tipovendedor
        listingtype
        address_state
        address_city
        created_at
        phone
        link
        priceDifference
        currency_code
        search_price
        previous_price
        profit
        version {
          id
          name
          model_id
        }
        brand {
          name
        }
        brand_model {
          name
        }
        carAvgPrice {
          price
          minPrice
          maxPrice
        }
      }
      totalCount
    }
  }
`;

export const getFastestSoldQuery = gql`
  query getFastestSold(
    $fromDate: DateTime
    $toDate: DateTime
    $page: Int
    $orderColName: String
    $orderDirection: String
    $rowsPerPage: Int
    $filter: String
  ) {
    getFastestSold(
      fromDate: $fromDate
      toDate: $toDate
      page: $page
      orderColName: $orderColName
      orderDirection: $orderDirection
      rowsPerPage: $rowsPerPage
      filter: $filter
    ) {
      rows {
        id
        year
        mileage
        price
        fueltype
        transmissiontype
        condtion
        tipovendedor
        listingtype
        address_state
        address_city
        created_at
        phone
        link
        profit
        profitPercentage
        currency_code
        version {
          id
          name
          model_id
        }
        brand {
          name
        }
        brand_model {
          name
        }
        carAvgPrice {
          price
          minPrice
          maxPrice
          daysToSell
        }
      }
      totalCount
    }
  }
`;

export const GET_BRANDS = gql`
  query getBrands {
    getBrands {
      name
    }
  }
`;

export const pieColors = [
  "#52D726",
  "#FFEC00",
  "#FF7300",
  "#007ED6",
  "#7CDDDD",
];
