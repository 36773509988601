import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  createMuiTheme,
  Grid,
  ThemeProvider,
  makeStyles,
  Tooltip,
  Switch,
  Typography,
} from "@material-ui/core";
import {
  Email as EmailIcon,
} from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import AddIcon from "@material-ui/icons/Add";

// components
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import translation from "../../translations/translations_muidatatable";


const theme = createMuiTheme({
  overrides: {
    MuiTable: {
      root: {
        border: [[1, "solid", "#d3d3d3"]],
      },
    },
    MuiTableCell: {
      root: {
        borderColor: "#d3d3d3",
      },
      head: {
        background: "lightgrey",
        fontWeight:"bold"
      },
    },
    MuiTableSortLabel: {
      root: {
        alignItems: "flex-start",
      },
    },
    MuiTableFooter: {
      root: {
        background: "lightgrey",
      },
    },
  },
});
const useStyles = makeStyles(theme => ({
  action: {
    minWidth: "163px",
  },
  title:{
    textAlign:"center",
    marginBottom:"1rem"
  },
  subtitle:{
    marginBottom:"1rem"
  }
}));

const GET_ALL_BUDGET_CUSTOMERS = gql`
  query getAllBudgetCustomers {
    getAllBudgetCustomers {
      id
      name
      phone
      email
      preferredCarType
      preferredYear
      preferredPriceRange
      birthday
      comments
      disabled
      lastSend{
        createdAt
      }
    }
  }
`;


export function BudgetCustomerPage() {
  const classes = useStyles();
  const { loading, error, data } = useQuery(GET_ALL_BUDGET_CUSTOMERS);


  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        sortable: false,
        filter: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "name",
      label: "Nombre y Apellido",
      options: { sortable: true, filter: true },
    },
    {
      name: "phone",
      label: "Teléfono",
      options: { sortable: true, filter: true },
    },
    {
      name: "email",
      label: "Email",
      options: { sortable: true, filter: true },
    },
    {
      name: "birthday",
      label: "Cumpleaños",
      options: { sortable: false, filter: false },
    },
    {
      name: "preferredCarType",
      label: "Vehículo de prefrencia",
      options: { sortable: false, filter: false },
    },
    {
      name: "preferredYear",
      label: "Año",
      options: { sortable: false, filter: false },
    },
    {
      name: "preferredPriceRange",
      label: "Rango de precio",
      options: { sortable: false, filter: false },
    },
    {
      name: "disabled",
      label: "disabled",
      options: { 
        sortable: false,
        filter: false,
        display: false,
        viewColumns: false,
       },
    },
    {
      name: "comments",
      label: "Comentarios",
      options: { display: false, filter: false },
    },
    {
      name: "lastSend",
      label: "Presupuesto enviado",
      options: { 
        sortable: false,
        filter: false,
        customBodyRender: (dataIndex, tableMeta, updateValue) => {
          let id = tableMeta.rowData[0];
          let lastSendDate = tableMeta.rowData[10];
          return (
            <Grid
              container
              spacing={1}
              direction="row"
              className={classes.action}
            >
              <Grid item>
                {
                  lastSendDate &&
                  <Button
                    id={id}
                    size="small"
                  >
                    <Tooltip title = {
                      `
                        Última vez presupuestado: ${lastSendDate.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1')}  
                      `}
                      aria-label="info" >
                      <EmailIcon style={{color:"#3f50b5"}} />
                  </Tooltip>
                </Button>
                }
              </Grid>
            </Grid>
          );
        }
      },
    },
    {
      name: "actions",
      label: "Operaciones",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (dataIndex, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
      
          return (
            <Grid
              container
              spacing={1}
              direction="row"
              className={classes.action}
            >
              <Grid item>
                <ButtonGroup
                  size="small"
                  aria-label="small outlined button group"
                >
                  <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    component={RouterLink}
                    disabled={tableMeta.rowData[8]}
                    to={`/app/budget/create/${id}`}
                  >
                    Presupuestar
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: "none",
    textLabels: translation['textLabels']
  };

  if (loading) {
    return <p>Cargando...</p>;
  }
  if (error) {
    console.log(error)
    return <p>Error</p>;
  }
  let setPrice = item=>{
    return ` $ ${item}`
  }
  const dataFunction = items =>
    items.map(item => [
      item.id && item.id ,
      item.name && item.name,
      item.phone && item.phone,
      item.email && item.email,
      item.birthday && item.birthday.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1'),
      item.preferredCarType &&  item.preferredCarType,
      item.preferredYear && item.preferredYear,
      item.preferredPriceRange && setPrice(item.preferredPriceRange),
      item.disabled && item.disabled,
      item.comments && item.comments,
      item.lastSend && item.lastSend.createdAt.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1')
    ]);

  return (
    <>
      <Typography variant="h1" className={classes.title}>
        Envio de presupuestos
      </Typography>
      <Typography variant="h5" className={classes.subtitle}>
        Seleccione cliente
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <ThemeProvider theme={theme}>
            <MUIDataTable
              title={"Clientes"}
              columns={columns}
              data={ dataFunction(data.getAllBudgetCustomers) }
              options={options}
            />
          </ThemeProvider>
        </Grid>
      </Grid>
    </>
  );
}




