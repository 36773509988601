import React from "react";
import { Box, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Edit } from "@material-ui/icons";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles({
  header: {},
  heading: {
    marginRight: "10px",
  },
});

export const ProfileHeader = ({handleClick}) => {
  const classes = useStyles();
  return (
    <Box className={classes.headerWrap} alignItems="center" display="flex">
      <h1 className={classes.heading}>Perfil</h1>
      <IconButton component={RouterLink} to="/app/profile/edit">
        <Edit />
      </IconButton>
    </Box>
  );
};
