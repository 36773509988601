import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useUserState } from "../../../context/UserContext";
export function AdminRoute({ component, ...rest }) {

  const { isAuthenticated, isAdmin } = useUserState();
  return (
    <Route
      {...rest}
      render={props => {
        if(isAdmin === null || isAdmin === undefined){
          return <div></div>
        }
        if (!isAuthenticated) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
        if (isAdmin) {
          return React.createElement(component, props);
        }
        return <div>Uh 403</div>;
      }}
    />
  );
}
