import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  ButtonGroup,
  createMuiTheme,
  ThemeProvider,
  makeStyles,
  Typography
} from "@material-ui/core";

import { Link as RouterLink } from "react-router-dom";
import MUIDataTable from "mui-datatables";

// components
import Widget from "../../components/Widget/Widget";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { MarkAsSoldDialog, SendToAuctionDialog, UpdateAuctionDetailDialog } from "./components";
import ConfirmDialog from "../../components/ConfirmDialog";
import { useToastDispatch } from "../../context/ToastContext";
import NumberFormat from "react-number-format";
import { GET_VEHICLES_LIST } from "../cars/components/queries/cars";
import PageTitle from "../../components/PageTitle/PageTitle";

import translation from "../translations/translations_muidatatable";

const theme = createMuiTheme({
  overrides: {
    MuiTable: {
      root: {
        border: [[1, "solid", "#d3d3d3"]],
        marginBottom:"3rem"
      },
    },
    MuiTableCell: {
      root: {
        borderColor: "#d3d3d3",
      },
      head: {
        background: "lightgrey",
        fontWeight:"bold"
      },
    },
    MuiTableSortLabel: {
      root: {
        alignItems: "flex-start",
      },
    },
    MuiTableFooter: {
      root: {
        background: "lightgrey",
      },
    },
  },
});

const useStyles = makeStyles(theme => ({
  action: {
    minWidth: "163px",
    alignContent:"center"
  },
  margin: {
    position:"fixed",
    right:'10%',
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  title:{
    textAlign:"center",
    marginBottom:"1rem"
  },
  subtitle:{
    marginBottom:"1rem"
  },
}));

const REMOVE_CAR = gql`
    mutation RemoveCar($input: RemoveCarInput!) {
        removeCar(input: $input)
    }
`;

export default function VehicleTable() {
  const [removeCar] = useMutation(REMOVE_CAR);
  const { loading, error, data, refetch } = useQuery(GET_VEHICLES_LIST);
  const [isOpenMarkAsSold, setIsOpenMarkAsSold] = useState(false);
  const [isNewSendingToAuction, setIsNewSendingToAuction] = useState(false);
  const [auctionData, setAuctionData] = useState(false);
  const [id, setId] = useState(null);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const toastDispatch = useToastDispatch();

  useEffect(() => {
    if (data) {
      refetch();
    }
  }, [data]);

  const handleMarkAsSoldDialogOpen = id => {
    setId(id);
    setIsOpenMarkAsSold(true);
  };

  const handleMarkAsSoldDialogClose = () => {
    setIsOpenMarkAsSold(false);
    refetch();
  };

  const handleSendToAuctionDialogOpen = (data, isNewSendingToAuction) => {
    setIsNewSendingToAuction(isNewSendingToAuction);
    setAuctionData(data);
  };

  const handleSendToAuctionDialogClose = () => {
    setAuctionData(false);
    refetch();
  };

  const handleDeleteDialogOpen = id => {
    setId(id);
    setIsDeleteConfirmOpen(true);
  };

  const handleDeleteDialogClose = confirm => {
    setIsDeleteConfirmOpen(false);
    if (confirm) {
      removeCar({
        variables: {
          input: {
            id: id,
          },
        },
      }).then(() => {
        toastDispatch({
          isOpen: true,
          type: "success",
          message: "El vehículo ha sido creado exitosamente",
        });
        refetch();
      });
    }
  };
  const columns = [
    {
      name: "id",
      label: "ID",
      options: { sortable: false, filter: false, display: false },
    },
    {
      name: "brand",
      label: "Marca",
      options: { sortable: true, filter: true,
        customBodyRender: (dataIndex, tableMeta, updateValue) => {
          return dataIndex.name;
        }
      }
    },
    {
      name: "brand_model",
      label: "Modelo",
      options: { sortable: true, filter: true,
        customBodyRender: (dataIndex, tableMeta, updateValue) => {
          return dataIndex.name;
        }
      }
    },
    {
      name: "version",
      label: "Versión",
      options: { sortable: true, filter: true,
        customBodyRender: (dataIndex, tableMeta, updateValue) => {
          return (dataIndex? dataIndex.name: "No seleccionado");
        }
      }
    },
    { name: "year", label: "Año", options: { sortable: true, filter: true } },
    {
      name: "versionId",
      label: "Versión",
      options: { sortable: true, filter: true, display: false },
    },
    {
      name: "fuelType",
      label: "Combustible",
      options: { sortable: true, filter: true },
    },
    {
      name: "doors",
      label: "Puertas",
      options: { sortable: true, filter: true, display: false },
    },
    {
      name: "condtion",
      label: "Condición (nuevo/usado)",
      options: { sortable: true, filter: true, display: false },
    },
    {
      name: "mileage",
      label: "KM",
      options: { sortable: true, filter: true, display: false },
    },
    {
      name: "color",
      label: "Color",
      options: { sortable: true, filter: true, display: false },
    },
    {
      name: "address",
      label: "Dirección",
      options: { sortable: true, filter: true, display: false },
    },
    {
      name: "transmissionType",
      label: "Transmisión",
      options: { sortable: true, filter: true, display: false },
    },
    {
      name: "controlTraccion",
      label: "Control de tracción",
      options: { sortable: true, filter: true, display: false },
    },
    {
      name: "engine",
      label: "Motor (ej. 2.0)",
      options: { sortable: true, filter: true, display: false },
    },
    {
      name: "carType",
      label: "Tipo de carrocería (ej. Sedan)",
      options: { sortable: true, filter: true, display: false },
    },
    {
      name: "sellerType",
      label: "Tipo de vendedor",
      options: { sortable: true, filter: true, display: false },
    },
    {
      name: "observations",
      label: "Observaciones",
      options: { sortable: true, filter: true, display: false },
    },
    {
      name: "sellingPrice",
      label: "Precio de venta",
      options: { sortable: true, filter: false,
        customBodyRender: (value, dataIndex, tableMeta, updateValue) => {
          return (
            <NumberFormat
              value={value}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
              fixedDecimalScale={true}
              decimalScale={"2"}
            />
            )
        }
      }
    },
    {
      name: "purchasedPrice",
      label: "Precio de compra",
      options: { sortable: true, filter: false,
        customBodyRender: (value, dataIndex, tableMeta, updateValue) => {
          return (
            <NumberFormat
              value={value}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
              fixedDecimalScale={true}
              decimalScale={"2"}
            />
            )
        }
      }
    },
    {
      name: "carAvgPrice.daysToSell",
      label: "Días hasta la venta",
      options: { sortable: true, filter: false, display: true,
      customBodyRender: (value, dataIndex, tableMeta, updateValue) => {
      return (
        value || "No hay datos"
      )
      }
      },
    },
    {
      name: "firstOwner",
      label: "Único dueño",
      options: {
        sortable: true,
        filter: true,
        display: false,
        customBodyRender: value => {
          return value ? "Si" : "No";
        },
      },
    },
    {
      name: "auction",
      label: "Operaciones",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (dataIndex, tableMeta, updateValue) => {
    
          let now = new Date().getTime();
 
          const isExpired = tableMeta.rowData[21]?.endedAt <= Date.now() ? true : false;
     
          return (
            <ButtonGroup size="small" aria-label="small outlined button group">
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                component={RouterLink} 
                to={`/app/vehicles/edit/${tableMeta.rowData[0]}`}
              >
                Editar
              </Button>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={() => handleMarkAsSoldDialogOpen(tableMeta.rowData[0])}
              >
                Marcar Vendido
              </Button>
              {dataIndex && !isExpired 
                ? <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => handleSendToAuctionDialogOpen(dataIndex, false)}
                >
                  Editar subasta
                </Button>
                : <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={() => handleSendToAuctionDialogOpen(tableMeta.rowData, true)}
                >
                  SUBASTAR
                </Button>}
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                disabled={tableMeta.rowData[21] && !isExpired}
                onClick={() => handleDeleteDialogOpen(tableMeta.rowData[0])}
              >
                Borrar
              </Button>
            </ButtonGroup>
          );
        },
      },
    },
  ];


  if (loading) {
    return <p>Cargando...</p>;
  }
  if (error) {
    return <p>Error</p>;
  }
  return (
    <>
      <ConfirmDialog
        isOpen={isDeleteConfirmOpen}
        handleClose={handleDeleteDialogClose}
      />
      <MarkAsSoldDialog
        id={id}
        isOpen={isOpenMarkAsSold}
        handleClose={handleMarkAsSoldDialogClose}
      />
      {isNewSendingToAuction
        ? <SendToAuctionDialog
          data={auctionData}
          handleClose={handleSendToAuctionDialogClose}
        />
        : <UpdateAuctionDetailDialog
          data={auctionData}
          handleClose={handleSendToAuctionDialogClose}
        />}
      <PageTitle title="Vehículos en stock" />
      <Grid item xs={12}>
      <Typography variant='subtitle1'>
        Listado de vehículos disponibles para la venta
      </Typography>
      </Grid>
      <Grid container spacing={4} style={{"paddingTop": "20px"}}>
        <Grid item xs={12}>
          <Widget title="Mis vehículos" upperTitle noBodyPadding>
            <ThemeProvider theme={theme}>
              <MUIDataTable
                columns={columns}
                data={data.cars.nodes}
                options={{
                  selectableRows: "none",
                  textLabels: translation['textLabels']
                }}
              />
            </ThemeProvider>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
