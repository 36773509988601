import React, { useEffect, useState } from "react";
import gql from "graphql-tag";
import { useApolloClient, useMutation, useQuery, useSubscription } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Alert } from "@material-ui/lab";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import { Close } from "@material-ui/icons";
import { Typography } from "@material-ui/core";
import { LatestAuction } from "../AuctionSubscription/AucitonSubscription";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  formControl: {
    margin: "0.5rem 0 !important",
    width: "20rem",
  },
}));
const BID_TO_AUCTION = gql`
    mutation BidToAuction($input: BidToAuctionInput!) {
        bidToAuction(input: $input)
    }
`;
 


const AUCTION = gql`
  query auction($auctionId: ID!) {
    auction(auctionId: $auctionId) {
      nodes {
          carDetail {
              id
              userId
              brand {
                  name
              }
              carType
              title
              year
              transmissionType
              fuelType
              observations
              doors
              sellingPrice
              photos {
                  id
                  file
                  url
                  position
              }
          }
          auctionDetail {
              id
              carId
              initialPrice
              incrementUnit
              currentPrice
              observations
              endedAt
              sentByMe
              biddenByMe
              bids {
                  id
                  bidPrice
              }
                
            }
        }
    }
  }
`;

const AUCTIONS_SUBSCRIPTION = gql`
subscription OnAuctionAdded($auctionId: String!) {
  newBidAuction(auctionId: $auctionId){
    id
    bidPrice
    auctionId
  }
}
`;


export const BidDialog = ({ handleClose, data   }) => {
  const classes = useStyles();
  const client = useApolloClient();
   const {
    _,
    __,
    data:auction,
    refetch
    // refetch:auctionRefetc
  } = useQuery(AUCTION, {
    variables: {
      auctionId:data.id
    },
  });
  const { data:subscribeData } = useSubscription(
    AUCTIONS_SUBSCRIPTION,
    { variables: { auctionId:data.id.toString() } }
  );

  const [bidToAuction] = useMutation(BID_TO_AUCTION);
  const [newPrice, setNewPrice] = React.useState(data?.currentPrice);
  const [error, setError] = React.useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    const minBidPrice = auction?.auction.nodes[0].auctionDetail.currentPrice + auction?.auction.nodes[0].auctionDetail.incrementUnit;
    if (Number(newPrice) < minBidPrice) {
      setError(`You can't bid with less than the price $${minBidPrice}`);
      return;
    }
    bidToAuction({
      variables: {
        input: {
          id: data.id,
          bidPrice: +newPrice,
        },
      },
    }).then(() => handleClose()).catch(error => setError(error.message));
  };
  const handleChange = e => {
    setNewPrice(e.target.value);
  };
   useEffect(()=>{
    // console.log(auction?.auction.nodes[0].auctionDetail.currentPrice)
    // console.log(subscribeData?.newBidAuction)
    if(subscribeData && subscribeData?.newBidAuction){
      refetch();
    }
  },[auction, client, refetch, subscribeData])

  React.useEffect(() => {
    setNewPrice( auction?.auction.nodes[0].auctionDetail.currentPrice || 0);
  }, [auction]);



  if(_){
    return <>Loading...</>;
  }

  if(__){
    return __;
  }

  return (
    <Dialog onEntered={() => setError("")} open={!!data} onClose={handleClose} aria-labelledby="form-dialog-title">
      {
        auction &&
          <form className={classes.form} onSubmit={onSubmit}>
          <DialogTitle id="form-dialog-title">Oferta</DialogTitle>
          <DialogContent>
            <Collapse in={!!error}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="secondary"
                    size="small"
                    onClick={() => {
                      setError("");
                    }}>
                    <Close fontSize="inherit"/>
                  </IconButton>
                }
              >
                {error}
              </Alert>
            </Collapse>
            <Typography>Precion actual {auction?.auction.nodes[0].auctionDetail.currentPrice}</Typography>
              
            <Typography>Min increment unit is ${auction?.auction.nodes[0].auctionDetail.incrementUnit}</Typography>
            <TextField
              className={classes.formControl}
              name="price"
              label="precio de oferta"
              value={newPrice}
              type="number"
              step="0.01"
              onChange={handleChange}
              variant="outlined"/>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">Cancelar</Button>
            <Button color="primary" type="submit">OFERTAR</Button>
          </DialogActions> 
        </form>
      }
    </Dialog>
  );
};
