import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  ButtonGroup,
  createMuiTheme,
  ThemeProvider,
  Typography,
  makeStyles
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import MUIDataTable from "mui-datatables";

// components
import Widget from "../../components/Widget/Widget";
import { useApolloClient, useQuery, useSubscription } from "@apollo/client";
import gql from "graphql-tag";
import { BidDialog } from "./components/BidDialog/BidDialog";
import { LatestAuction } from "./components/AuctionSubscription/AucitonSubscription";
import * as moment from 'moment'
import PageTitle from "../../components/PageTitle/PageTitle";
import translation from "../translations/translations_muidatatable";

const theme = createMuiTheme({
  overrides: {
    MuiTable: {
      root: {
        border: [[1, "solid", "#d3d3d3"]],
        marginBottom:"3rem"
      },
    },
    MuiTableCell: {
      root: {
        borderColor: "#d3d3d3",
      },
      head: {
        background: "lightgrey",
        fontWeight:"bold"
      },
    },
    MuiTableSortLabel: {
      root: {
        alignItems: "flex-start",
      },
    },
    MuiTableFooter: {
      root: {
        background: "lightgrey",
      },
    },
  },
});

const useStyles = makeStyles(theme => ({
  action: {
    minWidth: "163px",
    alignContent:"center"
  },
  margin: {
    position:"fixed",
    right:'10%',
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  title:{
    textAlign:"center",
    marginBottom:"1rem"
  },
  subtitle:{
    marginBottom:"1rem"
  },
}));

const GET_AUCTIONS = gql`
    query getAuctions {
        auctions {
            nodes {
                carDetail {
                    id
                    userId
                    brand {
                        name
                    }
                    carType
                    title
                    year
                    transmissionType
                    fuelType
                    observations
                    doors
                    sellingPrice
                    photos {
                        id
                        file
                        url
                        position
                    }
                }
                auctionDetail {
                    id
                    carId
                    initialPrice
                    incrementUnit
                    currentPrice
                    observations
                    endedAt
                    sentByMe
                    biddenByMe
                    bids {
                        id
                        bidPrice
                    }
                }
            }
        }
    }
`;


const NEW_AUCTION  = gql`
  subscription newAuction {
    newAuction {
      id
    }
  }
`;

export default function AuctionTable() {
  const client = useApolloClient();
  const { loading, error, data, refetch } = useQuery(GET_AUCTIONS);
  const [ auctionData, setAuctionData ] = React.useState(false);

  const { data: auctionDataSubscription} = useSubscription(
    NEW_AUCTION
  )

  useEffect(() => {
    if (data) {
      client.clearStore();
      refetch();
    }
  }, [client, data, refetch]);

  const handleOpenBidDialog = (data) => {
    setAuctionData(data);
  };
  const handleCloseBidDialog = () => {
    setAuctionData(false);
    refetch();
  };

const columns = [
    {
      name: "id",
      label: "ID",
      options: { sortable: false, filter: false, display: false },
    },
    {
      name: "carDetail", label: "Modelo", options: {
        sortable: true,
        filter: true,
        customBodyRender: carDetail => carDetail.brand.name,
      },
    },
    {
      name: "carDetail", label: "Tipo de carro", options: {
        sortable: true,
        filter: true,
        customBodyRender: carDetail => carDetail.carType,
      },
    },
    {
      name: "carDetail", label: "Título", options: {
        sortable: false,
        filter: false,
        display: false,
        customBodyRender: carDetail => carDetail.title,
      },
    },
    {
      name: "carDetail", label: "Año", options: {
        sortable: true,
        filter: true,
        customBodyRender: carDetail => carDetail.year,
      },
    },
    {
      name: "carDetail", label: "Transmisión", options: {
        sortable: true,
        filter: true,
        customBodyRender: carDetail => carDetail.transmissionType,
      },
    },
    {
      name: "carDetail", label: "Combustible", options: {
        sortable: true,
        filter: true,
        customBodyRender: carDetail => carDetail.fuelType,
      },
    },
    {
      name: "auctionDetail", label: "Observaciones", options: {
        sortable: true,
        filter: true,
        customBodyRender: auctionDetail => auctionDetail.observations,
      },
    },
    {
      name: "carDetail", label: "Puertas", options: {
        sortable: true,
        filter: true,
        customBodyRender: carDetail => carDetail.doors,
      },
    },
    {
      name: "auctionDetail", label: "Precio actual de subasta", options: {
        sortable: true,
        filter: true,
        customBodyRender: auctionDetail => <LatestAuction auctionData={auctionDetail} />
      }
    },
    {
      name: "auctionDetail", label: "Finalización de subasta", options: {
        sortable: true,
        filter: true,
        customBodyRender: auctionDetail => moment(new Date(auctionDetail.endedAt)).format('DD-MM-YYYY')
      },
    },
    {
      name: "auctionDetail",
      label: "Subasta",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (auctionDetail, tableMeta, updateValue) => {

          const isExpired = new Date(auctionDetail.endedAt).getTime() < Date.now();
        
          return (
            <ButtonGroup size="small" aria-label="small outlined button group">
              <Button
                size="small"
                variant="outlined"
                color="secondary"
                component={RouterLink}
                to={`/app/vehicles/detailAuction/${auctionDetail.carId}`}
              >
                Más detalles
              </Button>
              <Button
                size="small"
                variant="outlined"
                color="primary"
                disabled={auctionDetail.sentByMe || isExpired}
                onClick={() => handleOpenBidDialog(auctionDetail)}
              >
                Ofertar
              </Button>
            </ButtonGroup>
          );
        },
      },
    },
  ];
  
  useEffect(()=>{
    if(auctionDataSubscription && auctionDataSubscription.newAuction ){
      client.clearStore().then((data)=>{
       refetch(); 
      })
    }
  },[client, refetch, auctionDataSubscription])


  if (loading) {
    return <p>Cargando subastas...</p>;
  }
  if (error) {
    console.log(error)
    return <p>Error</p>;
  }

  return (
    <>
      {
        auctionData ?
        <BidDialog 
          data={auctionData} 
          handleClose={handleCloseBidDialog}
          refetch={refetch}
        />
        :  <></>
      }
      <PageTitle title="Vehículos en subasta" />
      <Grid item xs={12}>
        <Typography variant='subtitle1'>
        {"Listado de subastas activas"}
        </Typography>
      </Grid>
      <Grid container spacing={4} style={{"paddingTop": "20px"}}>
        <Grid item xs={12}>
          <Widget title="Vehículos subastado" upperTitle noBodyPadding>
            <ThemeProvider theme={theme}>
              <MUIDataTable
                columns={columns}
                data={data.auctions.nodes}
                options={{
                  filterType: "checkbox",
                  textLabels: translation['textLabels']
                }}
              />
            </ThemeProvider>
          </Widget>
        </Grid>
      </Grid>
    </>
  );
}
