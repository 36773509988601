import React, { useState } from "react";
import Widget from "../../../../components/Widget";
import useStyles from "../../../dashboard/styles";
import { gql, useQuery } from '@apollo/client';
import { getCarsOffersQuery  } from "../../components/queries/reports";
import {
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell,
  } from "@material-ui/core";
import { Button } from "../../../../components/Wrappers/Wrappers";

export default function DisplayTableCarOffers(props) {
    var classes = useStyles();
    const { loading, error, data } = useQuery(getCarsOffersQuery,
        {
            fetchPolicy: "cache-and-network",
            variables: {
                filter: JSON.stringify({"Marca":[],"currency_code":["ARS"],"Año":{"gte":"2000"},"Version":[],"Kilometraje":{"gte":"500"},"Transmisión":[],"Combustible":[],"Precio":{"gte":"100000"},"Precio Promedio":[],"Ganancia":[],"Ganancia Porcentual":[],"Fecha publicación":[],"Teléfono":[],"Operación":[],"Provincia":[],"Ciudad":[],"Fecha de publicación":{},"Financiación":["No"]}),
                rowsPerPage: 10,
                orderColName: "profitPercentage",
                displayHomePage: true
            }
        }
    );
    if (loading) return 'Cargando ofertas de la semana...';
    if (error) return `Error! ${error.message}`;
    const columns = [
        'Marca',
        'Modelo',
        'Año',
        'Kilometraje',
        'Moneda',
        'Precio',
        'Ganancia',
        'Enlace'
    ];
        return(
            <Widget
                    title={props.title || ""}
                    upperTitle
                    noBodyPadding
                    bodyClass={classes.tableWidget}
                >
                    <Table className="mb-0">
                        <TableHead>
                            <TableRow>
                            {columns.map(key => (
                                <TableCell key={key}>{key}</TableCell>
                            ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.getCarsOffers.rows.map(({ id, brand, brand_model, mileage, link, price, profitPercentage, year, currency_code }) => (
                                <TableRow key={id}>
                                    <TableCell className="pl-3 fw-normal">{brand.name}</TableCell>
                                    <TableCell>{brand_model.name}</TableCell>
                                    <TableCell>{year}</TableCell>
                                    <TableCell>{mileage}</TableCell>
                                    <TableCell>{currency_code}</TableCell>
                                    <TableCell>${price}</TableCell>
                                    <TableCell>{profitPercentage}%</TableCell>
                                    <TableCell>
                                        <Button
                                            color="success"
                                            size="small"
                                            className="px-2"
                                            variant="contained"
                                            onClick={()=> window.open(link, '_blank')}
                                        >
                                            Ver
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
            </Widget>
        );
}