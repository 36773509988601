import React from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useForm } from "react-hook-form";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { useToastDispatch } from "../../../../context/ToastContext";
import { AuctionModal } from "../../../../components/AuctionModal";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  formContent: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  formControl: {
    margin: "0.5rem 0 !important",
    width: "20rem",
  },
}));

const SEND_TO_AUCTION = gql`
    mutation SendToAuction($input: SendToAuctionInput!) {
        sendToAuction(input: $input){
            id
            initialPrice
            incrementUnit
            observations
            endedAt
        }
    }
`;



export const SendToAuctionDialog = ({ data: carDetail, handleClose }) => {
  const classes = useStyles();
  const {register, handleSubmit, watch, errors } = useForm();
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const toastDispatch = useToastDispatch();
  
  const [confirmConditions , setConfirmConditions ] = React.useState();
  const [isOpen , setIsOpen ]= React.useState(true);


  const [sendToAuction] = useMutation(SEND_TO_AUCTION);

  const onSubmit = data => {
    if(selectedDate.getDate() !== new Date().getDate()){
       sendToAuction({
        variables: {
          input: {
            carId: carDetail[0],
            initialPrice: parseFloat(data.initialPrice),
            incrementUnit: parseFloat(data.incrementUnit),
            observations: data.observations,
            endedAt: selectedDate.getTime(),
          },
        },
      }).then(() => {
        toastDispatch({
          isOpen: true,
          type: "success",
          message: "Subasta creada exitosamente!",
        })
        handleClose()
      });
    }
    else{
      toastDispatch({
        isOpen: true,
        type: "error",
        message: "Tienen que pasar al menos 24 hs!",
      })
    }
   
  };

  const handleConfirmConditions =confirm=>{
    if(confirm){
      setConfirmConditions(true)
    }else{
      handleClose()
      setIsOpen(false);
    }
    
  }
  return (
    <>
      {
        confirmConditions ?
        <Dialog open={!!carDetail} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle id="form-dialog-title">INICIAR SUBASTA</DialogTitle>
          <DialogContent className={classes.formContent}>
            <TextField
              className={classes.formControl}
              name="initialPrice"
              label="Precio inicial de la subasta($)"
              variant="outlined"
              step="0.01"
              type="number"
              inputRef={register({required:true})}/>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                style={{ width: "100%" }}
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                name="endDate"
                autoOk={true}
                label="Fecha de finalización"
                value={selectedDate}
                onChange={setSelectedDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              
            </MuiPickersUtilsProvider>
            <TextField
              className={classes.formControl}
              name="incrementUnit"
              label="Incremento mínimo($)"
              variant="outlined"
              step="0.01"
              type="number"
              inputRef={register({ required: true })}/>
            <TextField
              className={classes.formControl}
              name="observations"
              label="Observaciones"
              variant="outlined"
              inputRef={register}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">Cancelar</Button>
            <Button color="primary" type="submit">INICIAR</Button>
          </DialogActions>
        </form>
      </Dialog>
      
      :

      <AuctionModal
        isOpen={!!carDetail}
        handleClose={handleConfirmConditions}
      />
      }
    </>
  );
};
