import React from "react";
import {
  TextField,
  FormControl,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  Switch,
  Button,
  FormHelperText,
  CircularProgress,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  formControl: {
    margin: "5px 5px",
    flex: "1 1 30%",
    textAlign: "left",
  },
  buttonWrap: {
    flex: "1 1 100%",
    display: "flex",
    alignItems: "flex-end",
  },
  submitButton: {
    marginTop: "1rem",
  },
  loading: {
    marginLeft: "1rem",
  },
}));

export const UserForm = ({ data, accounts, submitText, onSubmit, loading }) => {
  const classes = useStyles();
  const { register, handleSubmit, watch, errors, control } = useForm();

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
      <TextField
        className={classes.formControl}
        defaultValue={data.name}
        error={errors.name ? true : false}
        helperText={errors.name ? errors.name.message : ""}
        InputLabelProps={{ required: true }}
        inputRef={register({ required: "name is required" })}
        label="Nombre y apellido"
        name="name"
        variant="outlined"
      />
      <TextField
        autoComplete={"new password"}
        className={classes.formControl}
        error={errors.password ? true : false}
        helperText={errors.password ? errors.password.message : ""}
        inputRef={register}
        label="Contraseña"
        name="password"
        type="password"
        variant="outlined"
      />
      <TextField
        autoComplete={"new password"}
        className={classes.formControl}
        error={errors.retypePassword ? true : false}
        helperText={errors.retypePassword ? errors.retypePassword.message : ""}
        inputRef={register({
          validate: input =>
            input === watch("password", "") || "The passwords do not match",
        })}
        label="Repetir contraseña"
        name="retypePassword"
        variant="outlined"
        type="password"
      />
      <TextField
        className={classes.formControl}
        defaultValue={data.phone}
        error={errors.phone ? true : false}
        helperText={errors.phone ? errors.phone.message : ""}
        InputLabelProps={{ required: true }}
        inputRef={register({ required: "phone is required" })}
        label="Teléfono"
        name="phone"
        variant="outlined"
      />
      <TextField
        className={classes.formControl}
        defaultValue={data.email}
        error={errors.email ? true : false}
        helperText={errors.email ? errors.email.message : ""}
        InputLabelProps={{ required: true }}
        inputRef={register({
          required: "email is required",
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "invalid email address",
          },
        })}
        label="Email"
        name="email"
        variant="outlined"
      />
      <FormControl variant="outlined" required className={classes.formControl}>
        <InputLabel>Role</InputLabel>
        <Controller
          as={
            <Select>
              <MenuItem value="normal">Normal</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
            </Select>
          }
          defaultValue={data.role || ""}
          rules={{ required: "role is required" }}
          control={control}
          label="Role"
          name="role"
        />
      </FormControl>

      <FormControl
        hiddenLabel
        variant="outlined"
        className={classes.formControl}
      >
        <InputLabel>Account</InputLabel>
        <Controller
          as={
            <Select>
              {accounts.map(account => (
                <MenuItem key={account.id} value={account.id}>
                  {account.name}
                </MenuItem>
              ))}
            </Select>
          }
          control={control}
          defaultValue={data.account?.id || ""}
          label="Account"
          name="accountId"
        />
        <FormHelperText error={true}>
          {errors.account && errors.account.message}
        </FormHelperText>
      </FormControl>
      <TextField
        className={classes.formControl}
        defaultValue={data.comments}
        inputRef={register}
        label="Comentarios"
        multiline
        name="comments"
        rows={3}
        variant="outlined"
      />
      <FormControlLabel
        className={classes.formControl}
        control={
          <Switch
            defaultChecked={data.active}
            color="primary"
            name="active"
            inputRef={register}
          />
        }
        label="Active"
      />
      <TextField
        className={classes.formControl}
        defaultValue={data.setup}
        inputRef={register}
        label="Setup"
        multiline
        name="setup"
        rows={3}
        variant="outlined"
      />
      <div className={classes.buttonWrap}>
        <Button
          disabled={loading}
          className={classes.submitButton}
          variant="contained"
          color="primary"
          type="submit"
        >
          {submitText}
        </Button>
        {loading && <CircularProgress size={35} className={classes.loading} />}
      </div>
    </form>
  );
};
