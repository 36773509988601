import React from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useForm } from "react-hook-form";

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  formControl: {
    margin: "0.5rem 0 !important",
    width: "20rem",
  },
}));

export const PhoneNumberDialog = ({ isOpen, handleClose, id }) => {
  const classes = useStyles();
  const { register, handleSubmit, errors, control } = useForm();
  const onSubmit = data => {
    handleClose(data.phone);
  };
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <form
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <DialogTitle id="form-dialog-title">Phone Number</DialogTitle>
        <DialogContent>
          <TextField
            className={classes.formControl}
            name="phone"
            id="outlined-basic"
            label="Phone number"
            variant="outlined"
            error={errors.phone ? true : false}
            helperText={errors.phone ? errors.phone.message : ""}
            InputLabelProps={{ required: true }}
            inputRef={register({ required: "Phone is required" })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Login
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
