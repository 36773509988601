import React from "react";

import {
  FormLabel,
  FormGroup,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";

export const RangeFilter = ({ filterList, onChange, index, column }) => (
  <div>
    <FormLabel>{column.name}</FormLabel>
    <FormGroup row>
      <TextField
        label="min"
        value={filterList[index][0] || ""}
        onChange={event => {
          filterList[index][0] = event.target.value;
          onChange(filterList[index], index, column);
        }}
        style={{ width: "45%", marginRight: "5%" }}
      />
      <TextField
        label="max"
        value={filterList[index][1] || ""}
        onChange={event => {
          filterList[index][1] = event.target.value;
          onChange(filterList[index], index, column);
        }}
        style={{ width: "45%" }}
      />
    </FormGroup>
  </div>
);
