import React from "react";
import { TextField, Button, CircularProgress } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: "5px 5px",
    flex: "1 1 30%",
    textAlign: "left",
  },
  buttonWrap: {
    flex: "1 1 100%",
    display: "flex",
    alignItems: "flex-end",
  },
  submitButton: {
    marginTop: "1rem",
  },
  loading: {
    marginLeft: "1rem",
  },
}));

export const MessageForm = ({ data, submitText, onSubmit, loading }) => {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <TextField
        className={classes.formControl}
        defaultValue={data.title}
        error={errors.title ? true : false}
        helperText={errors.title ? errors.title.message : ""}
        InputLabelProps={{ required: true }}
        inputRef={register({ required: "El título es requerido" })}
        label="Title"
        name="title"
        variant="outlined"
      />
      <TextField
        className={classes.formControl}
        defaultValue={data.body}
        error={errors.body ? true : false}
        helperText={errors.body ? errors.body.message : ""}
        InputLabelProps={{ required: true }}
        inputRef={register({ required: "El texto principal es requerido" })}
        label="Body"
        name="body"
        variant="outlined"
      />
      <div className={classes.buttonWrap}>
        <Button
          className={classes.submitButton}
          disabled={loading}
          variant="contained"
          color="primary"
          type="submit"
        >
          {submitText}
        </Button>
        {loading && <CircularProgress size={35} className={classes.loading} />}
      </div>
    </form>
  );
};
