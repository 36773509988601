import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core";
import { gql, useQuery, useMutation, useApolloClient } from "@apollo/client";
import { SalesData, VehicleData, VehiclePhotos } from "./components";
import { useToastDispatch } from "../../context/ToastContext";
import { useHistory, useParams } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "1200px",
    margin: "0 auto",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formWrap: {
    textAlign: "center",
  },
}));
const GET_CAR = gql`
  query getCar($id: ID!) {
    car(id: $id) {
      id
      brandId
      carModelId
      year
      versionId
      fuelType
      doors
      condtion
      mileage
      color
      address
      state
      transmissionType
      controlTraccion
      engine
      carType
      firstOwner
      sellerType
      observations
      sellingPrice
      purchasedPrice
      shareable
      photos {
        id
        url
      }
    }
  }
`;

const UPDATE_CAR = gql`
  mutation updateCar($input: UpdateCarInput!) {
    updateCar(input: $input) {
      id
      brandId
      carModelId
      year
      versionId
      fuelType
      doors
      condtion
      mileage
      color
      address
      state
      transmissionType
      controlTraccion
      engine
      carType
      firstOwner
      sellerType
      observations
      sellingPrice
      purchasedPrice
    }
  }
`;

function getSteps() {
  return ["Datos del vehículo", "Datos de venta", "Fotos del vehículo"];
}

export function VehicleEdit(props) {
  const { id } = useParams();
  const [formState, setFormState] = useState({
    brandId: "",
    carModelId: "",
    year: "",
    versionId: "",
    fuelType: "",
    doors: "",
    condtion: "",
    mileage: "",
    color: "",
    address: "",
    transmissionType: "",
    controlTraccion: "",
    engine: "",
    carType: "",
    firstOwner: false,
    sellerType: "",
    observations: "",
    sellingPrice: "",
    purchasedPrice: "",
    photos: "",
    shareable: false,
    state: "",
  });
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const [updateCar, { loading: mutationLoading, error }] = useMutation(
    UPDATE_CAR,
  );
  const { data: carData, loading: queryLoading, error: queryError } = useQuery(
    GET_CAR, {
      variables: {
        id
      }
    }
  );
  const toastDispatch = useToastDispatch();
  const client = useApolloClient();
  const history = useHistory();

  if (queryLoading) {
    return <div>Cargando...</div>;
  }
  if (queryError) {
    return <div>{queryError.message}</div>;
  }
  if(carData && carData.car && !formState.id) {
    setFormState(carData.car);
  }
  const handleSubmit = () => {
    updateCar({
      variables: {
        input: {
          id: parseInt(id),
          brandId: parseInt(formState.brandId),
          carModelId: parseInt(formState.carModelId),
          year: parseInt(formState.year),
          versionId: parseInt(formState.versionId),
          fuelType: formState.fuelType,
          doors: formState.doors,
          condtion: formState.condtion,
          mileage: parseInt(formState.mileage),
          color: formState.color,
          address: formState.address,
          transmissionType: formState.transmissionType,
          controlTraccion: formState.controlTraccion,
          engine: formState.engine,
          carType: formState.carType,
          firstOwner: formState.firstOwner,
          sellerType: formState.sellerType,
          observations: formState.observations,
          sellingPrice: parseFloat(formState.sellingPrice),
          purchasedPrice: parseFloat(formState.purchasedPrice),
          photos: formState.photos,
          shareable: formState.shareable,
          state: formState.state,
        },
      },
    }).then(() => {
      client.clearStore();
      toastDispatch({
        isOpen: true,
        type: "success",
        message: "El vehículo fue actualizado",
      });
      setTimeout(() => history.push("/app/vehicles"));
    });
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  function handleStepperInput(input) {
    setFormState({ ...formState, ...input });
    setActiveStep(prevActiveStep => {
      if (prevActiveStep < 2) {
        return prevActiveStep + 1;
      } else {
        return prevActiveStep;
      }
    });
  }
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <VehicleData data={formState} pushInput={handleStepperInput} />;
      case 1:
        return (
          <SalesData
            data={formState}
            pushInput={handleStepperInput}
            handleBack={handleBack}
          />
        );
      case 2:
        return (
          <VehiclePhotos
            data={formState}
            pushInput={handleStepperInput}
            handleBack={handleBack}
            loading={mutationLoading}
            handleSubmit={handleSubmit}
          />
        );
      default:
        return "Paso desconocido";
    }
  }
  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              {error ? error.message : "Vehículo creado satisfactoriamente"}
            </Typography>
          </div>
        ) : (
          <Card>
            <CardContent className={classes.formWrap}>
              {getStepContent(activeStep)}
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  );
}
