import * as React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  counter: {
    flex: 0.8,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "center",
    backgroundColor: "#333",
  },
}));
export default function TimeCounter(props) {
  const classes = useStyles();
  const [endedAt, setEndedAt] = React.useState(new Date(props.endedAt));
  const [days, setDays] = React.useState(0);
  const [hours, setHours] = React.useState(0);
  const [minutes, setMinutes] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);
  React.useEffect(() => {
    setEndedAt(new Date(props.endedAt));
  }, [props]);
  const timer = setInterval(() => {
    if (!endedAt || !endedAt.getTime()) {
      return;
    }
    const timestamp = endedAt.getTime() - Date.now();
    if (timestamp < 0) {
      return;
    }
    const timestampInSeconds = timestamp / 1000;
    setDays(Math.floor(timestampInSeconds / (60 * 60 * 24)));
    setHours(Math.floor(timestampInSeconds % (60 * 60 * 24) / (60 * 60)));
    setMinutes(Math.floor(timestampInSeconds % (60 * 60) / (60)));
    setSeconds(Math.floor(timestampInSeconds % 60));
  }, 1000);
  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={5}
        direction="row"
        justify="space-around"
        alignItems="center">
        <Grid item xs={2}/>
        <Grid item xs={2} style={{ fontSize: "1.5rem", whiteSpace: "nowrap" }}>
          Finaliza en
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}>
            <div style={{ color: "#0fc", fontSize: "2rem" }}>{days}</div>
            <div style={{ color: "#fff", fontSize: "1.2rem" }}>Days</div>
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}>
            <div style={{ color: "#0fc", fontSize: "2rem" }}>{hours}</div>
            <div style={{ color: "#fff", fontSize: "1.2rem" }}>Hours</div>
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}>
            <div style={{ color: "#0fc", fontSize: "2rem" }}>{minutes}</div>
            <div style={{ color: "#fff", fontSize: "1.2rem" }}>Mins</div>
          </Paper>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paper}>
            <div style={{ color: "#0fc", fontSize: "2rem" }}>{seconds}</div>
            <div style={{ color: "#fff", fontSize: "1.2rem" }}>Secs</div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
