import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Breadcrumbs,
  Link,
} from "@material-ui/core";
import { Link as RouterLink, useParams, useHistory } from "react-router-dom";
import { gql, useMutation, useQuery, useApolloClient } from "@apollo/client";
import { CustomerForm } from "../components";
import { useToastDispatch } from "../../../context/ToastContext";

const GET_CUSTOMER = gql`
  query getCustomer($id: ID!) {
    customer(id: $id) {
      id
      name
      phone
      email
      preferredCarType
      preferredYear
      preferredPriceRange
      birthday
      comments
    }
  }
`;

const EDIT_CUSTOMER = gql`
  mutation updateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      id
    }
  }
`;

export const CustomerEdit = () => {
  const history = useHistory();
  const client = useApolloClient();
  const { id } = useParams();
  const toastDispatch = useToastDispatch();

  const {
    loading: loadingCustomer,
    error: errorLoadingCustomer,
    data: customer,
  } = useQuery(GET_CUSTOMER, {
    variables: {
      id,
    },
  });


  const [updateCustomer, { loading: mutationLoading }] = useMutation(EDIT_CUSTOMER);

  if (loadingCustomer) {
    return <p>Loading...</p>;
  }
  if (errorLoadingCustomer) {
    return <p>Error</p>;
  }
  const handleSubmit = customer => {
    const input = {
      id: id,
      name: customer.name,
      phone: customer.phone,
      email: customer.email,
      preferredCarType: customer.preferredCarType,
      preferredYear: customer.preferredYear,
      preferredPriceRange: customer.preferredPriceRange,
      comments: customer.comments,
      birthday: new Date(customer.birthday),
    };
    updateCustomer({ variables: { input } }).then(async () => {
      await client.clearStore();
      toastDispatch({
        isOpen: true,
        type: "success",
        message: "Customer is successfully edited",
      });
      history.push("/app/customers");
    });
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={8}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" component={RouterLink} to="/app/customers">
            Customer
          </Link>
          <Link
            color="textPrimary"
            component={RouterLink}
            to={`/app/customers/edit/${id}`}
            aria-current="page"
          >
            Editar un cliente
          </Link>
        </Breadcrumbs>
        <Card>
          <CardHeader title="Editar un cliente" />
          <CardContent>
            {customer.customer ? (
              <CustomerForm
                data={customer.customer}
                loading={mutationLoading}
                submitText="Editar"
                onSubmit={handleSubmit}
              />
            ) : (
              <p>No eixsten clientes ingresados en el sistema</p>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
