import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  createMuiTheme,
  Grid,
  IconButton,
  ThemeProvider,
  Tooltip,
  makeStyles,
} from "@material-ui/core";

import MUIDataTable from "mui-datatables";
import AddIcon from "@material-ui/icons/Add";

// components
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { useToastDispatch } from "../../../context/ToastContext";
import { useUserState } from "../../../context/UserContext";
import translation from "../../translations/translations_muidatatable";

const theme = createMuiTheme({
  overrides: {
    MuiTable: {
      root: {
        border: [[1, "solid", "#d3d3d3"]],
      },
    },
    MuiTableCell: {
      root: {
        borderColor: "#d3d3d3",
      },
      head: {
        background: "lightgrey",
      },
    },
    MuiTableSortLabel: {
      root: {
        alignItems: "flex-start",
      },
    },
    MuiTableFooter: {
      root: {
        background: "lightgrey",
      },
    },
  },
});
const useStyles = makeStyles(theme => ({
  action: {
    minWidth: "163px",
  },
}));

const GET_ACCOUNTS_LIST = gql`
  query getMessages {
    messages {
      nodes {
        id
        title
        body
     
      }
    }
  }
`;

const GET_NOTIFICATION_AUCTION_ENDED_LIST = gql`
 query allMessagesEndedAuction($destinataryId:ID!){
  allMessagesEndedAuction(destinataryId:$destinataryId){
    id
    title
    body
    auctionId
    result 
    destinataryId
  }
 }
`;


const DELETE_ACCOUNT = gql`
  mutation DeleteMessage($input: DeleteMessageInput) {
    deleteMessage(input: $input)
  }
`;

export function MessageTable() {
  const classes = useStyles();
  const [deleteMessage] = useMutation(DELETE_ACCOUNT);
  const { loading, error, data, refetch } = useQuery(GET_ACCOUNTS_LIST);

  const [id, setId] = useState(null);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const toastDispatch = useToastDispatch();
  const user = useUserState();
 const { loading:loadingAuctionEnded, 
  error:errorAuctionEnded, 
  data:dataAuctionEnded, 
  // refetch:refetchAuctionEnded 
} 
 = useQuery(GET_NOTIFICATION_AUCTION_ENDED_LIST,
  {variables:{destinataryId: user.me.id }});

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        sortable: false,
        filter: false,
        display: false,
        viewColumns: false,
      }, 
    },
    {
      name: "title",
      label: "Título",
      options: { sortable: true, filter: true },
    },
    {
      name: "body",
      label: "Mensaje",
      options: { sortable: false, filter: false },
    },
    {
      name: "actions",
      label: "Operaciones",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (dataIndex, tableMeta, updateValue) => {
          const id = tableMeta.rowData[0];
          return (
            <Grid
              container
              spacing={1}
              direction="row"
              className={classes.action}
            >
              <Grid item>
                <ButtonGroup
                  size="small"
                  aria-label="small outlined button group"
                >
                  <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    component={RouterLink}
                    to={`/admin/messages/edit/${id}`}
                  >
                    Editar
                  </Button>
                </ButtonGroup>
              </Grid>
              <Grid item>
                <ButtonGroup
                  size="small"
                  aria-label="small outlined button group"
                >
                  <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleDeleteDialogOpen(tableMeta.rowData[0])}
                  >
                    Borrar
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: "none",
    customToolbar: () => {
      return (
        
        <Tooltip title="Crear Mensaje">
          <IconButton component={RouterLink} to="/admin/messages/create">
            <AddIcon />
          </IconButton>
        </Tooltip>
      );
    },
    textLabels: translation['textLabels']
  };

  const handleDeleteDialogOpen = id => {
    setId(id);
    setIsDeleteConfirmOpen(true);
  };
  const handleDeleteDialogClose = confirm => {
    setIsDeleteConfirmOpen(false);
    if (confirm) {
      deleteMessage({
        variables: {
          input: {
            id: id,
          },
        },
      }).then(() => {
        toastDispatch({
          isOpen: true,
          type: "success",
          message: "El mensaje ha sido creado exitosamente",
        });
        refetch();
      });
    }
  };

  if (loading || loadingAuctionEnded) {
    return <p>Cargando...</p>;
  }
  if (error || errorAuctionEnded) {
    return <p>Error</p>;
  }

  return (
    <>
     {
       
       <>
        <ConfirmDialog
        isOpen={isDeleteConfirmOpen}
        handleClose={handleDeleteDialogClose}
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <ThemeProvider theme={theme}>
            <MUIDataTable
              title={"Cuenta"}
              columns={columns}
              data={[...data.messages.nodes , ...dataAuctionEnded.allMessagesEndedAuction]}
              options={options}
            />
          </ThemeProvider>
        </Grid>
      </Grid>
       </>
     }
    </>
  );
}
