export const translation = {
textLabels: {
    body: {
      noMatch: "No hay vehiculos compatibles en el sistema",
      toolTip: "Ordenar",
      columnHeaderTooltip: column => `Ordenar por ${column.label}`
    },
    pagination: {
      next: "Página siguiente",
      previous: "Página previa",
      rowsPerPage: "Filas por página:",
      jumpToPage: "Saltar a página:",
      nextPage: "Página siguiente"
    },
    toolbar: {
      search: "Buscar",
      downloadCsv: "Descargar CSV",
      print: "Imprimir",
      viewColumns: "Ver Columnas",
      filterTable: "Filtros",
    },
    filter: {
      all: "Todas",
      title: "FILTROS",
      reset: "Reiniciar",
    },
    viewColumns: {
      title: "Mostrar Columnas",
      titleAria: "Mostrar/Ocultar Columnas",
    },
  }
};
export default translation;