import React from "react";
import { Grid, Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  card: {
    width: "50rem",
  },
  detailsWrap: {
    flex: "0 1 100%",
    padding: "1rem",
    marginTop: "2%",
    border: "1px solid #ccc",
    borderRadius: "7px",
  },
  detailGroupWrap: {
    display: "flex",
  },
  detailValue: {
    flex: "1 1 23%",
    marginRight: "2%",
  },
  detailGroup: {
    flex: "1 1 30%",
    marginRight: "5%",
  },
  detailItem: {
    display: "flex",
    justifyContent: "space-between",
    "& p:first-child": {
      fontWeight: "bold",
    },
    "& p:last-child": {
      width: "15rem",
      wordBreak: "break-word",
    },
  },
  detailSmtp: {
    display: "flex",
    justifyContent: "space-between",
    "& p:first-child": {
      fontWeight: "bold",
    },
    "& p:last-child": {
      width: "35rem",
      wordBreak: "break-word",
    },
  }
});

export const ProfileBody = ({ data }) => {
  const classes = useStyles();
  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.detailGroupWrap}>
            <div className={classes.detailGroup}>
              <div className={classes.detailItem}>
                <p>Account</p>
                <p>{data.account?.name}</p>
              </div>
              <div className={classes.detailItem}>
                <p>Name</p>
                <p>{data.name}</p>
              </div>
              <div className={classes.detailItem}>
                <p>Email</p>
                <p>{data.email}</p>
              </div>
            </div>
            <div className={classes.detailGroup}>
              <div className={classes.detailItem}>
                <p>Role</p>
                <p>{data.role}</p>
              </div>
              <div className={classes.detailItem}>
                <p>Phone</p>
                <p>{data.phone}</p>
              </div>
              <div className={classes.detailItem}>
                <p>Comentarios</p>
                <p>{data.comments}</p>
              </div>
            </div>
          </div>
        </CardContent>
       
        <CardContent>
          <div className={classes.detailSmtp} >
            <p>Dirección de email SMTP</p>
            <p>{data.smtpEmail}</p> 
          </div>
          <div className={classes.detailSmtp}>
            <p>Contraseña email SMTP</p>
            <p>{data.smtpPassword ? '*******' : ' '} </p> 
          </div>
          <div className={classes.detailSmtp}>
            <p>Puerto SMTP</p>
            <p>{data.smtpPort}</p> 
          </div>
          <div className={classes.detailSmtp}>
            <p>Host SMTP</p>
            <p>{data.smtpHost}</p> 
          </div>
          <div className={classes.detailSmtp}>
            <p>Asunto SMTP</p>
            <p>{data.smtpSubject}</p> 
          </div>
          <div className={classes.detailSmtp}>
            <p>Plantilla de email</p>
          </div>
          <div>
            <p>{data.emailTemplate}</p> 
          </div>
        </CardContent>
      </Card>
    </div>
  );
};
