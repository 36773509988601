import React from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from "react-google-maps";

// styles
import useStyles from "./styles";

const googleAPI = "AIzaSyB1RyzaqXC7wzhxJv6XEF_MNysqB__etP8";
const BasicMap = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={15}
      defaultCenter={{
        lat: parseFloat(props.geolat),
        lng: parseFloat(props.geolong),
      }}
    >
      <Marker position={{ lat: props.geolat, lng: props.geolong }} />
    </GoogleMap>
  )),
);

export default function StandardMap(props) {
  var classes = useStyles();
  const mapUrl = "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key="+googleAPI
  return (
    <div className={classes.mapContainer}>
      <BasicMap
        googleMapURL={mapUrl}
        loadingElement={<div style={{ height: "inherit", width: "inherit" }} />}
        containerElement={<div style={{ height: "100%" }} />}
        mapElement={<div style={{ height: "100%" }} />}
        geolat={props.geolatitude}
        geolong={props.geolongitude}
      />
    </div>
  );
}