import React, { useEffect } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { gql, useLazyQuery } from "@apollo/client";

// components
import Layout from "../Layout";

// pages
import Error from "../../pages/error";
import { AdminRoute, NormalRoute, PublicRoute } from "./components";

import LayoutAdmin from "../LayoutAdmin";
import LayoutPublic from "../LayoutPublic";
import { useUserDispatch, useUserState } from "../../context/UserContext";

const GET_ME = gql`
  query getMe {
    me {
      id
      name
      role
      email
    }
  }
`;
// context
export const Router = props => {
  const userState = useUserState();
  const userDispatch = useUserDispatch();
  const [
    getMe,
    { data: me, loading: loadingMe, error: errorMe, called: calledMe, refetch: refetchMe },
  ] = useLazyQuery(GET_ME);
  if (
    userState.isAuthenticated &&
    !userState.me &&
    !me &&
    !errorMe &&
    !loadingMe
  ) {
    getMe();
  }
  useEffect(() => {
    if (calledMe && userState.isAuthenticated) {
      refetchMe();
    }
  }, [calledMe, userState.isAuthenticated]);
  if (loadingMe) {
    return <div>Loading</div>;
  }
  if (
    errorMe &&
    errorMe.message === "Not Authorised!" &&
    userState.isAuthenticated
  ) {
    setTimeout(() => {
      userDispatch({ type: "SIGN_OUT_SUCCESS" });
      localStorage.removeItem("id_token");
      window.location.reload();
    }, 0);
  } else if (errorMe) {
    return <div>Error</div>;
  }
  if (userState.isAuthenticated && me && me.me && (!userState.me || userState.me.id !== me.me.id)) {
    setTimeout(() => userDispatch({ me: me.me }), 0);
  }
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/app/dashboard" />} />
        <Route
          exact
          path="/app"
          render={() => <Redirect to="/app/dashboard" />}
        />
        <AdminRoute path="/admin" component={LayoutAdmin} />
        <NormalRoute path="/app" component={Layout} />
        <Route
          exact
          path="/reports/mostsold"
          render={() => <Redirect to="/app/report/mostsold" />}
        />
        <PublicRoute path="/" component={LayoutPublic} />
        <Route component={Error} />
      </Switch>
    </HashRouter>
  );
};
