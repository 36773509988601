import React from "react";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { gql, useLazyQuery } from '@apollo/client';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(0.1),
        textAlign: "left"
      },
    },
    circular: {
        textAlign: "left"
    }
 }));


function isPhoneWhatsApp(phone) {
    if (phone) {
        if ((phone.includes('+54') !== -1) || (phone.includes('11') !== -1)) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

function isMercadoLibre(link) {
    if (link) {
        if (link.includes("mercadolibre")) {
            return true;
        } else {
            return false;
        }
    }
}
export default function DisplayPhoneorWhatsApp( props ) {
    const classes = useStyles();
    const [getPhoneML, setGetPhoneML] = React.useState(false);
    const getPhoneMLA = gql`
    query getPhoneMLA($mlaID: String!, $link: String!) {
        getPhoneMLA(mlaID:$mlaID, link: $link)
    }`;
    //Example of ULR https://auto.mercadolibre.com.ar/MLA-860953969-alfa-romeo-stelvio-20-200cv-distinctive-q4-2020-_JM
    const mlaIDReg = RegExp(/MLA-\d*/).exec(props.link);
    var mlaID;
    var errorDefined=false;
    var phone;
    if (mlaIDReg) {
        mlaID = mlaIDReg[0].replace("-","");
    }
    const [getPhone, { loading : loadingPhone, error: errorPhone, data: dataPhone }] = useLazyQuery(getPhoneMLA,
        {
        fetchPolicy: "cache-and-network",
        nextFetchPolicy: "standby"
      }
    );
    const [open, setOpen] = React.useState(true);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    if (loadingPhone) return "Cargando...";
    // if ((dataPhone?.getPhoneMLA !== undefined) && (typeof dataPhone?.getPhoneMLA?.error !== undefined)) {
    if (dataPhone?.getPhoneMLA !== undefined) {
        //Query returns error when trying to get the phone
        var jsonResponse = JSON.parse(dataPhone.getPhoneMLA);
        if (jsonResponse.error === undefined) {
            phone = JSON.parse(dataPhone.getPhoneMLA)?.phone[0].replace("+","")
        } else {
            errorDefined = jsonResponse.error;
        }
    }
    if (errorDefined || errorPhone) {        
        return <><Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <MuiDialogTitle id="customized-dialog-title" onClose={handleClose}>
        No es posible obtener este teléfono en este momento por favor reintente nuevamente 
        </MuiDialogTitle>
        <MuiDialogContent dividers>
            Compruebe que la publicación tiene un teléfono asociado
        </MuiDialogContent>
        <MuiDialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
            Cerrar
        </Button>
        </MuiDialogActions>
    </Dialog>
    <div className={classes.root}><Button key={props.id} variant="contained" color="primary" target="_blank" onClick={() => getPhone({ variables: { mlaID: mlaID, link: props.link }})} size={'small'}>Obtener Teléfono</Button></div>
    </>
    }
    if ((props.phone === null) || (props.phone.trim() === "")) {
        if (isMercadoLibre(props.link)) {
            if ((phone !== undefined) && (typeof phone !== undefined) && (phone !== 'error')) {
                return <p><a key={props.id} target="_blank" rel="noopener noreferrer" href={"https://api.whatsapp.com/send?phone=" + phone}><WhatsAppIcon style={{color:"green", top: "10px"}}/></a>{phone}</p>
            } else {
                if (loadingPhone) {
                    return <div className={classes.circular}><CircularProgress /></div>;
                } else {
                    return <div className={classes.root}><Button key={props.id} variant="contained" color="primary" target="_blank" onClick={() => getPhone({ variables: { mlaID: mlaID, link: props.link }})} size={'small'}>Obtener Teléfono</Button></div>
                }
            }
        } else {
            return <div className={classes.root}><Button variant="contained" color="primary" target="_blank" size={'small'} href={props.link}>Ver Teléfono</Button></div>
        }
    } else {
        if (isPhoneWhatsApp(props.phone)) {
            return <p><a target="_blank" key={props.id} rel="noopener noreferrer" href={"https://api.whatsapp.com/send?phone=" + props.phone}><WhatsAppIcon style={{color:"green", top: "10px"}}/></a>{props.phone}</p>
        } else {
            return <p>{props.phone}</p>
        }
    }
}