import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Fab,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  MailOutline as MailIcon,
  NotificationsNone as NotificationsIcon,
  Person as AccountIcon,
  Search as SearchIcon,
  Send as SendIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import classNames from "classnames";

import {
  gql,
  useQuery,
  useApolloClient,
  useMutation,
  useSubscription,
} from "@apollo/client";
import { useHistory } from "react-router-dom";
// styles
import useStyles from "./styles";

// components
import { Badge, Typography } from "../Wrappers/Wrappers";
import Notification from "../Notification/Notification";
import UserAvatar from "../UserAvatar/UserAvatar";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useUserDispatch, useUserState } from "../../context/UserContext";

const SIGNOUT = gql`
  mutation logout {
    logout
  }
`;
const messages = [
  {
    id: 0,
    variant: "warning",
    name: "Jane Hew",
    message: "Hey! How is it going?",
    time: "9:32",
  },
  {
    id: 1,
    variant: "success",
    name: "Lloyd Brown",
    message: "Check out my new Dashboard",
    time: "9:18",
  },
  {
    id: 2,
    variant: "primary",
    name: "Mark Winstein",
    message: "I want rearrange the appointment",
    time: "9:15",
  },
  {
    id: 3,
    variant: "secondary",
    name: "Liana Dutti",
    message: "Good news from sale department",
    time: "9:09",
  },
];
const MESSAGE_SUBSCRIPTION = gql`
  subscription newMessage {
    newMessage {
      id
      title
      body
    }
  }
`;



const AUCTION_ENDED_SUBSCRIPTION = gql`
  subscription newMessageForDestinatary($destinataryId:String!) {
    newMessageForDestinatary(destinataryId:$destinataryId){
      id
      title
      body
      auctionId
      result 
      destinataryId
      view
      isNotificationsUnread
    }
  }
`;

const GET_NOTIFICATIONS = gql`
  query getUnreadNotifications {
    messages {
      nodes {
        id
        title
        body
      }
    }
  }
`;

const GET_NOTIFICATIONS_AUCTIONS = gql`
  query allMessagesEndedAuction($destinataryId:ID!) {
    allMessagesEndedAuction(destinataryId:$destinataryId){
      id
      title
      body
      auctionId
      result 
      destinataryId
      view
      isNotificationsUnread
    }
  }
`;

export default function Header(props) {
  var classes = useStyles();
 const userState = useUserState();
  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  
  // local
  var [mailMenu, setMailMenu] = useState(null);
  var [isMailsUnread, setIsMailsUnread] = useState(true);
  var [notificationsMenu, setNotificationsMenu] = useState(null);
  var [isNotificationsUnread, setIsNotificationsUnread] = useState(true);
  var [profileMenu, setProfileMenu] = useState(null);
  var [isSearchOpen, setSearchOpen] = useState(false);

  const userDispatch = useUserDispatch();
 
  const [signout] = useMutation(SIGNOUT);
  let notifications = [];
  const client = useApolloClient();
  

  const {
    data: notificationData,
    // loading: notificationLoading,
    refetch: notificationRefetch,
  } = useQuery(GET_NOTIFICATIONS);

  const {
    data: notificationDataAuction,
    // loading: notificationLoading,
    refetch: notificationAuctionRefetch,
  } = useQuery(GET_NOTIFICATIONS_AUCTIONS , {
    variables:{destinataryId:userState.me.id}
  });

  if (notificationData && notificationData.messages && notificationDataAuction && notificationDataAuction.allMessagesEndedAuction) {

    let messages = notificationData.messages.nodes 
      ? notificationData.messages.nodes 
      : [] ;
    let auctionsNot = notificationDataAuction.allMessagesEndedAuction 
      ? notificationDataAuction.allMessagesEndedAuction 
      : [] ;
  
    let allNotifications = [...messages , ...auctionsNot ]
     notifications = allNotifications.map(node => ({
      id: node.id,
      message: node.title,
      result:node.result ? node.result : null,
      destinataryId:userState.me.id,
      view:node.view ? node.view : null,
      isNotificationsUnread:node.isNotificationsUnread ? true: false
    }));
    
    if (!notifications.length && isNotificationsUnread) {
      setIsNotificationsUnread(false);
    } else if (notifications.length && !isNotificationsUnread) {
      setIsNotificationsUnread(true);
    }
  };
  const { data: subData } = useSubscription(
    MESSAGE_SUBSCRIPTION,
  );

    const { data: auctionEnded } = useSubscription(
     AUCTION_ENDED_SUBSCRIPTION,
     {variables:{
       destinataryId:userState.me.id
     }}
   );

  useEffect(()=>{
    if(subData && subData.newMessage ){
      client.clearStore().then((data)=>{
       notificationRefetch(); 
      })
    }
  },[client, notificationRefetch, subData])


  useEffect(()=>{
    if(auctionEnded && auctionEnded.newMessageForDestinatary ){
      notificationAuctionRefetch();
    }
  },[auctionEnded, notificationAuctionRefetch]);
  
  let countNotifications = (notificationsLengh , auctionsNotifications)=>{
    if(auctionsNotifications && auctionsNotifications.allMessagesEndedAuction){
   
      let auctionsData = auctionsNotifications.allMessagesEndedAuction
      let isReadCount=0;
      let isNotReadCount=0;

      for (let i = 0 ; i < auctionsData.length; i++){
        if(!auctionsData[i].isNotificationsUnread){
          isReadCount = isReadCount +1
        }else{
          isNotReadCount= isNotReadCount +1
        }
      }

      return notificationsLengh - isNotReadCount ;
    }
   
    return notificationsLengh;
  }
  const history = useHistory();
  const handleProfileClick = () => {
    history.push("/app/profile");
  };
 
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse,
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          )}
        </IconButton>
        <Typography variant="h6" weight="medium" className={classes.logotype}>
          carzCloud
        </Typography>
        <div className={classes.grow} />

        {/* <div
          className={classNames(classes.search, {
            [classes.searchFocused]: isSearchOpen,
          })}
        >
          <div
            className={classNames(classes.searchIcon, {
              [classes.searchIconOpened]: isSearchOpen,
            })}
            onClick={() => setSearchOpen(!isSearchOpen)}
          >
            <SearchIcon classes={{ root: classes.headerIcon }} />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
          />
        </div> */}
        <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-controls="mail-menu"
          onClick={e => {
            setNotificationsMenu(e.currentTarget);
          }}
          className={classes.headerMenuButton}
        >
          <Badge
            badgeContent={isNotificationsUnread ?countNotifications(notifications.length , notificationDataAuction) : null}
            
            color="warning"
          >
            <NotificationsIcon classes={{ root: classes.headerIcon }} />
          </Badge>
        </IconButton>
        {/* <IconButton
          color="inherit"
          aria-haspopup="true"
          aria-controls="mail-menu"
          onClick={e => {
            setMailMenu(e.currentTarget);
            setIsMailsUnread(false);
          }}
          className={classes.headerMenuButton}
        >
          <Badge
            badgeContent={isMailsUnread ? messages.length : null}
            color="secondary"
          >
            <MailIcon classes={{ root: classes.headerIcon }} />
          </Badge>
        </IconButton> */}
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>
        {/* <Menu
          id="mail-menu"
          open={Boolean(mailMenu)}
          anchorEl={mailMenu}
          onClose={() => setMailMenu(null)}
          MenuListProps={{ className: classes.headerMenuList }}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              New Messages
            </Typography>
            <Typography
              className={classes.profileMenuLink}
              component="a"
              color="secondary"
            >
              {messages.length} New Messages
            </Typography>
          </div>
          {messages.map(message => (
            <MenuItem key={message.id} className={classes.messageNotification}>
              <div className={classes.messageNotificationSide}>
                <UserAvatar color={message.variant} name={message.name} />
                <Typography size="sm" color="text" colorBrightness="secondary">
                  {message.time}
                </Typography>
              </div>
              <div
                className={classNames(
                  classes.messageNotificationSide,
                  classes.messageNotificationBodySide,
                )}
              >
                <Typography weight="medium" gutterBottom>
                  {message.name}
                </Typography>
                <Typography color="text" colorBrightness="secondary">
                  {message.message}
                </Typography>
              </div>
            </MenuItem>
          ))}
          <Fab
            variant="extended"
            color="primary"
            aria-label="Add"
            className={classes.sendMessageButton}
          >
            Send New Message
            <SendIcon className={classes.sendButtonIcon} />
          </Fab>
        </Menu> */}
        <Menu
          id="notifications-menu"
          open={Boolean(notificationsMenu)}
          anchorEl={notificationsMenu}
          onClose={() => setNotificationsMenu(null)}
          className={classes.headerMenu}
          disableAutoFocusItem
        >
          {notifications.map(notification => (
            !notification.isNotificationsUnread &&
            <MenuItem
              key={notification.id}
              onClick={() => setNotificationsMenu(null)}
              className={classes.headerMenuItem}
            >
              <Notification 
              notificationAuctionRefetch={notificationAuctionRefetch}
              {...notification} 
              typographyVariant="inherit" />
            </MenuItem>
          ))}
        </Menu>
        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant="h4" weight="medium">
              {userState && userState.me ? userState.me.name : ""}
            </Typography>
          </div>
          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
            onClick={handleProfileClick}
          >
            <AccountIcon className={classes.profileMenuIcon} /> Mi cuenta
          </MenuItem>
          {/* <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
          >
            <AccountIcon className={classes.profileMenuIcon} /> Mensajes
          </MenuItem> */}
          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color="primary"
              onClick={() => {
                signout().then(() => {
                  localStorage.removeItem("id_token");
                  client.clearStore();
                  userDispatch({ type: "SIGN_OUT_SUCCESS" });
                  props.history.push("/login");
                });
              }}
            >
              Salir
            </Typography>
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

