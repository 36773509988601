import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Breadcrumbs,
  Link,
  Switch,
} from "@material-ui/core";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { gql, useMutation, useQuery, useApolloClient } from "@apollo/client";
import { FunctionalityForm } from "../components";
import { useToastDispatch } from "../../../context/ToastContext";


const GET_ONE_FUNCTIONALITY = gql`
  query getOneFunctionality($id: ID!) {
    getOneFunctionality(id: $id) {
      id
      title
      description
      state
    }
  }
`;

const EDIT_FUNCTIONALITY = gql`
  mutation updateFunctionality($input: UpdateFunctionalityInput!) {
    updateFunctionality(input: $input) {
      id
    }
  }
`;

export const FunctionalityEdit = () => {
  const client = useApolloClient();
  const history = useHistory();
  const id = parseInt(history.location.pathname.split('/')[4])
  const toastDispatch = useToastDispatch();

  const {
    loading: loadingFunctionality,
    error: errorLoadingFunctionality,
    data: functionality,
  } = useQuery(GET_ONE_FUNCTIONALITY, {
    variables: {
      id
    },
  });

  const [updateOrder, { loading: mutationLoading }] = useMutation(EDIT_FUNCTIONALITY);

  if (loadingFunctionality) {
    return <p>Loading...</p>;
  }
  if (errorLoadingFunctionality) {
    return <p>Error</p>;
  }

  const handleSubmit = (value) => {

  let setValueState =(value)=>{
    switch (value) {
      case 1:
        return "Open"
      case 2:
      return  "Close"
      case 3:
        return "Executing"
      default:
        break;
    }
    }
    
    const input = {
      id: id,
      title: value.title,
      description: value.description,
      state:setValueState(value.state)
    };

    updateOrder({ variables: { input } }).then(async () => {
      await client.clearStore();
      toastDispatch({
        isOpen: true,
        type: "success",
        message: "La Funcionaldad fue Editada!",
      });
      history.push("/app/functionalities");
    });
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={8}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" component={RouterLink} to="/app/functionalities">
            Funcionalidades
          </Link>
          <Link
            color="textPrimary"
            component={RouterLink}
            to={`/app/functionalities/edit/${id}`}
            aria-current="page"
          >
            Editar Una Funcionalidad
          </Link>
        </Breadcrumbs>
        <Card>
          <CardHeader title="Editar Una Funcionalidad" />
          <CardContent>
            {functionality.getOneFunctionality ? (
              <FunctionalityForm 
                data={functionality.getOneFunctionality}
                loading={mutationLoading}
                submitText="Editar"
                onSubmit={handleSubmit}
              />
            ) : (
              <p>No Data Found</p>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
