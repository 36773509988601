import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { gql, useQuery } from "@apollo/client";
import { makeStyles } from '@material-ui/core';
import * as moment from 'moment';

const useStyles = makeStyles(theme => ({
  action: {
    minWidth: "163px",
    justifyContent:"end"
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  
const GET_ONE_FUNCTIONALITY = gql`
  query getOneFunctionality($id: ID!) {
    getOneFunctionality(id: $id) {
      id
      title
      description
      state
      createdAt
    }
  }
`;

export const FunctionalityDialog = ({ isOpen, handleClose , id  }) => {
    const {
        loading,
        error,
        data
      } = useQuery(GET_ONE_FUNCTIONALITY, {
        variables: {
          id
        },
      });

      if (loading) {
        return <p>Cargando...</p>;
      }
    
      if (error) {
        return <p>Error</p>;
      }
  return (
  <div>
    {
        data &&
        <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
        
        <DialogContent>
          <h3 style={{fontWeight:"bold" , fontSize:"45px"}}>{data.getOneFunctionality.title}</h3>
              <h4 style={{marginBottom:"-3px"}}>{"Fecha de creación"}</h4> 
              {moment(data.getOneFunctionality.createdAt).format('DD-MM-YYYY')}
            <h4 style={{marginBottom:"-3px"}}>{"Descripción"}</h4>
                {data.getOneFunctionality.description}
         </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
              OK
          </Button>
        </DialogActions>
        </Dialog>
        }
    </div>
  );
};
