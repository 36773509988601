import React from "react";
import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import Typography from "../../pages/typography";
import Notifications from "../../pages/notifications";
import Maps from "../../pages/maps";
import Tables from "../../pages/tables";
import Icons from "../../pages/icons";
import Charts from "../../pages/charts";

import VehicleCreate from "../../pages/vehicleCreate";
import VehicleEdit from "../../pages/vehicleEdit";
import VehicleTable from "../../pages/vehicles/VehicleTable";

import MostSoldCarsByBrands from "../../pages/cars/reports/mostsold/MostSoldCarsByBrand";
import MostSoldCarsByModels from "../../pages/cars/reports/mostsold/MostSoldCarsByModels";
import PriceProgression from "../../pages/cars/reports/mostsold/PriceProgression";

import AllSellingCars from "../../pages/cars/listing/AllSellingCars/AllSellingCars";

import BuyOpportunities from "../../pages/cars/listing/BuyOpportunities/BuyOpportunities";

import CarsOriginalPriceDiscounted from "../../pages/cars/listing/CarsOriginalPriceDiscounted/CarsOriginalPriceDiscounted";

import carsRecentlyPublished from "../../pages/cars/listing/CarsRecentlyPublished/CarsRecentlyPublished";

import FastestSoldCars from "../../pages/cars/listing/FastestSoldCars/FastestSoldCars"
// context
import { useLayoutState } from "../../context/LayoutContext";
import { Profile, ProfileEdit } from "../../pages/profile";
import { CustomerEdit, CustomerCreate, CustomersTable } from "../../pages/customers";
import { OrderEdit, OrderCreate, OrdersTable } from "../../pages/orders";
import VehicleDetail from "../../pages/vehicles/VehicleDetail";
import VehicleDetailAuction from "../../pages/vehicles/VehicleDetail/VehicleDetailAuction";
import AuctionTable from "../../pages/vehicles/AuctionTable";
import { FunctionalityCreate, FunctionalityTable, FunctionalityEdit } from '../../pages/functionality';
import { BudgetCarPage, BudgetCustomerPage, } from '../../pages/budget';

import { useUserState } from "../../context/UserContext";

import { MessageView } from "../../pages/messages";


function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();
  var userId = useUserState();
  return (
    <div className={classes.root}>
      {
        userId && userId.me ?
          <>
            <Header history={props.history} userId={userId.me.id} />
            <Sidebar />
            <div
              className={classnames(classes.content, {
                [classes.contentShift]: layoutState.isSidebarOpened,
              })}
            >
              <div className={classes.fakeToolbar} />
              <Switch>
                <Route path="/app/dashboard" component={Dashboard} />
                <Route path="/app/reports/mostsoldbybrand" component={MostSoldCarsByBrands} />
                <Route path="/app/reports/mostsoldbymodel" component={MostSoldCarsByModels} />
                <Route path="/app/reports/priceprogression" component={PriceProgression} />
                <Route path="/app/listing/cars" component={AllSellingCars} />
                <Route path="/app/listing/carBuyOpportunities" component={BuyOpportunities} />
                <Route path="/app/listing/carsRecentlyPublished" component={carsRecentlyPublished} />
                <Route path="/app/listing/carsOriginalPriceDiscounted" component={CarsOriginalPriceDiscounted} />
                <Route path="/app/listing/carFastestSold" component={FastestSoldCars} />

                <Route exact path="/app/vehicles" component={VehicleTable} />
                <Route exact path="/app/vehicles/detail/:id" component={VehicleDetail} />
                <Route exact path="/app/vehicles/detailAuction/:id" component={VehicleDetailAuction} />
                <Route exact path="/app/vehicles/create" component={VehicleCreate} />
                <Route exact path="/app/vehicles/edit/:id" component={VehicleEdit} />
                <Route exact path="/app/auctions" component={AuctionTable} />
                <Route path="/app/profile/edit" component={ProfileEdit} />
                <Route path="/app/profile" component={Profile} />
                <Route path="/app/customers/create" component={CustomerCreate} />
                <Route path="/app/customers/edit/:id" component={CustomerEdit} />
                <Route path="/app/customers" component={CustomersTable} />
                <Route path="/app/orders/create" component={OrderCreate} />
                <Route path="/app/orders/edit/:id" component={OrderEdit} />
                <Route path="/app/orders" component={OrdersTable} />
                <Route path="/app/functionalities/create" component={FunctionalityCreate} />
                <Route path='/app/functionalities/edit' component={FunctionalityEdit} />
                <Route path="/app/functionalities" component={FunctionalityTable} />

                <Route path="/app/budget/create/:id" component={BudgetCarPage} />
                <Route path="/app/budget" component={BudgetCustomerPage} />
                <Route path="/app/allnotifications/:id" component={MessageView} />
              </Switch>
            </div>
          </>
          :
          <>
          </>
      }
    </div>
  );
}

export default withRouter(Layout);
