import React, { useState } from "react";
import {
  Select,
  InputLabel,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
} from "@material-ui/core";

export function SingleSelectFilter(props) {
  const { filterList, onChange, index, column, options } = props;
  const [selectedValue, setSelectedValue] = useState(filterList[index] && filterList[index].length > 0 ? filterList[index]: "");
  return (
    <FormControl>
      <InputLabel htmlFor="select-multiple-chip">{column.name}</InputLabel>
      <Select
        value={selectedValue}
        onChange={event => {
          setSelectedValue(event.target.value);
          filterList[index] = [event.target.value];
          onChange(filterList[index], index, column);
        }}
        disabled={!options.length}
      >
        {options.map((item, key) => (
          <MenuItem key={key} value={item}>
            <ListItemText primary={item} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
