import React from "react";
import {
  TextField,
  FormControl,
  Button,
  FormHelperText,
  CircularProgress,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { useUserState } from "../../../../context/UserContext";

const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  formControl: {
    margin: "5px 5px",
    flex: "1 1 40%",
    textAlign: "left",
  },
  buttonWrap: {
    flex: "1 1 100%",
    display: "flex",
    alignItems: "flex-end",
  },
  submitButton: {
    marginTop: "1rem",
  },
  loading: {
    marginLeft: "1rem",
  },
}));

export const FunctionalityForm = ({
  data,
  submitText,
  onSubmit,
  loading,
}) => {

  const classes = useStyles();
  const { register, handleSubmit, watch , errors, control } = useForm();
  const userState = useUserState();
  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
      <FormControl
        error={errors.title ? true : false}
        hiddenLabel
        required
        variant="outlined"
        className={classes.formControl}
      >
        <TextField
        className={classes.formControl}
        defaultValue={data.title}
        error={errors.title ? true : false}
        InputLabelProps={{ required: true }}
        inputRef={register({ required: "Título es requerido" })}
        label="Título"
        name="title"
        type="string"
        variant="outlined"
      />
        <FormHelperText error={true}>
          {errors.title && errors.title.message}
        </FormHelperText>
      </FormControl>
      <>
      {
        userState.isAdmin ?
        <>
          {
            data &&
            <SelectComponent
              data = {data.state}
              classes={classes}
              control={control}
              errors={errors}
            />
          }
        </>
          :
          <></>
        }
      </>
      <TextField
        className={classes.formControl}
        defaultValue={data.description}
        error={errors.description ? true : false}
        InputLabelProps={{ required: true }}
        inputRef={register({ required: "La descripción es requerida" })}
        label="Descripción"
        name="description"
        type="string"
        variant="outlined"
      />

      <div className={classes.buttonWrap}>
        <Button
          className={classes.submitButton}
          disabled={loading}
          variant="contained"
          color="primary"
          type="submit"
        >
          {submitText}
        </Button>
        {loading && <CircularProgress size={35} className={classes.loading} />}
      </div>
    </form>
  );
};

//Select Component
const SelectComponent = ( {stateComponent , control , classes , errors} )=>{
  let options = [
    {
      id:1,
      title:"Votando",
      name:"Open"
    },
    {
      id:2,
      title:"Votacion cerrada",
      name:"Close"
    },
    {
      id:3,
      title:"Implementada",
      name:"Executing"
    }
  ]

  return(
    <FormControl
      error={errors.title ? true : false}
      hiddenLabel
      required
      variant="outlined"
      className={classes.formControl}
    >
      <InputLabel>Estado</InputLabel>
      <Controller
        as={
          <Select>
            {options.map(state => (
              <MenuItem key={state.id} value={state.id}>
                {state.title}
              </MenuItem>
            ))}
          </Select>
        }
        control={control}
        defaultValue={ options[0].id || ""}
        label="Estado"
        name="state"
        rules={{ required: "" }}
      />
      <FormHelperText error={true}>
        {errors.state && errors.state.message}
      </FormHelperText>
    </FormControl>
  )
}
