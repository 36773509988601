import React from "react";
import { useQuery, gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { BidDialog } from "../components/BidDialog/BidDialog";
import TimeCounter from "./TimeCounter";
import { LatestAuction } from '../components/AuctionSubscription/AucitonSubscription'
import * as moment from 'moment'

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  carousel: {
    flex: "1 1 65%",
    marginRight: "2%",
    padding: "1rem",
    border: "1px solid #ccc",
    borderRadius: "7px",
    maxWidth: "500px",
    maxHeight: "500px"
  },
  carouselImg: {
    maxWidth: "350px",
    maxHeight: "350px",
    width: "auto",
    height: "auto"
  },
  priceWrap: {
    flex: "1 1 30%",
    padding: "1rem",
    border: "1px solid #ccc",
    borderRadius: "7px",
  },
  price: {
    fontSize: "2.5rem",
    fontWeight: "bold",
    textAlign: "center",
  },
  detailsWrap: {
    flex: "0 1 100%",
    padding: "1rem",
    marginTop: "2%",
    border: "1px solid #ccc",
    borderRadius: "7px",
  },
  detailGroupWrap: {
    display: "flex"
  },
  detailValue: {
    flex: "1 1 23%",
    marginRight: "2%",
  },
  detailGroup: {
    flex: "1 1 25%",
    marginRight: "2%",
  },
  detailItem: {
    display: "flex",
    justifyContent: "space-between",
    "& p:first-child": {
      fontWeight: "bold",
    },
    "& p:last-child": {
      width: "27rem",
      wordBreak: "break-word",
    },
  },
});

const GET_CAR = gql`
    query car($id: ID!) {
        car(id: $id) {
            id
            photos {
                id
                file
                url
                position
            }
            brandId
            brand {
                name
            }
            brand_model {
                name
            }
            mileage
            transmissionType
            condtion
            carModelId
            year
            version{
              id
            }
            fuelType
            doors
            condtion
            color
            address
            state
            transmissionType
            user {
                id
                name
                phone
                email
            }
            carType
            sellerType
            observations
            sellingPrice
            auction {
                id
                initialPrice
                incrementUnit
                currentPrice
                observations
                endedAt
                bids {
                    id
                    bidPrice
                }
                sentByMe
                biddenByMe
            }
        }
    }
`;
export default function VehicleDetailAuction() {
  const classes = useStyles();
  const { id } = useParams();
  const [auctionData, setAuctionData] = React.useState(false);

  const {
    loading: loadingCar,
    error: errorLoadingCar,
    data: carData,
    refetch,
  } = useQuery(GET_CAR, {
    variables: {
      id: +id,
    },
  });
  React.useEffect(() => {
    if (carData) {
      refetch();
    }
  }, [carData, refetch]);
  const handleOpenBidDialog = auctionData => {
    setAuctionData(auctionData);
  };
  const handleCloseBidDialog = () => {
    setAuctionData(false);
    refetch();
  };
  if (loadingCar) {
    return "Cargando";
  }
  if (errorLoadingCar) {
    console.error(errorLoadingCar);
    return "Error";
  }
  const car = carData.car;
  const endedAt = car?.auction?.endedAt ? new Date(car?.auction?.endedAt).getTime() : undefined;

  const isExpired = endedAt ? endedAt < Date.now() : true;

  return (
    <div className={classes.root}>
    {
      auctionData &&
      <BidDialog data={auctionData} handleClose={handleCloseBidDialog}/>
    }
      {car?.photos ? (
        <Carousel className={classes.carousel}>
          {car.photos.map(photo => (
            <div key={photo.id}>
              <img alt={photo.id} src={`${process.env.REACT_APP_SERVER_ROOT_URL + '/images/' + photo.url}`}/>
            </div>
          ))}
        </Carousel>
      ) : (
        <div>No posee fotos</div>
      )}
      <div className={classes.priceWrap}>
        <div className={classes.price}>Precio: <LatestAuction auctionData={car.auction? car.auction : null }/></div>
      </div>
      <div className={classes.detailsWrap}>
        <div style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}>
          <Typography variant="h2"><strong>Detalles</strong></Typography>
          {endedAt && <TimeCounter endedAt={endedAt}/>}
        </div>
        <div className={classes.detailGroupWrap}>
          <div className={classes.detailGroup}>
            <div className={classes.detailItem}>
              <p>Marca</p>
              <p>{car.brand?.name}</p>
            </div>
            <div className={classes.detailItem}>
              <p>Modelo</p>
              <p>{car.brand_model?.name}</p>
            </div>
            <div className={classes.detailItem}>
              <p>Año</p>
              <p>{car?.year}</p>
            </div>
            <div className={classes.detailItem}>
              <p>Kilometraje</p>
              <p>{car?.mileage}</p>
            </div>
            <div className={classes.detailItem}>
              <p>Observaciones</p>
              <p>{car?.observations}</p>
            </div>
            <div className={classes.detailItem}>
              <p>Precio actual de Subasta</p>
              <p>{<LatestAuction auctionData={car.auction? car.auction : null }/>}</p>
            </div>
            <div className={classes.detailItem}>
              <p>Precio inicial de subasta</p>
              <p>{car?.auction?.initialPrice}</p>
            </div>
            <div className={classes.detailItem}>
              <p>Observaciones de la subasta</p>
              <p>{car?.auction?.observations}</p>
            </div>
            <div className={classes.detailItem}>
              <p>Cantidad de pujas</p>
              <p>{car?.auction?.bids?.length || 0}</p>
            </div>
            <div className={classes.detailItem}>
              <p>Tipo de combustible</p>
              <p>{car?.fuelType}</p>
            </div>
            <div className={classes.detailItem}>
              <p>Tipo de transmisión</p>
              <p>{car?.transmissionType}</p>
            </div>
          </div>
          <div className={classes.detailGroup}>
            <div className={classes.detailItem}>
              <p>Condición</p>
              <p>{car?.condtion}</p>
            </div>
            <div className={classes.detailItem}>
              <p>Color</p>
              <p>{car?.color}</p>
            </div>
            <div className={classes.detailItem}>
              <p>Provincia</p>
              <p>{car?.state}</p>
            </div>
            <div className={classes.detailItem}>
              <p>Ciudad</p>
              <p>{car?.address}</p>
            </div>
            <div className={classes.detailItem}>
              <p>Tipo de vehículo</p>
              <p>{car?.carType}</p>
            </div>
            <div className={classes.detailItem}>
              <p>Expira</p>
              <p>{car?.auction?.endedAt && moment(new Date(car?.auction?.endedAt)).format('DD-MM-YYYY')}</p>
            </div>
            <div className={classes.detailItem}>
              <p>Teléfono</p>
              <p>{car?.user.phone}</p>
            </div>
            {car.description?
            (<div className={classes.detailItem}>
              <p>Description</p>
              <p>{car?.description}</p>
            </div>):""}
          </div>
         
        </div>
        <div style={{ textAlign: "center" }}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            style={{ margin: "auto 20px" }}
            disabled={car?.auction?.sentByMe || isExpired}
            onClick={() => handleOpenBidDialog(car?.auction)}
          >
            Ofertar
          </Button>          
        </div>
      </div>
    </div>
  );
};
