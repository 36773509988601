import React from "react";
import {
  TextField,
  FormControl,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  Switch,
  Button,
  FormHelperText,
  CircularProgress,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery, gql } from "@apollo/client";

const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  formControl: {
    margin: "5px 5px",
    flex: "1 1 40%",
    textAlign: "left",
  },
  buttonWrap: {
    flex: "1 1 100%",
    display: "flex",
    alignItems: "flex-end",
  },
  submitButton: {
    marginTop: "1rem",
  },
  loading: {
    marginLeft: "1rem",
  },
}));

const GET_CUSTOMERS = gql`
  query getCustomers {
    customers {
      nodes {
        id
        name
      }
    }
  }
`;

const GET_VEHICLES_LIST = gql`
  query getVehicles {
    cars {
      nodes {
        id
        brand {
          name
        }
        brand_model {
          name
        }
        year
      }
    }
  }
`;
export const OrderForm = ({
  data,
  submitText,
  onSubmit,
  loading,
}) => {
  const classes = useStyles();
  const { register, handleSubmit, watch, errors, control } = useForm();
  const { data: carsData, loading: carsLoading, error: carsError } = useQuery(
    GET_VEHICLES_LIST,
  );
  const {
    data: customersData,
    loading: customersLoading,
    error: customersError,
  } = useQuery(GET_CUSTOMERS);
  if (carsLoading || customersLoading) {
    return <p>Cargando...</p>;
  }
  if (carsError || customersError) {
    return <p>Error</p>;
  }
  const cars = carsData.cars.nodes;
  const customers = customersData.customers.nodes;
  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
      <FormControl
        error={errors.customer ? true : false}
        hiddenLabel
        required
        variant="outlined"
        className={classes.formControl}
      >
        <InputLabel>Cliente</InputLabel>
        <Controller
          as={
            <Select>
              {customers.map(customer => (
                <MenuItem key={customer.id} value={customer.id}>
                  {customer.name}
                </MenuItem>
              ))}
            </Select>
          }
          control={control}
          defaultValue={data.customerId || ""}
          label="Customer"
          name="customerId"
          rules={{ required: "El cliente es requerido" }}
        />
        <FormHelperText error={true}>
          {errors.customer && errors.customer.message}
        </FormHelperText>
      </FormControl>
      <FormControl
        error={errors.car ? true : false}
        hiddenLabel
        required
        variant="outlined"
        className={classes.formControl}
      >
        <InputLabel>Vehículo</InputLabel>
        <Controller
          as={
            <Select>
              {cars.map(car => (
                <MenuItem key={car.id} value={car.id}>
                  {`${car.brand.name} - ${car.brand_model.name} - ${car.year}`}
                </MenuItem>
              ))}
            </Select>
          }
          control={control}
          defaultValue={data.carId || ""}
          label="Car"
          name="carId"
          rules={{ required: "El vehículo es requerido" }}
        />
        <FormHelperText error={true}>
          {errors.car && errors.car.message}
        </FormHelperText>
      </FormControl>
      <TextField
        className={classes.formControl}
        defaultValue={data.soldPrice}
        error={errors.soldPrice ? true : false}
        helperText={errors.soldPrice ? errors.soldPrice.message : ""}
        InputLabelProps={{ required: true }}
        inputRef={register({ required: "El precio de venta es requerido" })}
        label="Precio de venta"
        name="soldPrice"
        type="number"
        variant="outlined"
      />
      <div className={classes.buttonWrap}>
        <Button
          className={classes.submitButton}
          disabled={loading}
          variant="contained"
          color="primary"
          type="submit"
        >
          {submitText}
        </Button>
        {loading && <CircularProgress size={35} className={classes.loading} />}
      </div>
    </form>
  );
};
