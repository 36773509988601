import React from "react";
import PropTypes from "prop-types";
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  Grid,
  Switch,
  Button,
  FormHelperText,
  FormControlLabel,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  form: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: "5px 5px",
    flex: "1 1 30%",
    textAlign: "left",
  },
  checkFormControl: {
    margin: "5px 5px",
    flex: "1 1 30%",
    textAlign: "center",
  },
  actionButtons: {
    flex: "1 1 100%",
    display: "flex",
    alignItems: "flex-end",
    marginTop: '1rem',
  },
}));

export const SalesData = ({ data, pushInput, handleBack }) => {
  const classes = useStyles();

  const [formState, setFormState] = React.useState({
    values: {
      firstOwner: false,
      sellerType: "",
    },
  });

  const { register, handleSubmit, errors, control } = useForm();

  const handleInputChange = event => {
    // event.persist();
    // const value =
    //   event.target.type === "checkbox"
    //     ? event.target.checked
    //     : event.target.value;
    // setFormState(formState => ({
    //   ...formState,
    //   values: {
    //     ...formState.values,
    //     [event.target.name]: value,
    //   },
    // }));
    // pushInput({ [event.target.name]: value });
  };
  return (
    <form onSubmit={handleSubmit(pushInput)} noValidate>
      <div className={classes.form}>
        <TextField
          className={classes.formControl}
          defaultValue={data.address}
          error={errors.address ? true : false}
          helperText={errors.address ? errors.address.message : ""}
          InputLabelProps={{ required: true }}
          inputRef={register({ required: "dirección is required" })}
          label="Dirección"
          name="address"
          variant="outlined"
        />
        <FormControl
          variant="outlined"
          required
          className={classes.formControl}
        >
          <InputLabel>Tipo de vendedor</InputLabel>
          <Controller
            as={
              <Select error={errors.sellerType ? true : false}>
                <MenuItem value="Particular">Particular</MenuItem>
                <MenuItem value="Concesionaria">Concesionaria</MenuItem>
              </Select>
            }
            control={control}
            defaultValue={data.sellerType}
            label="Tipo de vendedor"
            name="sellerType"
            rules={{ required: "tipo de vendedor is required" }}
          />
          <FormHelperText error={true}>
            {errors.sellerType && errors.sellerType.message}
          </FormHelperText>
        </FormControl>
        <TextField
          className={classes.formControl}
          defaultValue={data.observations}
          error={errors.observations ? true : false}
          helperText={errors.observations ? errors.observations.message : ""}
          inputRef={register}
          label="Observaciones"
          name="observations"
          variant="outlined"
        />
        <TextField
          className={classes.formControl}
          defaultValue={data.sellingPrice}
          error={errors.sellingPrice ? true : false}
          helperText={errors.sellingPrice ? errors.sellingPrice.message : ""}
          InputLabelProps={{ required: true }}
          inputRef={register({ required: "sellingPrice is required" })}
          label="Precio de venta"
          name="sellingPrice"
          type="number"
          variant="outlined"
        />
        <TextField
          className={classes.formControl}
          defaultValue={data.purchasedPrice}
          error={errors.purchasedPrice ? true : false}
          helperText={
            errors.purchasedPrice ? errors.purchasedPrice.message : ""
          }
          InputLabelProps={{ required: true }}
          inputRef={register({ required: "purchasedPrice is required" })}
          label="Precio de compra"
          name="purchasedPrice"
          type="number"
          variant="outlined"
        />
        <Typography component="div" className={classes.checkFormControl}>
          <InputLabel>Único dueño</InputLabel>
          <Grid
            component="label"
            container
            alignItems="center"
            spacing={1}
            justify="center"
          >
            <Grid item>No</Grid>
            <Grid item>
              <FormControlLabel
                control={<Switch defaultChecked={data.firstOwner} />}
                inputRef={register}
                name="firstOwner"
              />
            </Grid>
            <Grid item>Si</Grid>
          </Grid>
        </Typography>
      </div>
      <div className={classes.actionButtons}>
        <Button className={classes.backButton} onClick={handleBack}>
          Atrás
        </Button>
        <Button color="primary" variant="contained" type="submit">
          Siguiente
        </Button>
      </div>
    </form>
  );
};

// SalesData.propTypes = {
//   pushInput: PropTypes.func.isRequired,
// };
