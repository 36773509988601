import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Breadcrumbs,
  Link,
} from "@material-ui/core";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { CustomerForm } from "../components";
import { gql, useMutation, useApolloClient } from "@apollo/client";
import { useToastDispatch } from "../../../context/ToastContext";

const CREATE_CUSTOMER = gql`
  mutation createCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      id
    }
  }
`;
export const CustomerCreate = () => {
  const client = useApolloClient();
  const toastDispatch = useToastDispatch();
  const history = useHistory();
  const [createCustomer, { loading: mutationLoading }] = useMutation(
    CREATE_CUSTOMER,
  );

  const handleSubmit = customer => {
    const input = {
      id: customer.id,
      name: customer.name,
      phone: customer.phone,
      email: customer.email,
      preferredCarType: customer.preferredCarType,
      preferredYear: customer.preferredYear,
      preferredPriceRange: customer.preferredPriceRange,
      comments: customer.comments,
      birthday: new Date(customer.birthday),
    };
    createCustomer({ variables: { input } }).then(async () => {
      await client.clearStore();
      toastDispatch({
        isOpen: true,
        type: "success",
        message: "Customer is successfully created",
      });
      history.push("/app/customers");
    });
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={8}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" component={RouterLink} to="/app/customers">
            Customer
          </Link>
          <Link
            color="textPrimary"
            component={RouterLink}
            to="/app/customers/create"
            aria-current="page"
          >
            Crear Una Customer
          </Link>
        </Breadcrumbs>
        <Card>
          <CardHeader title="Crear Una Customer" />
          <CardContent>
            <CustomerForm
              data={{}}
              submitText="Crear"
              onSubmit={handleSubmit}
              loading={mutationLoading}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
