import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useQuery, useMutation, useApolloClient } from "@apollo/client";
import gql from "graphql-tag";
import { Card, CardContent, CardMedia, Grid, makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useToastDispatch } from '../../../context/ToastContext';
import NumberFormat from "react-number-format";

const useStyles = makeStyles(theme => ({
  action: {
    minWidth: "363px",
    justifyContent:"end"
  },
    root: {
    
      maxWidth: 645,
    },
    media: {
      height: 190,
    },
    cardContent:{
      width:"100%"
    },
    cardWeing:{
      fontWeight:"bold"
    },
    notCarImage:{
      height:'190px',
      width:"100%",
      display:"flex",
      justifyContent:"center",
      alignItems:"center"
    }
}));

const SEND_BUDGET_CAR = gql`
  mutation sendBudgetCar($input: SendBudgetCarInput) {
    sendBudgetCar(input: $input){
      isSend
      message
    }
  }
`;

export const BudgetPreview = ({ isOpen, handleClose , data , collectionIdCars , refetch  }) => {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const client = useApolloClient();

  const [isSending , setIsSendig] = useState(false);

  const toastDispatch = useToastDispatch();
  const [userId , setUser ] = useState(history.location.pathname.split('/')[4])
  const [ sendBudgetCar ] = useMutation(SEND_BUDGET_CAR);

  const descriptionElementRef = React.useRef(null); 
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
 
  const handleSendBudget=()=>{
    setIsSendig(true)
      let input ={
        clientId:userId,
        body:collectionIdCars
      }
      sendBudgetCar({ variables: { input } })
      .then(async (response) => {
        console.log(response.data)
        if(!response.data.sendBudgetCar.isSend){
          await client.clearStore();
          toastDispatch({
            isOpen: true,
            type: "error", 
            message: `${response.data.sendBudgetCar.message}`,
          });
          setIsSendig(false)
        }
        else{
          await client.clearStore();
            toastDispatch({
              isOpen: true,
              type: "success",
              message: `${response.data.sendBudgetCar.message}`,
            });
          refetch();
          setIsSendig(false)
          setTimeout(() => {
            history.push('/app/budget/create/') 
          }, 100);
          }
      });
  
  }
  return (
    <div>
      {
        data &&
        <>
          <Dialog
            open={isOpen}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">Vista Previa</DialogTitle>
            <DialogContent dividers={scroll === 'paper'}>
              <CardComponent 
                dataCars = {data.previewBudgetCar}
              />
            </DialogContent>
            <DialogActions>
              <Button 
              onClick={handleClose} 
              color="primary"
              disabled={isSending ? true : false}>
                Cancelar
              </Button>
              <Button 
                onClick={handleSendBudget} 
                color="primary"
                disabled={isSending ? true : false}
                >
                  {
                    isSending ?
                    "Enviando"
                    :
                    "Enviar"
                  }
              </Button>
            </DialogActions>
          </Dialog>
        </>
      }
    </div>
  );
}



let CardComponent =({dataCars})=>{
  const classes = useStyles();
  return( 
    <>
      {
        dataCars.map((car , i)=>(
          <DialogContent  className={classes.cardContent}key={i}>
            <Card className={classes.root}>
              {
                 car.photos.length > 0 ?
                  <CardMedia
                    className={classes.media}
                    image={`${process.env.REACT_APP_SERVER_ROOT_URL + '/images/' + car.photos[0].url}`}
                    title="car"
                  />
                :
                <div className={classes.notCarImage}>
                  Vehículo sin foto
                </div>
              }
                <CardContent>
                  <Typography 
                    gutterBottom 
                    variant="h2" 
                    component="h2"
                  >
                    {car.brand}
                  </Typography>
                  <Grid container spacing={2}>

                  <Grid item xs={4} zeroMinWidth>
                    <Typography component='h4' color='primary'>
                        {"Modelo"}
                      </Typography>
                      <Typography component='h3' className={classes.cardWeing}>
                        {car.model}
                      </Typography>
                    </Grid>

                    <Grid item xs={4} zeroMinWidth>
                    <Typography component='h4' color='primary'>
                        {"Kilómetros"}
                      </Typography>
                      <Typography component='h3' className={classes.cardWeing}>
                        {car.mileage}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} zeroMinWidth>
                    <Typography component='h4' color='primary'>
                        {"Año"}
                      </Typography>
                      <Typography component='h3' className={classes.cardWeing}>
                        {car.year}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} zeroMinWidth>
                    <Typography component='h4' color='primary'>
                        {"Transmisión"}
                      </Typography>
                      <Typography component='h2' className={classes.cardWeing}>
                         {car.transmissionType}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} zeroMinWidth>
                    <Typography component='h4' color='primary'>
                        {"Combustible"}
                      </Typography>
                      <Typography component='h2' className={classes.cardWeing}>
                        {car.fuelType}
                      </Typography>
                    </Grid>
                    <Grid item xs={4} zeroMinWidth>
                    <Typography component='h4' color='primary'>
                        {"Precio"}
                      </Typography>
                      <Typography component='h2' className={classes.cardWeing}>
                        <NumberFormat value={car.sellingPrice} displayType={'text'} thousandSeparator={true} prefix={'$'} fixedDecimalScale={true} decimalScale={'2'} />
                      </Typography>
                    </Grid>
                  </Grid>  
                </CardContent>
            </Card>
          </DialogContent>
        ))
      }
    </>
  )
}


