import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Login from "../../pages/login";
import ResetPassword from '../../pages/reset-password';

function LayoutPublicComponent(props) {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Route path="/reset-password" component={ResetPassword} />
    </Switch>
  );
}

export const LayoutPublic = withRouter(LayoutPublicComponent);
