import React, { useState } from "react";
import { TextField, FormLabel, FormGroup, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));
export function DatePickerFilter(props) {
  const classes = useStyles();
  const { filterList, onChange, index, column, options } = props;
  const [selectedValue, setSelectedValue] = useState(
    filterList[index] && filterList[index].length > 0
      ? filterList[index]
      : new Date(),
  );
  return (
    <div>
      <FormLabel>{column.name}</FormLabel>
      <FormGroup row>
        <TextField
          label="min"
          type="date"
          value={filterList[index][0] || ""}
          className={classes.textField}
          onChange={event => {
            setSelectedValue(event.target.value);
            filterList[index][0] = [event.target.value];
            onChange(filterList[index], index, column);
          }}
          InputLabelProps={{
            shrink: true,
          }}
          style={{ width: "100%" }}
        />
        <TextField
          label="max"
          type="date"
          value={filterList[index][1] || ""}
          className={classes.textField}
          onChange={event => {
            setSelectedValue(event.target.value);
            filterList[index][1] = [event.target.value];
            onChange(filterList[index], index, column);
          }}
          InputLabelProps={{
            shrink: true,
          }}
          style={{ width: "100%" }}
        />
      </FormGroup>
    </div>
  );
}
