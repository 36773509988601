import React from "react";
import {
  TextField,
  FormControl,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  Switch,
  Button,
  FormHelperText,
  CircularProgress,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  form: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
  },
  formControl: {
    margin: "5px 5px",
    flex: "1 1 45%",
    textAlign: "left",
  },
  buttonWrap: {
    flex: "1 1 100%",
    display: "flex",
    alignItems: "flex-end",
  },
  submitButton: {
    marginTop: "1rem",
  },
  loading: {
    marginLeft: "1rem",
  },
}));

export const CustomerForm = ({
  data,
  accounts,
  submitText,
  onSubmit,
  loading,
}) => {
  const classes = useStyles();
  const { register, handleSubmit, watch, errors, control } = useForm();

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
      <TextField
        className={classes.formControl}
        defaultValue={data.name}
        error={errors.name ? true : false}
        helperText={errors.name ? errors.name.message : ""}
        InputLabelProps={{ required: true }}
        inputRef={register({ required: "El nombre es requerido" })}
        label="Nombre y apellido"
        name="name"
        variant="outlined"
      />
      <TextField
        className={classes.formControl}
        defaultValue={data.phone}
        error={errors.phone ? true : false}
        helperText={errors.phone ? errors.phone.message : ""}
        InputLabelProps={{ required: true }}
        inputRef={register({ required: "El teléfono es requerido" })}
        label="Teléfono"
        name="phone"
        variant="outlined"
      />
      <TextField
        className={classes.formControl}
        defaultValue={data.email}
        error={errors.email ? true : false}
        helperText={errors.email ? errors.email.message : ""}
        InputLabelProps={{ required: true }}
        inputRef={register({
          required: "El email es requerido",
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Dirección de email inválida",
          },
        })}
        label="Email"
        name="email"
        variant="outlined"
      />

      <TextField
        className={classes.formControl}
        defaultValue={data.birthday}
        error={errors.birthday ? true : false}
        helperText={errors.birthday ? errors.birthday.message : ""}
        inputRef={register()}
        label="Fecha de nacimiento"
        type="date"
        InputLabelProps={{
          shrink: true,
        }}
        name="birthday"
        variant="outlined"
      />
      <TextField
        className={classes.formControl}
        defaultValue={data.preferredYear}
        inputRef={register({
          pattern: {
            value: /^((\d{4})|(\d{4})+(\s)*(-)+(\s)*(\d{4}))$/,
            message: "El año debe tener un formato de por ej. 2004 o un rango (2004-2010)",
          },
        })}
        error={errors.preferredYear ? true : false}
        placeholder="2004 o 2004-2010"
        helperText={errors.preferredYear ? errors.preferredYear.message : ""}
        label="Año de preferencia"
        name="preferredYear"
        variant="outlined"
      />
      <TextField
        className={classes.formControl}
        defaultValue={data.preferredPriceRange}
        inputRef={register({
          pattern: {
            value: /^((\d{1,})|(\d{1,})+(\s)*(-)+(\s)*(\d{1,}))$/,
            message: "Precio de preferencia, por ej. 500000 o un rango 500000-600000",
          },
        })}
        error={errors.preferredPriceRange ? true : false}
        placeholder="500000 o 500000-600000"
        helperText={errors.preferredPriceRange ? errors.preferredPriceRange.message : ""}
        label="Precio de preferencia"
        name="preferredPriceRange"
        variant="outlined"
      />
      <TextField
        className={classes.formControl}
        defaultValue={data.preferredCarType}
        inputRef={register}
        label="Tipo de vehículo preferido"
        multiline
        name="preferredCarType"
        rows={3}
        variant="outlined"
      />
      <TextField
        className={classes.formControl}
        defaultValue={data.comments}
        inputRef={register}
        label="Comentarios"
        multiline
        name="comments"
        rows={3}
        variant="outlined"
      />
      <div className={classes.buttonWrap}>
        <Button
          disabled={loading}
          className={classes.submitButton}
          variant="contained"
          color="primary"
          type="submit"
        >
          {submitText}
        </Button>
        {loading && <CircularProgress size={35} className={classes.loading} />}
      </div>
    </form>
  );
};
