import React from "react";
import { Grid } from "@material-ui/core";
import { Button } from "../../../../components/Wrappers/Wrappers";
import GoogleHeatMap from "../../../../components/GoogleHeatMap/GoogleHeatMap";
import { makeStyles } from '@material-ui/core/styles';
import ChartXYData from "../../components/ChartXYData";
import {
    ResponsiveContainer,
    ComposedChart,
    AreaChart,
    LineChart,
    Line,
    Area,
    PieChart,
    Pie,
    Cell,
    Series,
    YAxis,
    XAxis,
} from "recharts";
import Card from '@material-ui/core/Card';

import { gql } from '@apollo/client';

import Widget from "../../../../components/Widget/Widget";

import PageTitle from "../../../../components/PageTitle/PageTitle";
import TableDisplayData from "../../components/TableDisplayData";
import FullScreenDialog from "../../../../components/FullScreenDialog";
import useStyles from "../../../dashboard/styles";

import { getPricingHistoricalQuery } from "../../components/queries/reports";
import NumberFormat from "react-number-format";

export default function PriceProgression() {
    const [openFullScreen, setopenFullScreen] = React.useState(false);
    const [carSoldCoordinates, setCarSoldCoordinates] = React.useState({
        brandId: null,
        modelId: null,
        year: null,
        transmissiontype: null,
        fueltype: null
    });
    const [filtersDataTable, setfiltersDataTable] = React.useState();
    const columns = [
        {
            name: "Marca",
            options: {
                display: false,
                filter: true
            }
        },
        {
            name: "Modelo",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "Version",
            options: {
                filter: true,
                sort: true,
            }
        },
        { name: "Año" },
        {
            name: "Precio promedio vehículo",
            options: {
                sort: false,
                filter: false,
            },
        },
        {
            name: "Precio mínimo",
            options: {
                sort: false,
                filter: false,
            },
        },
        {
            name: "Precio máximo",
            options: {
                sort: false,
                filter: false,
            },
        },
        { name: "Transmisión" },
        { name: "Combustible" },
        { name: "Cantidad Vendidos", options: { filter: false } },
        { name: "Días hasta Venta", options: { filter: false } },
        { name: "Zona", options: { filter: true } },
        {
            name: "Capitalización de mercado",
            options: {
                filter: false,
            },
        },
        {
            name: "Mapa de ventas",
            options: {
                filter: false,
                display: true
            }
        }
    ];

    var captureFilters = data => {
        setfiltersDataTable(data);
    }

    const useStyles = makeStyles({
        subtitle: {
            margin: '23px',
        }
    });

    var classes = useStyles();

    var dataDisplayFunction = data => {
        return data.map(item => {
            return [
                item.brand.name,
                item.brand_model.name,
                item.version.name,
                item.year,
                <NumberFormat
                    value={item.price}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    fixedDecimalScale={true}
                    decimalScale={"2"}
                />,
                <NumberFormat
                    value={item.minPrice}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    fixedDecimalScale={true}
                    decimalScale={"2"}
                />,
                <NumberFormat
                    value={item.maxPrice}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    fixedDecimalScale={true}
                    decimalScale={"2"}
                />,
                item.transmissiontype,
                item.fueltype,
                item.amountSoldLastMonth || "No hay datos",
                item.daysToSell,
                item.address_state,
                item.price * item.amountSoldLastMonth ?
                    <NumberFormat
                        value={item.price * item.amountSoldLastMonth}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        fixedDecimalScale={true}
                        decimalScale={"2"}
                    /> : "No hay datos",
                <Button
                    color="success"
                    size="small"
                    className="px-2"
                    variant="contained"
                    onClick={() => {
                        setCarSoldCoordinates({
                            brandId: item.brand.id,
                            modelId: item.brand_model.id,
                            year: item.year,
                            transmissiontype: item.transmissiontype,
                            fueltype: item.fueltype
                        });
                        setopenFullScreen((prev) => !prev)
                    }
                    }
                >
                    Mostrar en Mapa
                </Button>
            ];
        });
    };

    const sortCol = {
        col: "totalSoldCars",
        direction: "desc",
    };

    const priceProgression = gql`
    query priceProgression($fromDate: DateTime, $toDate: DateTime, $brandId: Int, $modelId: Int, $versionId: Int, $addressState: String, $year: Int, $days: Int) {
        priceProgression (fromDate: $fromDate, toDate: $toDate, brandId: $brandId, modelId: $modelId, versionId: $versionId, addressState: $addressState, year: $year, days: $days){
        rows {
          totalSoldCars
          soldMonth
          soldYear
            brand {
            name
          }
        }
        totalCount
      }
    }
    `;

    return (
        <>
            <PageTitle title="Progresión semestral de precios por modelo" />
            <Grid container item xs={12} spacing={2} alignItems="center" justify="center" >
                <ChartXYData
                    brandmodel={true}
                    displayYear={true}
                    version={true}
                    address={true}
                    mdSize={12}
                    xsSize={12}
                    lastDays={365}
                    query={priceProgression}
                    queryField="priceProgression"
                    YLegend="Precio"
                    symbol="$"
                    title="Progresión de precios"
                />
            </Grid>
            <Grid container item xs={12}>
                <Grid item xs={12}>
                    &nbsp;
                </Grid>
            </Grid>
            <Card><h2 className={classes.subtitle}>Listado de vehículos vendidos durante los últimos 30 días</h2></Card>
            <Grid item xs={12}>
                <TableDisplayData
                    title="Vehículos vendidos por modelo"
                    columns={columns}
                    field="getPricingCarsHistorical"
                    query={getPricingHistoricalQuery}
                    dataFunction={dataDisplayFunction}
                    sortColumn={sortCol}
                    captureFilters={captureFilters}
                />
            </Grid>
            <FullScreenDialog isOpen={openFullScreen} dialogController={setopenFullScreen}><GoogleHeatMap center={{ lat: 37.775, lng: -122.434 }} zoom={12} columns={columns} filterList={filtersDataTable} carData={carSoldCoordinates} /></FullScreenDialog>
        </>
    );
}
