import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  Card,
  CardContent,
} from "@material-ui/core";
import { gql, useMutation, useApolloClient } from "@apollo/client";
import { SalesData, VehicleData, VehiclePhotos } from "./components";
import { useToastDispatch } from "../../context/ToastContext";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "1200px",
    margin: "0 auto",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  formWrap: {
    textAlign: "center",
  },
}));

const CREATE_CAR = gql`
  mutation createCar($input: CreateCarInput!) {
    createCar(input: $input) {
      brandId
      carModelId
      versionId
      year
      fuelType
      doors
      condtion
      mileage
      color
      address
      transmissionType
      controlTraccion
      engine
      carType
      firstOwner
      sellerType
      observations
      sellingPrice
      purchasedPrice
    }
  }
`;

function getSteps() {
  return ["Datos del vehículo", "Datos de venta", "Fotos del vehículo"];
}

export function VehicleCreate() {
  const [formState, setFormState] = useState({
    brandId: "",
    carModelId: "",
    year: "",
    versionId: "",
    fuelType: "",
    doors: "",
    condtion: "",
    mileage: "",
    color: "",
    address: "",
    transmissionType: "",
    controlTraccion: "",
    engine: "",
    carType: "",
    firstOwner: false,
    sellerType: "",
    observations: "",
    sellingPrice: "",
    purchasedPrice: "",
    photos: "",
    shareable: false,
    state: "",
  });
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const [createCar, { loading:mutationLoading, error }] = useMutation(CREATE_CAR);
  const toastDispatch = useToastDispatch();
  const client = useApolloClient();
  const history = useHistory();

  const handleSubmit = () => {
    createCar({
      variables: {
        input: {
          brandId: parseInt(formState.brandId),
          carModelId: parseInt(formState.carModelId),
          year: parseInt(formState.year),
          versionId: parseInt(formState.versionId),
          fuelType: formState.fuelType,
          doors: formState.doors,
          condtion: formState.condtion,
          mileage: parseInt(formState.mileage),
          color: formState.color,
          address: formState.address,
          transmissionType: formState.transmissionType,
          controlTraccion: formState.controlTraccion,
          engine: formState.engine,
          carType: formState.carType,
          firstOwner: formState.firstOwner,
          sellerType: formState.sellerType,
          observations: formState.observations,
          sellingPrice: parseFloat(formState.sellingPrice),
          purchasedPrice: parseFloat(formState.purchasedPrice),
          photos: formState.photos,
          shareable: formState.shareable,
          state: formState.state,
        },
      },
    }).then(() => {
      client.clearStore();
      toastDispatch({
        isOpen: true,
        type: "success",
        message: "El vehículo fue creado exitosamente",
      });
      setTimeout(() => history.push("/app/vehicles"));
    });
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  function handleStepperInput(input) {
    setFormState({ ...formState, ...input });
    setActiveStep(prevActiveStep => {
      if (prevActiveStep < 2) {
        return prevActiveStep + 1;
      } else {
        return prevActiveStep;
      }
    });
  }
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <VehicleData data={formState} setFields={setFormState} pushInput={handleStepperInput} />;
      case 1:
        return (
          <SalesData
            data={formState}
            pushInput={handleStepperInput}
            handleBack={handleBack}
          />
        );
      case 2:
        return (
          <VehiclePhotos
            data={formState}
            pushInput={handleStepperInput}
            handleBack={handleBack}
            loading={mutationLoading}
            handleSubmit={handleSubmit}
          />
        );
      default:
        return "Paso desconocido";
    }
  }
  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              {error ? error.message : "El vehículo fue creado exitosamente"}
            </Typography>
          </div>
        ) : (
          <Card>
            <CardContent className={classes.formWrap}>
              {getStepContent(activeStep)}
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  );
}
