import React from "react";
import { Map, HeatMap, GoogleApiWrapper } from "google-maps-react";
import { useQuery } from "@apollo/client";
import {
  getSoldCarsCoordinates
} from "../../pages/cars/components/queries/reports"
import { Grid } from "@material-ui/core";

const gradient = [
  "rgba(0, 255, 255, 0)",
  "rgba(0, 255, 255, 1)",
  "rgba(0, 191, 255, 1)",
  "rgba(0, 127, 255, 1)",
  "rgba(0, 63, 255, 1)",
  "rgba(0, 0, 255, 1)",
  "rgba(0, 0, 223, 1)",
  "rgba(0, 0, 191, 1)",
  "rgba(0, 0, 159, 1)",
  "rgba(0, 0, 127, 1)",
  "rgba(63, 0, 91, 1)",
  "rgba(127, 0, 63, 1)",
  "rgba(191, 0, 31, 1)",
  "rgba(255, 0, 0, 1)"
];
// var data = [
//   { lat: 37.751266, lng: -122.40335500000003 }
// ];

const googleAPI = "AIzaSyB1RyzaqXC7wzhxJv6XEF_MNysqB__etP8";

function GoogleHeatMap(props) {
  const { loading, error, data, fetchMore } = useQuery(getSoldCarsCoordinates, {
    variables: { 
      filter: props.filterList,
      brandId: props.carData.brandId,
      modelId: props.carData.modelId,
      year: props.carData.year,
      transmissiontype: props.carData.transmissiontype,
      fueltype: props.carData.fueltype
    },
    fetchPolicy: "no-cache",
  });
  if (loading) return <Grid container
  spacing={0}
  direction="column"
  alignItems="center"
  justify="center"
  style={{ minHeight: '100vh' }}>Cargando...</Grid>;
  if (data.getSoldCarsCoordinates.rows.length === 0) return <Grid container
  spacing={0}
  direction="column"
  alignItems="center"
  justify="center"
  style={{ minHeight: '100vh' }}>No hay suficientes datos datos de este modelo.Sugerimos que lo vuelva a intentar con una versión distinta del mismo modelo.</Grid>;
  return (
      <div className="map-container">
        <Map
          google={props.google}
          className={"map"}
          zoom={props.zoom}
          initialCenter={{lat: -34.6549, lng: -58.5536}}
        >
          <HeatMap
            gradient={gradient}
            positions={data.getSoldCarsCoordinates.rows}
            opacity={1}
            radius={10}
          />
        </Map>
      </div>
    );
}

export default GoogleApiWrapper({
  apiKey: googleAPI,
  libraries: ["visualization"]
})(GoogleHeatMap);