import React, { useState, useEffect } from "react";
import {
  Select,
  InputLabel,
  MenuItem,
  ListItemText,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery, gql } from "@apollo/client";
import { useForm, Controller, useWatch } from "react-hook-form";

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  form: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: "5px 5px",
    flex: "1 1 30%",
    textAlign: "left",
  },
  formControlSingle: {
    margin: "5px 5px",
    flex: "0 0 32.2%",
    textAlign: "left",
  },
  actionButtons: {
    flex: "1 1 100%",
    display: "flex",
    alignItems: "flex-end",
    marginTop: "1rem",
  },
}));

const GET_VERSIONS = gql`
  query getVersions($modelId: Int!) {
    getVersions(modelId: $modelId) {
      id
      name
      model_id
    }
  }
`;

export function VersionForm(props) {
  const { control, errors, data } = props;
  const modelId = useWatch({
    control,
    name: "modelId",
    defaultValue: data.modelId || 0,
  });

  const { loading, error, data: versionData, fetchMore } = useQuery(
    GET_VERSIONS,
    {
      variables: {
        modelId: modelId,
      },
    },
  );
  const classes = useStyles();

  if (loading) return "Cargando...";

  if (error) return `Error! ${error.message}`;
  const optionValues = modelId ? versionData.getVersions : [];
  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel>Versión</InputLabel>
      <Controller
        as={
          <Select error={errors.versionId ? true : false}>
            <MenuItem key="0" value=""></MenuItem>
            {optionValues.map((item, index) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        }
        control={control}
        defaultValue={data.versionId || ""}
        label="Versión"
        name="versionId"
      />
      <FormHelperText error={true}>
        {errors.versionId && errors.versionId.message}
      </FormHelperText>
    </FormControl>
  );
}
