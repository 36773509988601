import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

export const ConfirmDialog = ({ isOpen, handleClose }) => {
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"xs"}
      aria-labelledby="confirmation-dialog"
      open={isOpen}
      onClose={e => handleClose(false)}
    >
      <DialogTitle id="confirmation-dialog">Confirmar la inspección</DialogTitle>
      <DialogContent dividers>
        <p>El costo de la inspección se descontará de su saldo. ¿Desea continuar?</p>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={e => handleClose(false)} color="primary">
          Cancelar
        </Button>
        <Button onClick={e => handleClose(true)} color="primary">
          Inspeccionar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.prototype = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};
