import React, { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Breadcrumbs,
  Link,
  Typography,
} from "@material-ui/core";
import { Link as RouterLink, useParams } from "react-router-dom";
import { gql, useMutation, useQuery, useApolloClient } from "@apollo/client";
import { Clear as ClearIcon } from "@material-ui/icons";

import { useToastDispatch } from "../../../context/ToastContext";

const ALL_NOTIFICATIONS = gql`
  query allMessagesEndedAuction($destinataryId:ID!) {
    allMessagesEndedAuction(destinataryId:$destinataryId){
      id
      title
      body
      auctionId
      result 
      destinataryId
      createdAt
      view
      carId
    }
  }
`;

const EDIT_ENDED = gql`
  mutation deleteMessageEndedAuction($endedAuctionId: ID!) {
    deleteMessageEndedAuction(endedAuctionId: $endedAuctionId)
  }
`;
export const MessageView = () => {
  const client = useApolloClient();
  const { id } = useParams();
  const toastDispatch = useToastDispatch();

  const { loading: loadingQuery, error: errorQuery, data , refetch } = useQuery(
    ALL_NOTIFICATIONS,
    {
      variables: {
        destinataryId:id,
      },
    },
  );
  const [deleteMessageEndedAuction, {loading: mutationLoading}] = useMutation(EDIT_ENDED);
  
  const handleSubmit = value => {
    deleteMessageEndedAuction({ variables: { endedAuctionId: value } }).then((data) => {
      toastDispatch({
        isOpen: true,
        type: "success",
        message: "Dejarás de ver esta notificación",
      });
      client.clearStore().then(data=>{
        refetch();
      })
    })
  };

  if (loadingQuery) {
    return <p>Loading...</p>;
  }
  if (errorQuery) {
    return <p>Error</p>;
  }

 
  return (
    <Grid container spacing={4}>
      {
        data &&
        <Grid item xs={12} md={8}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" component={RouterLink} to="/app/dashboard">
            Inicio
          </Link>
          <Link
            color="textPrimary"
            component={RouterLink}
            to={`/app/allnotifications/${id}`}
            aria-current="page"
          >
            Notificaciones
          </Link>
        </Breadcrumbs>
        {
          data.allMessagesEndedAuction.length ===  0 &&
          <Card>
            <CardHeader  
              title={"Sin notificaciones"}
            />
          </Card>
        }
        {
          data.allMessagesEndedAuction.map(auction=>(
            <Card key={auction.id} style={{marginBottom:"1rem"}}>
              <CardHeader  
                style={{textAlign:"end"}} 
                title={<ClearIcon/>}
                onClick={()=>handleSubmit(auction.id) }
              />
                  <CardHeader title={`${new Date(auction.createdAt).toLocaleString()}`} />              <CardContent>
                  <Typography variant="h2">{auction.title}</Typography>
              </CardContent>
              <CardContent>
                <Typography variant="h6">{auction.body}</Typography>
                <Link 
                  component={RouterLink} 
                  to={`/app/vehicles/detailAuction/${auction.carId}`}
                  >
                  <Typography variant={"h6"}>
                    vehículo
                  </Typography>
                </Link>
              </CardContent>
            </Card>
          ))
        }
      </Grid>
      }
    </Grid>
  );
};
