import React from "react";
import { useQuery, gql } from "@apollo/client";
import ProfileHeader from "./components/ProfileHeader";
import ProfileBody from "./components/ProfileBody";

const PROFILE = gql`
  query Profile {
    profile {
      name
      email
      phone
      role
      comments
      smtpEmail
      smtpPassword
      smtpHost
      smtpPort
      smtpSubject
      emailTemplate
      account {
          name
      }
    }
  }
`;

export const Profile = () => {
  const { data, error, loading } = useQuery(PROFILE);
  if (loading) {
    return <p>Cargando...</p>;
  }
  if (error) {
    console.log(error)
    return <p>Error</p>;
  }
  const handleOnClickEdit = () => {
    
  }
  return (
    <div>
      <ProfileHeader handleClick={handleOnClickEdit} />
      <ProfileBody data={data.profile}/>
    </div>
  );
};
