import React from "react";

export default function CustomTooltip(props) {
    const wrapper = {
        padding: '15px',
        backgroundColor: 'rgba(255,255,255,0.5)'
    };
    if ((props.payload == null) || (typeof props.payload.length === 'undefined')) {
        return <></>;
    }

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    if (props.payload.length > 0) {
        return (
            <div style={wrapper}>
                <div>Mes: {props.label}</div>
                <div>{props.YLegend}: {props.symbol}{numberWithCommas(props.payload[0].value)}</div>
            </div>
        )
    }
    return <></>;
}