import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Breadcrumbs,
  Link,
} from "@material-ui/core";
import { Link as RouterLink, useParams, useHistory } from "react-router-dom";
import { gql, useMutation, useQuery, useApolloClient } from "@apollo/client";
import { CityRatesForm } from "../components";
import { useToastDispatch } from "../../../context/ToastContext";

const CITY_RATES = gql`
 query cityRates ($cityId:ID!){
    cityRates (cityId:$cityId){
      id
      city_name
      price
    }
  }
`;

const EDIT_CITY_RATES = gql`
  mutation editCityRates($input: InputEditCityRates!) {
    editCityRates(input: $input) 
  }
`;



export const CityRatesEdit = () => {
  const client = useApolloClient();
  const { id } = useParams();
  const toastDispatch = useToastDispatch();
  const history = useHistory();

  const {
    loading: loadingOrder,
    error: errorLoadingOrder,
    data
  } = useQuery(CITY_RATES, {
    variables: {
      cityId:id,
    },
  });


  const [editCityRates, { loading: mutationLoading }] = useMutation(EDIT_CITY_RATES);

  if (loadingOrder) {
    return <p>Cargando...</p>;
  }
  if (errorLoadingOrder) {
    return <p>Error</p>;
  }



  const handleSubmit = value => {

    const input = {
      id: id,
      price: parseInt(value.price),
      city_name: value.city_name,
    };

    editCityRates({ variables: { input } }).then(async () => {
      await client.clearStore();
      toastDispatch({
        isOpen: true,
        type: "success",
        message: "Ciudad editada correctamente",
      });
      history.push("/app/cityrates/");
    });

  };
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={8}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" component={RouterLink} to="/app/cityrates">
            Ciudad
          </Link>
          <Link
            color="textPrimary"
            component={RouterLink}
            to={`/app/cityrates/edit/${id}`}
            aria-current="page"
          >
            Editar Ciudad
          </Link>
        </Breadcrumbs>
        <Card>
          <CardHeader title="Editar Ciudad" />
          <CardContent>
            {data.cityRates ? (
              <CityRatesForm
                data={data.cityRates}
                loading={mutationLoading}
                submitText="Editar"
                onSubmit={handleSubmit}
              />
            ) : (
              <p>No Data Found</p>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
