import React, { useContext } from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useToastState, useToastDispatch } from "../../context/ToastContext";

export const Toasts = () => {
  const toastState = useToastState();
  const toastDispatch = useToastDispatch();
  const handleClose = event => {
    if (event === null) {
      toastDispatch({ isOpen: false, type: "success", message: "" });
    }
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={toastState.isOpen}
      onClose={handleClose}
      autoHideDuration={4000}
    >
      <MuiAlert elevation={6} variant="filled" severity={toastState.type}>
        {toastState.message}
      </MuiAlert>
    </Snackbar>
  );
};
