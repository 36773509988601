import React, { useState } from "react";
import { Grid} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { gql, useQuery } from '@apollo/client';
import BoxDataDisplay from "./components/BoxDataDisplay/BoxDataDisplay";
// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle";
import { Typography } from "../../components/Wrappers";
import MonthlyStats from "./components/MonthlyStats/MonthlyStats";
import MonthlySales from "./components/MonthlyStats/MonthlySales";

import ChartXYSales from "../../components/ChartXYSales/ChartXYSales";
import { getPublishedCarsMonthly, getRevenueMontly } from "../cars/components/queries/cars";
import DisplayTableCarOffers from "../cars/components/DisplayTableCarOffers/DisplayTableCarOffers";
import { GET_VEHICLES_LIST } from "../cars/components/queries/cars";
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import ChartXYSoldCarsData from "../../pages/cars/components/ChartXYSoldCarsData";

const ALL_CARS_AMOUNT_QUERY = gql`
  query getPublishLiveCars(
    $filter: String
  ) {
    getPublishLiveCars(filter: $filter) {
      totalCount
    }
  }
`;

const OFFER_CARS_AMOUNT_QUERY = gql`
  query getCarsOffers(
    $filter: String
  ) {
    getCarsOffers(
      filter: $filter
    ) {
      totalCount
    }
  }
`;

const RECENTLY_CARS_AMOUNT_QUERY = gql`
  query getRecentlyCars(
    $filter: String
  ) {
    getRecentlyCars(
      filter: $filter
    ) {
      totalCount
    }
  }
`;

const REDUCED_PRICE_CARS_AMOUNT_QUERY = gql`
  query getCarsReducedOriginalPrice (
    $filter: String
  ) {
    getCarsReducedOriginalPrice (
      filter: $filter
    ) {
      totalCount
    }
  }
`;

const getSoldCarsGroupByMonth = gql`
    query getSoldCarsGroupByMonth($fromDate: DateTime, $toDate: DateTime, $brandId: Int, $modelId: Int) {
      getSoldCarsGroupByMonth (fromDate: $fromDate, toDate: $toDate, brandId: $brandId, modelId: $modelId){
        rows {
          totalSoldCars
          soldMonth
          soldYear
            brand {
            name
          }
        }
        totalCount
        allSold
        lastMonth
      }
    }
    `;

const getSoldCarsGroupByCurrentMonth = gql`
    query getSoldCarsGroupByCurrentMonth($fromDate: DateTime, $toDate: DateTime, $brandId: Int, $modelId: Int) {
      getSoldCarsGroupByCurrentMonth (fromDate: $fromDate, toDate: $toDate, brandId: $brandId, modelId: $modelId){
        rows {
          totalSoldCars
          soldMonth
          soldYear
            brand {
            name
          }
        }
        totalCount
      }
    }
    `;

export default function Dashboard(props) {
  var classes = useStyles();
  var theme = useTheme();

  const { loading, error, data, refetch } = useQuery(GET_VEHICLES_LIST);
  const { loading: loadingAllCars, error: errorAllCars, data: dataAllCars } = useQuery(ALL_CARS_AMOUNT_QUERY,
    { variables: { "filter": "" } }
  );
  const { loading: loadingOfferCars, error: errorOfferCars, data: dataOfferCars } = useQuery(OFFER_CARS_AMOUNT_QUERY,
    { variables: { "filter": '{"Marca":[],"Modelo":[],"Año":{},"Version":[],"Kilometraje":{"gte":"500"},"Transmisión":[],"Combustible":[],"Moneda":[],"Precio":[],"Precio Promedio":[],"Ganancia":[],"Ganancia Porcentual":[],"Fecha publicación":[],"Teléfono":[],"Operación":[],"Provincia":[],"Ciudad":[],"Fecha de publicación":{},"Financiación":["No"]}' } }
  );
  const { loading: loadingRecentlyCars, error: errorRecentlyCars, data: dataRecentlyCars } = useQuery(RECENTLY_CARS_AMOUNT_QUERY,
    { variables: { "filter": "" } }
  );
  const { loading: loadingReducedPriceCars, error: errorReducedPriceCars, data: dataReducedPriceCars } = useQuery(REDUCED_PRICE_CARS_AMOUNT_QUERY,
    { variables: { "filter": "" } }
  );
  var date = new Date();
  if (loading || loadingAllCars || loadingOfferCars || loadingRecentlyCars || loadingReducedPriceCars) return "Cargando...";
  return (
    <>
      <PageTitle title="Panel de control del negocio" button="Estadísticas del negocio" />
      {typeof data !== 'undefined' && data.cars.nodes.length > 0 ?
        (<Grid container spacing={4}>
          <ChartXYSoldCarsData
            query={getSoldCarsGroupByMonth}
            queryField="getSoldCarsGroupByMonth"
            YLegend="Vehículos vendidos"
          />
          {/* <Grid item lg={6} md={4} sm={6} xs={12}>
            <MonthlyStats query={getPublishedCarsMonthly} queryField="getPublishedCarsMonthly" dateFrom={new Date(date.getFullYear(), date.getMonth(), 1)} />
          </Grid>
          <Grid item lg={6} md={4} sm={6} xs={12}>
            <MonthlySales query={getRevenueMontly} queryField="getRevenueMontly" displayMoneyFormat={true} dateFrom={new Date(date.getFullYear(), date.getMonth(), 1)} />
          </Grid>
          <Grid item xs={6}>
            <ChartXYSales query={getPublishedCarsMonthly} queryField="getPublishedCarsMonthly" title="Vehículos publicados y ventas" dataKeyX="month" dataKeyY="publishedCars" lineOneKey="publishedCars" lineOneLeyend="Vehículos publicados" lineTwoKey="soldCars" lineTwoLeyend="Vehículos vendidos" displayMoneyFormat={false} displayEmptyQuery="No se registran ventas en el mes en curso" />
          </Grid>
          <Grid item xs={6}>
            <ChartXYSales query={getRevenueMontly} queryField="getRevenueMontly" title="Compra y venta de vehículos (en pesos)" dataKeyX="month" dataKeyY="sellingPriceTotal" lineOneKey="sellingPriceTotal" lineOneLeyend="Venta de vehículos" lineTwoKey="purchasedPriceTotal" lineTwoLeyend="Costo de compra de vehículos" displayMoneyFormat={true} />
          </Grid> */}
        </Grid>) : (
          <Typography color="text" colorBrightness="secondary">
            Para poder recibir sugerencias sobre su negocio es necesario que ingrese los vehículos que tiene a la venta.
          </Typography>
        )}
      <Grid container spacing={4} style={{ paddingTop: '10px' }} >
        <BoxDataDisplay title="Vehículos en venta" amount={dataAllCars.getPublishLiveCars.totalCount} link="#/app/listing/cars" iconColor="blue" icon={<DriveEtaIcon fontSize="large" />} />
        <BoxDataDisplay title="Vehículos en oferta" amount={dataOfferCars.getCarsOffers.totalCount} link="#/app/listing/carBuyOpportunities" iconColor="#f3e260" icon={<DriveEtaIcon fontSize="large" />} />
        <BoxDataDisplay title="Vehículos recientes" amount={dataRecentlyCars.getRecentlyCars.totalCount} link="#/app/listing/carsRecentlyPublished" iconColor="#388e3c" icon={<DriveEtaIcon fontSize="large" />} />
        <BoxDataDisplay title="Vehículos precio reducido" amount={dataReducedPriceCars.getCarsReducedOriginalPrice.totalCount} link="#/app/listing/carsOriginalPriceDiscounted" iconColor="#00C0EF" icon={<DriveEtaIcon fontSize="large" />} />
      </Grid>
      <Grid container spacing={4} style={{ paddingTop: '10px' }} >
        <Grid item xs={12}>
          <DisplayTableCarOffers title="Top 10 de vehículos en oferta de esta semana" />
        </Grid>
      </Grid>
    </>
  );
}

