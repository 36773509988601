const createFilterJSON = (filters, columns) => {
    if (!filters) {
      return null;
    }
    let json = {};
    filters
      .filter((filter, key) => columns[key].name)
      .map((filter, key) => {
        const column = columns[key].name;
        if (
          ["Año", "Kilometraje", "Precio", "Fecha de publicación"].includes(
            column,
          )
        ) {
          json[column] = {};
          if (filter[0]) {
            json[column].gte = filter[0];
          }
          if (filter[1]) {
            json[column].lte = filter[1];
          }
        } else {
          json[column] = filter;
        }
      });
    return JSON.stringify(json);
  };
  export default createFilterJSON;