import React, { useState } from "react";
import {
  Select,
  InputLabel,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControl,
} from "@material-ui/core";
import { useQuery, gql } from "@apollo/client";
import { GET_BRANDS } from "../queries/reports";


export function BrandFilter(props) {
  const { filterList, onChange, index, column } = props;
  const { loading, error, data, fetchMore } = useQuery(GET_BRANDS);
  const [selectedValue, setSelectedValue] = useState(filterList[index] && filterList[index].length > 0 ? filterList[index]: "");
  if (loading) return "Cargando...";

  if (error) return `Error! ${error.message}`;

  const optionValues = data.getBrands.map(brand => brand.name);
  
  return (
    <FormControl>
      <InputLabel htmlFor="select-multiple-chip">Marca</InputLabel>
      <Select
        value={selectedValue}
        onChange={event => {
          setSelectedValue(event.target.value);
          filterList[index] = [event.target.value];
          onChange(filterList[index], index, column);
        }}
      >
        {optionValues.map((item, index) => (
          <MenuItem key={index} value={item}>
            <ListItemText primary={item} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
