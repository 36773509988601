import React, { useState, useEffect } from "react";
import {
  Select,
  InputLabel,
  MenuItem,
  ListItemText,
  FormControl,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery, gql } from "@apollo/client";
import { useForm, Controller } from "react-hook-form";

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  form: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: "5px 5px",
    flex: "1 1 30%",
    textAlign: "left",
  },
  formControlSingle: {
    margin: "5px 5px",
    flex: "0 0 32.2%",
    textAlign: "left",
  },
  actionButtons: {
    flex: "1 1 100%",
    display: "flex",
    alignItems: "flex-end",
    marginTop: "1rem",
  },
}));

const GET_BRANDS = gql`
  query getBrands {
    getBrands {
      id
      name
    }
  }
`;

export function BrandForm(props) {
  const { control, errors, data } = props;

  const { loading, error, data: brandData } = useQuery(GET_BRANDS);

  const classes = useStyles();

  if (loading) return "Cargando...";

  if (error) return `Error! ${error.message}`;
  const optionValues = brandData.getBrands;

  return (
    <FormControl variant="outlined" required className={classes.formControl}>
      <InputLabel>Marca</InputLabel>
      <Controller
        as={
          <Select error={errors.brandId ? true : false}
          >
            {optionValues.map(option => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        }
        control={control}
        defaultValue={data.brandId || ""}
        label="Marca"
        name="brandId"
        rules={{ required: "Debe definir la marca" }}
      />
      <FormHelperText error={true}>
        {errors.brandId && errors.brandId.message}
      </FormHelperText>
    </FormControl>
  );
}
