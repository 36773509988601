import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

import { useToastDispatch } from "../../context/ToastContext";

// styles
import useStyles from "./styles";

// logo
import logo from "./logo.svg";
import carzcloudLogo from "../../images/carzcloud.png";

const REQUEST_PASSWORD_RESET = gql`
  mutation requestPasswordReset($input: RequestPasswordResetInput!) {
    requestPasswordReset(input: $input)
  }
`;
const VERIFY_PASSWORD_RESET = gql`
  mutation verifyPasswordReset($input: VerifyPasswordResetInput!) {
    verifyPasswordReset(input: $input) {
      id
      email
    }
  }
`;
const RESET_PASSWORD = gql`
  mutation resetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`;

function ResetPassword(props) {
  var classes = useStyles();

  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  const toastDispatch  = useToastDispatch();
  const [
    requestPasswordReset,
    { error: requestPasswordResetError },
  ] = useMutation(REQUEST_PASSWORD_RESET);
  const [
    verifyPasswordReset,
    { error: verifyPasswordResetError },
  ] = useMutation(VERIFY_PASSWORD_RESET);
  const [resetPassword, { error: resetPasswordError }] = useMutation(
    RESET_PASSWORD,
  );
  const [requestSuccessMessage, setRequestSuccessMessage] = useState();
  const queryParams = new URLSearchParams(props.location.search);
  const token = queryParams.get("token");
  if (token && !loginValue) {
    verifyPasswordReset({
      variables: {
        input: {
          token,
        },
      },
    }).then(res => {
      setLoginValue(res.data.verifyPasswordReset.email);
    });
  }
  const onSubmit = () => {
    requestPasswordReset({
      variables: {
        input: {
          email: loginValue,
          callBackUrl: process.env.REACT_APP_WEB_URL + "/#/reset-password",
        },
      },
    }).then(res => {
      setError(null);
      setIsLoading(false);
      setRequestSuccessMessage("An email has been sent to your email with a reset link.")
    });
  };
  const onReset = () => {
    resetPassword({
      variables: {
        input: {
          email: loginValue,
          password: passwordValue,
        },
      },
    }).then(() => {
      toastDispatch({
        isOpen: true,
        type: "success",
        message: "Password updated, please login with new password",
      })
      props.history.push("/login");
    });
  };
  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
          <img src={carzcloudLogo} alt="CarzCloud logo" className={classes.logotypeImage} />
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <Typography variant="h2" className={classes.greeting}>
            Reiniciar contraseña
          </Typography>

          <Fade in={!!requestSuccessMessage}>
            <Typography className={classes.successMessage}>
              {requestSuccessMessage}
            </Typography>
          </Fade>
          <Fade
            in={
              !!requestPasswordResetError ||
              !!verifyPasswordResetError ||
              !!resetPasswordError
            }
          >
            <Typography color="secondary" className={classes.errorMessage}>
              {requestPasswordResetError
                ? requestPasswordResetError.message
                : verifyPasswordResetError
                ? verifyPasswordResetError.message
                : resetPasswordError
                ? resetPasswordError.message
                : "Something is wrong with your email"}
            </Typography>
          </Fade>
          <TextField
            id="email"
            InputProps={{
              classes: {
                underline: classes.textFieldUnderline,
                input: classes.textField,
              },
            }}
            disabled={!!token}
            value={loginValue}
            onChange={e => setLoginValue(e.target.value)}
            margin="normal"
            placeholder="Dirección de email"
            type="email"
            fullWidth
          />
          {token ? (
            <TextField
              id="password"
              onChange={e => setPasswordValue(e.target.value)}
              margin="normal"
              placeholder="Contraseña"
              type="password"
              fullWidth
            />
          ) : (
            ""
          )}
          <div className={classes.formButtons}>
            {isLoading ? (
              <CircularProgress size={26} className={classes.loginLoader} />
            ) : token ? (
              <Button
                disabled={loginValue.length === 0}
                onClick={onReset}
                variant="contained"
                color="primary"
                size="large"
              >
                Reset
              </Button>
            ) : (
              <Button
                disabled={loginValue.length === 0}
                onClick={onSubmit}
                variant="contained"
                color="primary"
                size="large"
              >
                Reiniciar
              </Button>
            )}
            <Button
              color="primary"
              size="large"
              className={classes.forgetButton}
              component={RouterLink}
              to="login"
            >
              Iniciar sesión
            </Button>
          </div>
        </div>
        <Typography color="primary" className={classes.copyright}>
        © 2021 carzcloud.com. Todos los derechos reservados.
        </Typography>
      </div>
    </Grid>
  );
}

export { ResetPassword };
