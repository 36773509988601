import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Button } from "../../../../components/Wrappers/Wrappers";
import GoogleHeatMap from "../../../../components/GoogleHeatMap/GoogleHeatMap";
import * as moment from 'moment'
import DisplayPhoneorWhatsApp from "../../components/DisplayPhoneorWhatsApp";
import PageviewIcon from '@material-ui/icons/Pageview';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { Link } from '@material-ui/core';
import useStyles from "../../../typography/styles";
import DisplayDetailsLinkButton from '../../../../components/DisplayDetailsLinkButton/DisplayDetailsLinkButton';

import {
  ResponsiveContainer,
  ComposedChart,
  AreaChart,
  LineChart,
  Line,
  Area,
  PieChart,
  Pie,
  Cell,
  Series,
  YAxis,
  XAxis,
} from "recharts";

import PageTitle from "../../../../components/PageTitle/PageTitle";
import TableDisplayData from "../../components/TableDisplayData";

import {
  getCarsOriginalPriceDiscounted
} from "../../components/queries/reports";
import NumberFormat from "react-number-format";

export default function CarsOriginalPriceDiscounted() {
  var classes = useStyles();
  const [openFullScreen, setopenFullScreen] = React.useState(false);
  const [filtersDataTable, setfiltersDataTable] = React.useState();
  const columns = [
    {
      name: "Marca",
      options: {
        display: false,
        filter: true
      }
    },
    {
      name: "Modelo",
      options: {
        filter: true,
        sort: true
      }
    },
    { name: "Año" },
    {
      name: "Version",
      options: {
        filter: true,
        sort: true,
        display: false
      }
    },
    {
      name: "Kilometraje",
      options: {
        sort: true,
        filter: true,
      },
    },
    { name: "Transmisión", 
      options: { filter: true, display: false}
    },
    { name: "Combustible", 
      options: { filter: true, display: false} 
    },
    {
      name: "Moneda",
      options: {
        sort: false,
        filter: false,
        display: false
      },
    },
    {
      name: "Precio Promedio",
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      name: "Precio Actual ($)",
      options: {
        sort: true,
        filter: true,
      },
    },
    {
      name: "Precio Anterior ($)",
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      name: "Diferencia",
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      name: "Precio Mínimo",
      options: {
        sort: true,
        filter: false,
        display: false
      },
    },
    {
      name: "Precio Máximo",
      options: {
        sort: true,
        filter: false,
        display: false
      },
    },
    {
      name: "Fecha publicación",
      options: {
        filter: false,
        display: true
      }
    },
    {
      name: "Teléfono",
      options: {
        sort: false,
        filter: false,
      },
    },
    {
      name: "Provincia",
      options: {
        sort: false,
        filter: true,
        display: false
      }
    },
    {
      name: "Ciudad",
      options: {
        sort: false,
        filter: true,
        display: false
      }
    },
    {
      name: "Financiación",
      options: {
        filter: true,
        display: false,
        download: false
      }
    },
    {
      name: "Posee Teléfono",
      options: {
        filter: true,
        display: false,
        download: false,
        sort: false,
        viewColumns: false
      }
    },
    {
      name: "Vendedor",
      options: {
        filter: true,
        display: false,
        download: false,
        sort: false
      }
    },
    {
      name: "Operación",
      options: {
        sort: false,
        filter: false,
        download: false
      },
    },
    {
      name: "Fecha de publicación",
      options: {
        filter: true,
        display: false,
        download: false,
        sort: false,
        viewColumns: false
      }
    }
  ];

  var captureFilters = data => {
    setfiltersDataTable(data);
  }
  var dataDisplayFunction = data => {
    return data.map(item => {
      return [
        item.brand.name,
        item.brand_model.name,
        item.year,
        item.version.name,
        item.mileage,
        item.transmissiontype,
        item.fueltype,
        item.currency_code,
        <NumberFormat
          value={item.carAvgPrice.price}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
          fixedDecimalScale={true}
          decimalScale={"2"}
        />,
        <NumberFormat
          value={item.search_price}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
          fixedDecimalScale={true}
          decimalScale={"2"}
        />,
        <NumberFormat
          value={item.previous_price}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
          fixedDecimalScale={true}
          decimalScale={"2"}
        />,
        <NumberFormat
          value={item.priceDifference}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
          fixedDecimalScale={true}
          decimalScale={"2"}
        />,
        item.carAvgPrice.minPrice ?
          <NumberFormat
            value={item.carAvgPrice.minPrice}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            fixedDecimalScale={true}
            decimalScale={"2"}
          /> : "No hay datos",
        item.carAvgPrice.maxPrice ?
          <NumberFormat
            value={item.carAvgPrice.maxPrice}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            fixedDecimalScale={true}
            decimalScale={"2"}
          /> : "No hay datos",
        moment(item.created_at).format('DD-MM-YYYY'),
        <DisplayPhoneorWhatsApp phone={item.phone} link={item.link} />,
        item.address_state,
        item.address_city,
        '',
        '',
        item.tipovendedor,
        <>
          <DisplayDetailsLinkButton id={item.id} url={process.env.REACT_APP_WEB_URL + "#/app/vehicles/detail/" + item.id} />
        </>
      ];
    });
  };
  const sortCol = {
    col: "created_at",
    direction: "desc",
  };
  return (
    <>
      <PageTitle title="Vehículos a la venta con el precio reducido" />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant='h4'>
            Información sobre el reporte
          </Typography>
          <Typography style={{ "paddingTop": "20px" }} variant='subtitle1'>
            {"Vehículos publicados en los últimos 90 días a los cuales el vendedor les ha reducido el precio original de venta. Esto representa una oportunidad ya que muy probablemente el comprador puede negociar un mejor precio de adquisición."}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableDisplayData
            title="Vehículos a la venta a los cuales se les ha reducido el precio"
            columns={columns}
            field="getCarsReducedOriginalPrice"
            query={getCarsOriginalPriceDiscounted}
            dataFunction={dataDisplayFunction}
            sortColumn={sortCol}
            captureFilters={captureFilters}
          />
        </Grid>
      </Grid>
    </>
  );
}
