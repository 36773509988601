import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {
  BrandFilter,
  BrandModelFilter,
  VersionFilter,
  RangeFilter,
  SingleSelectFilter,
  DatePickerFilter,
} from "./filters";
import { ROWS_PER_PAGE } from "../../../themes/globalvariables";
import { useQuery } from "@apollo/client";
import createFilterJSON from './filters/createFilterJSON';
import options from "./options";
import translation from "../../translations/translations_muidatatable";
import EventTriggeredAnalytics from '../../../components/Analytics/EventTriggeredAnalytics';

function renderValue(val) {
  if (typeof val === 'object') {
    if (typeof val.props.phone !== 'undefined') {
      return val.props.phone;
    } else {
      if ((typeof val.props.prefix !== 'undefined') && (val.props.value)) {
        return val.props.prefix + ' ' + val.props.value;
      } else {
        return val.props.value;
      }
    }
  } else {
    return val;
  }
}

function buildCustomBodyDownload(columns, data) {
  if (!data.length) return '';
  return data
    .reduce(
      (soFar, row) =>
        soFar +
        '"' +
        row.data
          .filter((_, index) => columns[index].download)
          .map(columnData => renderValue(columnData))
          .join('"' + "," + '"') +
        '"\r\n',
      '',
    )
    .trim();
}

export default function TableDisplayData(props) {
  const { title, columns, query, field, dataFunction } = props;
  const [state, setState] = useState({
    page: 0,
    rowsPerPage: ROWS_PER_PAGE,
    fromDate: null,
    toDate: null,
    orderColName: props.sortColumn.col,
    orderDirection:
      props.sortColumn
        .direction /*The initial order has to be set up in the Apollo server query*/,
    filterList: null,
  });

  const handleFilterSubmit = applyFilters => {
    const filterList = applyFilters();
    setState(prevState => ({
      ...prevState,
      filterList: filterList,
    }));
    EventTriggeredAnalytics('Search', 'Filter applied');
  };
  const [carModelId, setCarModelId] = useState(null); //Assigned to retrieve the Car Versions via ID

  var jsonFilters = "";
  if (state.filterList) {
    jsonFilters = createFilterJSON(state.filterList, columns);
  }
  const { loading, error, data, fetchMore } = useQuery(query, {
    variables: { ...state, filter: jsonFilters },
    fetchPolicy: "no-cache",
  });
  useEffect(() => {
    props.captureFilters(jsonFilters);
  }, [jsonFilters]);

  if (loading) return "Cargando...";
  if (error) return `Error! ${error.message}`;
  const totalCount = data[field].totalCount; //Total rows count

  const dataTableOptions = {
    rowsPerPage: ROWS_PER_PAGE,
    page: state.page,
    useDisplayedColumnsOnly: true,
    filterType: "dropdown",
    confirmFilters: true,
    rowsPerPageOptions: [15, 25, 30],
    count: totalCount,
    responsive: "standard",
    serverSide: true,
    filter: true,
    sort: true,
    jumpToPage: true,
    pagination: true,
    search: false,
    textLabels: translation['textLabels'],
    sortOrder: {
      name: state.orderColName,
      direction: state.orderColDirection,
    },
    customToolbar: () => {
      return (
        <IconButton>{totalCount}</IconButton>
      );
    },
    onColumnSortChange: (changedColumn, direction) => {
      setState(prevState => ({
        ...prevState,
        orderColName: changedColumn,
        orderDirection: direction,
        page: 0,
        orderColDirection: direction
      }));
    },
    onChangePage: newPage => {
      setState(prevState => ({
        ...prevState,
        page: newPage,
      }));
    },
    onFilterChange: (column, filterList, type) => {
      const applyFilters = () => filterList;
      handleFilterSubmit(applyFilters);
    },
    // Calling the applyNewFilters parameter applies the selected filters to the table
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div style={{ marginTop: "40px" }}>
          <Button
            variant="contained"
            onClick={() => handleFilterSubmit(applyNewFilters)}
          >
            Aplicar Filtros
          </Button>
        </div>
      );
    },
    onDownload: (buildHead, buildBody, columns, data) =>
      // console.log(data)
      buildHead(columns)
      +
      buildCustomBodyDownload(columns, data)
    // buildBody(
    //   data.concat({
    //     index: data.length,
    //   }),
    // )
  };
  columns.forEach((column, columnPos) => {
    switch (column.name) {
      case "Marca":
        column.options = {
          ...column.options,
          filterType: "custom",
          filterList:
            state.filterList && state.filterList[columnPos].length
              ? state.filterList[columnPos]
              : null,
          customFilterListOptions: {
            render: v => column.name + ": " + v,
            update: (filterList, filterPos, index) => {
              filterList[index].splice(filterPos, 1);
              return filterList;
            },
          },
          filterOptions: {
            logic: (location, filters, row) => {
              if (filters.length) return !filters.includes(location);
              return false;
            },
            display: (filterList, onChange, index, column) => {
              return (
                <BrandFilter
                  filterList={filterList}
                  onChange={onChange}
                  index={index}
                  column={column}
                />
              );
            },
          },
        };
        break;
      case "Modelo":
        column.options = {
          ...column.options,
          filterType: "custom",
          filterList:
            state.filterList && state.filterList[columnPos].length
              ? state.filterList[columnPos]
              : null,
          customFilterListOptions: {
            render: v => column.name + ": " + v,
            update: (filterList, filterPos, index) => {
              filterList[index].splice(filterPos, 1);
              return filterList;
            },
          },
          filterOptions: {
            logic: (location, filters, row) => {
              if (filters.length) return !filters.includes(location);
              return false;
            },
            display: (filterList, onChange, index, column) => {
              const brandIndex = columns.findIndex(c => c.name === "Marca");
              const brandName = filterList[brandIndex].length
                ? filterList[brandIndex][0]
                : null;
              return (
                <BrandModelFilter
                  filterList={filterList}
                  onChange={onChange}
                  index={index}
                  column={column}
                  brandName={brandName} 
                  assignCarModelId={setCarModelId}
                />
              );
            },
          },
        };
        break;
      case "Version":
        column.options = {
          ...column.options,
          filterType: "custom",
          filterList:
            state.filterList && state.filterList[columnPos].length
              ? state.filterList[columnPos]
              : null,
          customFilterListOptions: {
            render: v => column.name + ": " + v,
            update: (filterList, filterPos, index) => {
              filterList[index].splice(filterPos, 1);
              return filterList;
            },
          },
          filterOptions: {
            logic: (location, filters, row) => {
              if (filters.length) return !filters.includes(location);
              return false;
            },
            display: (filterList, onChange, index, column) => {
              //const modeloIndex = columns.findIndex(c => c.name == "Modelo");
              const modeloId = filterList[1].length
                ? filterList[1][0]
                : null;
              return (
                <VersionFilter
                  filterList={filterList}
                  onChange={onChange}
                  index={index}
                  column={column}
                  carModelId={carModelId}
                />
              );
            },
          },
        };
        break;
      case "Año":
      case "Kilometraje":
      case "Precio":
      case "Fecha de publicación":
        column.options = {
          ...column.options,
          filterType: "custom",
          filterList:
            state.filterList && state.filterList[columnPos].length
              ? state.filterList[columnPos]
              : null,
          customFilterListOptions: {
            render: v => {
              if (v[0] && v[1]) {
                return [
                  `Min ${column.name}: ${v[0]}`,
                  `Max ${column.name}: ${v[1]}`,
                ];
              }
              if (v[0]) {
                return [`Min ${column.name}: ${v[0]}`];
              }
              return [`Max ${column.name}: ${v[1]}`];
            },
            update: (filterList, filterPos, index) => {
              console.log(
                "customFilterListOnDelete: ",
                filterList,
                filterPos,
                index,
              );

              if (filterPos === 0) {
                filterList[index].splice(filterPos, 1);
              } else if (filterPos === 1) {
                filterList[index].splice(filterPos, 1);
              } else if (filterPos === -1) {
                filterList[index] = [];
              }

              return filterList;
            },
          },
          filterOptions: {
            names: [],
            logic(value, filters) {
              if (filters[0] && filters[1]) {
                return value < filters[0] || value > filters[1];
              } else if (filters[0]) {
                return value < filters[0];
              } else if (filters[1]) {
                return value > filters[1];
              }
              return false;
            },
            display: (filterList, onChange, index, column) =>
              column.name != "Fecha de publicación" ? (
                <RangeFilter
                  filterList={filterList}
                  onChange={onChange}
                  index={index}
                  column={column}
                />
              ) : (
                <DatePickerFilter
                  filterList={filterList}
                  onChange={onChange}
                  index={index}
                  column={column}
                  options={options[column.name] || []}
                />
              ),
          },
          print: false,
        };
        break;
      case "Transmisión":
      case "Combustible":
      case "Condición":
      case "Tipo de vendedor":
      case "Listing Type":
      case "Provincia":
      case "Ciudad":
        column.options = {
          ...column.options,
          filterType: "custom",
          filterList:
            state.filterList && state.filterList[columnPos].length
              ? state.filterList[columnPos]
              : null,
          customFilterListOptions: {
            render: v => column.name + ": " + v,
            update: (filterList, filterPos, index) => {
              filterList[index].splice(filterPos, 1);
              return filterList;
            },
          },
          filterOptions: {
            logic: (location, filters, row) => {
              if (filters.length) return !filters.includes(location);
              return false;
            },
            display: (filterList, onChange, index, column) => {
              let fOptions = [];
              if (column.name == "Ciudad") {
                const provinceIndex = columns.findIndex(
                  c => c.name == "Provincia",
                );
                if (
                  provinceIndex >= 0 &&
                  filterList[provinceIndex] &&
                  filterList[provinceIndex].length > 0
                ) {
                  fOptions =
                    options[column.name][filterList[provinceIndex][0]] || [];
                }
              } else {
                fOptions = options[column.name] || [];
              }
              return (
                <SingleSelectFilter
                  filterList={filterList}
                  onChange={onChange}
                  index={index}
                  column={column}
                  options={fOptions}
                />
              );
            },
          },
        };
        break;
      case "Financiación":
        column.options = {
          ...column.options,
          filterType: "custom",
          filterList:
            state.filterList && state.filterList[columnPos].length
              ? state.filterList[columnPos]
              : null,
          customFilterListOptions: {
            render: v => column.name + ": " + v,
            update: (filterList, filterPos, index) => {
              filterList[index].splice(filterPos, 1);
              return filterList;
            },
          },
          filterOptions: {
            logic: (location, filters, row) => {
              if (filters.length) return !filters.includes(location);
              return false;
            },
            display: (filterList, onChange, index, column) => {
              let fOptions = ["Si", "No"];
              return (
                <SingleSelectFilter
                  filterList={filterList}
                  onChange={onChange}
                  index={index}
                  column={column}
                  options={fOptions}
                />
              );
            },
          },
        };
        break;
      case "Posee Teléfono":
        column.options = {
          ...column.options,
          filterType: "custom",
          filterList:
            state.filterList && state.filterList[columnPos].length
              ? state.filterList[columnPos]
              : null,
          customFilterListOptions: {
            render: v => column.name + ": " + v,
            update: (filterList, filterPos, index) => {
              filterList[index].splice(filterPos, 1);
              return filterList;
            },
          },
          filterOptions: {
            logic: (location, filters, row) => {
              if (filters.length) return !filters.includes(location);
              return false;
            },
            display: (filterList, onChange, index, column) => {
              let fOptions = ["Si", "No"];
              return (
                <SingleSelectFilter
                  filterList={filterList}
                  onChange={onChange}
                  index={index}
                  column={column}
                  options={fOptions}
                />
              );
            },
          },
        };
        break;
      case "Vendedor":
        column.options = {
          ...column.options,
          filterType: "custom",
          filterList:
            state.filterList && state.filterList[columnPos].length
              ? state.filterList[columnPos]
              : null,
          customFilterListOptions: {
            render: v => column.name + ": " + v,
            update: (filterList, filterPos, index) => {
              filterList[index].splice(filterPos, 1);
              return filterList;
            },
          },
          filterOptions: {
            logic: (location, filters, row) => {
              if (filters.length) return !filters.includes(location);
              return false;
            },
            display: (filterList, onChange, index, column) => {
              let fOptions = ["Concesionaria", "Particular"];
              return (
                <SingleSelectFilter
                  filterList={filterList}
                  onChange={onChange}
                  index={index}
                  column={column}
                  options={fOptions}
                />
              );
            },
          },
        };
        break
      default:
    }
    return column;
  });
  return (
    <>
      <MUIDataTable
        title={title}
        data={dataFunction(data[field].rows)}
        columns={columns}
        options={dataTableOptions}
      />
    </>
  );
}
