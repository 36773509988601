import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Breadcrumbs,
  Link,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { gql, useMutation, useQuery, useApolloClient } from "@apollo/client";
import { ProfileForm } from "./components";
import { useToastDispatch } from "../../../context/ToastContext";

const PROFILE = gql`
  query Profile {
    profile {
      name
      email
      phone
      role
      comments
      smtpEmail
      smtpPassword
      smtpHost
      smtpPort
      smtpSubject
      emailTemplate
      account {
        name
      }
    }
  }
`;

const EDIT_PROFILE = gql`
  mutation UpdateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      id
    }
  }
`;

export const ProfileEdit = () => {
  const client = useApolloClient();
  const toastDispatch = useToastDispatch();

  const { loading: loadingQuery, error: errorQuery, data , refetch } = useQuery(PROFILE);
  const [updateAccount, {loading: mutationLoading}] = useMutation(EDIT_PROFILE);

  if (loadingQuery) {
    return <p>Loading...</p>;
  }
  if (errorQuery) {
    return <p>Error</p>;
  }

  const handleSubmit = value => {
    value = { ...value };
    updateAccount({ variables: { input: value } }).then(() => {
      client.clearStore();
      toastDispatch({
        isOpen: true,
        type: "success",
        message: "El perfil fue actualizado correctamente",
      });
      refetch();
    });
  };
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={8}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" component={RouterLink} to="/app/profile">
            Perfil
          </Link>
          <Link
            color="textPrimary"
            component={RouterLink}
            to="/app/profile/edit"
            aria-current="page"
          >
            Editar Cuenta
          </Link>
        </Breadcrumbs>
        <Card>
          <CardHeader title="Editar Perfil" />
          <CardContent>
            {data.profile ? (
              <ProfileForm
                data={data.profile}
                loading={mutationLoading}
                submitText="Editar"
                onSubmit={handleSubmit}
              />
            ) : (
              <p>No hay información</p>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
