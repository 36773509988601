import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Link,
  Typography,
  Button,
} from "@material-ui/core";

import { Link as RouterLink, useHistory } from "react-router-dom";
import { gql, useMutation, useApolloClient, useQuery } from "@apollo/client";
import { useToastDispatch } from "../../../context/ToastContext";
import  BudgetCarTable  from '../BudgetCarTable';
import { makeStyles } from '@material-ui/core/styles';
import * as moment from 'moment';

const GET_ONE_BUDGET_CUSTOMER = gql`
  query getOneBudgetCustomer($id: ID!) {
    getOneBudgetCustomer(id: $id) {
      id
      name
      phone
      email
      preferredCarType
      preferredYear
      preferredPriceRange
      birthday
      comments
    }
  }
`;

const PREVIEW_BUDGET_CAR = gql`
  mutation previewBudgetCar($input: PreviewBudgetCarInput) {
    previewBudgetCar(input: $input){
      id
      brand
      model
      year
      color
      sellingPrice
      mileage
      fuelType
      transmissionType
      photos{
        url
      }
    }
  }
`;

const useStyles = makeStyles({
  root: {
    minWidth: '49%',
    margin:"3px",
  },
  pagetitle:{
    textAlign:"center",
    marginBottom:"2rem"
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  }, 
  text: {
    marginBottom: ".5rem",
  },
    sendContainerButton :{
      height:"50px",
      backgroundColor:"white",
      position:"fixed",
      top:'92%',
      left:'47%',
      zIndex:500,
      marginTop:"20px",
      margin:"0% auto",
 
   },
   sendButton:{
    margin:"0% auto"
   }
});

const formatPriceRange = (priceRange) => {
 var prices = priceRange.split("-");
 const price1 = new Intl.NumberFormat('us-US', { style: 'currency', currency: 'USD' }).format(prices[0]);
 const price2 = new Intl.NumberFormat('us-US', { style: 'currency', currency: 'USD' }).format(prices[1]);
 return price1 + ' - ' + price2;
}

export const BudgetCarPage = () => {
  const classes = useStyles();
  const client = useApolloClient();
  const toastDispatch = useToastDispatch();
  const history = useHistory();

  const [ isPreview , setIsPreview ] = useState(false);
  const [ previewBudgetCar ] = useMutation(PREVIEW_BUDGET_CAR);
  const [dataPreview , setDataPreview ] = useState();
  const [idCar , setBudgetIdCar] = useState([]);
  const [userId , setUser ] = useState(history.location.pathname.split('/')[4])
  const {
    loading,
    error,
    data,
  } = useQuery(GET_ONE_BUDGET_CUSTOMER, {
    variables: {
      id:userId
    },
  });

  if (loading) {
    return <p>Cargando...</p>;
  }

  if (error) {
    return <p>Error</p>;
  }
  //All data
let {
  id,
  name,
  phone,
  email,
  preferredCarType,
  preferredYear,
  preferredPriceRange,
  birthday,
  comments,
  } = data.getOneBudgetCustomer;

  //customer data
  let data_customer={
    id,
    name,
    phone,
    email,
    birthday:birthday ? moment(birthday).format('DD-MM-YYYY'):"No definido",
  }
  //preferrer data
  let data_preferrer = {
    preferredCarType,
    preferredYear,
    preferredPriceRange,
    comments,
  }

    //handle preview
    const handlePreviewData =()=>{
      let input ={
        clientId:userId,
        body:idCar
      }
  
      previewBudgetCar({ variables: { input } })
      .then(async (response) => {
        
        setIsPreview(true)
        setDataPreview(response.data)
      });
    }
  

  return (
    <Grid container spacing={4}>
      <Grid item md={12}>
        <Typography 
          variant="h1" 
          className={classes.pagetitle}
        >
          {"Envio de Presupuestos"}
        </Typography>
        <Link 
          to='/app/budget' 
          component={RouterLink}
        >
          <Typography variant="h5">
            {"Cambiar Cliente"}
          </Typography>
        </Link>
      </Grid>

      {/* customer and preferrer data components */}
        <CustomerCard 
          data_customer={data_customer}
        />
        
        <PreferrerCard 
          data_preferrer={data_preferrer}
        />
      {/* ----------------------------- */}

       <Grid item md={12}>
        <Typography 
          variant="h2" 
          className={classes.pagetitle} 
        >
          {"Vehículos Disponibles"}
        </Typography>
        <BudgetCarTable 
          setBudgetIdCar={setBudgetIdCar} 
          setIsPreview={setIsPreview}  
          dataPreview={dataPreview} 
          isPreview={isPreview} 
        />
        {/* send budget component */}
      {
        idCar.length > 0 &&
        <Grid item xl={10} className={classes.sendContainerButton}>
          <Button
            size="medium"
            variant="contained" 
            color="primary"
            onClick={handlePreviewData}
            className={classes.sendButton}
          >
            Enviar presupuesto
          </Button>
        </Grid>
      }
      </Grid>
      </Grid>
    );  
  };

//customerCard
function CustomerCard({data_customer}) {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardHeader title="Cliente" />
      <CardContent>
        <Typography color='primary' variant='h5' >
          {'Nombre y apellido'}
        </Typography>
        <Typography variant='h3' className={classes.text}>
          {data_customer.name}
        </Typography>
        <Typography color='primary' variant='h5' >
          {'Teléfono'}
        </Typography>
        <Typography variant='h3' className={classes.text}>
          {data_customer.phone}
        </Typography>
        <Typography color='primary' variant='h5' >
          {'Email'}
        </Typography>
        <Typography variant='h3' className={classes.text}>
           {data_customer.email}
        </Typography>
        <Typography color='primary' variant='h5' >
          {'Cumpleaños'}
        </Typography>
        <Typography variant='h3' className={classes.text}>
          {data_customer.birthday}
        </Typography>
      </CardContent>
    </Card>
  );
}

//preferrercard
function PreferrerCard({data_preferrer}) {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardHeader title="Vehículo buscado" />
      <CardContent>
      <Typography color='primary' variant='h5' >
          {'Tipo de vehiculo'}
        </Typography>
        <Typography variant='h3' className={classes.text}>
          {data_preferrer.preferredCarType ? data_preferrer.preferredCarType : "No definido"} 
        </Typography>
        <Typography color='primary' variant='h5' >
          {'Año de preferencia'}
        </Typography>
        <Typography variant='h3' className={classes.text}>
          {data_preferrer.preferredYear ? data_preferrer.preferredYear : "No definido"}
        </Typography>
        <Typography color='primary' variant='h5' >
          {'Rango de precio'}
        </Typography>
        <Typography variant='h3' className={classes.text}>
        {data_preferrer.preferredPriceRange ? formatPriceRange(data_preferrer.preferredPriceRange) : "No definido"}
        </Typography>
        {
          data_preferrer.comments &&
          <>
            <Typography color='primary' variant='h5' >
              {'Comentarios'}
            </Typography>
            <Typography variant='h3' className={classes.text}>
              {data_preferrer.comments}
            </Typography>
          </>
        }
      </CardContent>
    </Card>
  );
}
