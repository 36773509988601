import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
} from "@material-ui/core";
import { Facebook as FacebookIcon } from "@material-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";

// styles
import useStyles from "./styles";

// logo
import logo from "./logo.svg";
import google from "../../images/google.svg";
import carzcloudLogo from "../../images/carzcloud.png";

// context
import { useUserDispatch } from "../../context/UserContext";
import { RegisterUser, PhoneNumberDialog } from "./components";
import { useToastDispatch } from "../../context/ToastContext";
import EventTriggeredAnalytics from '../../components/Analytics/EventTriggeredAnalytics';

const LOGIN = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      token
      user {
        id
      }
    }
  }
`;
const LOGIN_WITH_SOCIAL = gql`
  mutation loginWithSocial($input: LoginWithSocialInput!) {
    loginWithSocial(input: $input) {
      token
      user {
        id
      }
    }
  }
`;
const REGISTER_USER = gql`
  mutation registerUser($input: RegisterUserInput!) {
    registerUser(input: $input)
  }
`;
function Login(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();
  const toastDispatch = useToastDispatch();
  // local
  var [isLoading, setIsLoading] = useState(false);
  var [activeTabId, setActiveTabId] = useState(0);
  const [phoneDialogOpen, setPhoneDialogOpen] = useState(false);
  const [formData, setFormData] = useState({});
  const { register, handleSubmit, errors } = useForm();
  const [loginMutation, { error: errorLogin }] = useMutation(LOGIN);
  const [loginWithSocialMutation] = useMutation(LOGIN_WITH_SOCIAL);
  const [registerUser] = useMutation(REGISTER_USER);
  const login = data => {
    loginMutation({
      variables: {
        input: {
          email: data.email,
          password: data.password,
        },
      },
    }).then(res => {
      setIsLoading(false);
      userDispatch({ type: "LOGIN_SUCCESS" });
      localStorage.setItem("id_token", res.data.login.token);
      EventTriggeredAnalytics('Login', 'User Logged');
      props.history.push("/app/dashboard");
    });
  };

  const handleSubmitRegisterUser = value => {
    const inputValue = {
      name: value.name,
      email: value.email,
      password: value.password,
      phone: value.phone,
      setup: value.setup,
    };
    registerUser({ variables: { input: inputValue } })
      .then(res => {
        setActiveTabId(0);
        toastDispatch({
          isOpen: true,
          type: "success",
          message: "User is successfully registered",
        });
      })
      .catch(error => {
        toastDispatch({
          isOpen: true,
          type: "error",
          message: error.message,
        });
      });
  };
  const handleGoogleError = err => {
    console.log("google errors", err);
  };
  const handleGoogleSuccess = response => {
    setFormData(response);
    loginWithSocialMutation({
      variables: {
        input: {
          name: response.name,
          email: response.email,
        },
      },
    })
      .then(res => {
        setIsLoading(false);
        userDispatch({ type: "LOGIN_SUCCESS" });
        localStorage.setItem("id_token", res.data.loginWithSocial.token);
        props.history.push("/app/dashboard");
      })
      .catch(err => {
        if (err.message === "MISSING_PHONE") {
          setPhoneDialogOpen(true);
        }
      });
  };
  const handleFacebookResponse = response => {
    setFormData(response);
    loginWithSocialMutation({
      variables: {
        input: {
          name: response.name,
          email: response.email,
        },
      },
    })
      .then(res => {
        setIsLoading(false);
        userDispatch({ type: "LOGIN_SUCCESS" });
        localStorage.setItem("id_token", res.data.loginWithSocial.token);
        props.history.push("/app/dashboard");
      })
      .catch(err => {
        if (err.message === "MISSING_PHONE") {
          setPhoneDialogOpen(true);
        }
      });
  };
  const submitWithPhone = phone => {
    setFormData({ ...formData, ...{ phone } });
    loginWithSocialMutation({
      variables: {
        input: {
          name: formData.name,
          email: formData.email,
          phone: phone,
        },
      },
    }).then(res => {
      setIsLoading(false);
      userDispatch({ type: "LOGIN_SUCCESS" });
      localStorage.setItem("id_token", res.data.loginWithSocial.token);
      props.history.push("/app/dashboard");
    });
  };
  return (
    <>
      <PhoneNumberDialog
        isOpen={phoneDialogOpen}
        handleClose={submitWithPhone}
      />
      <Grid container className={classes.container}>
        <div className={classes.logotypeContainer}>
          <img src={carzcloudLogo} alt="CarzCloud logo" className={classes.logotypeImage} />
        </div>
        <div className={classes.formContainer}>
          <div className={classes.form}>
            <Tabs
              value={activeTabId}
              onChange={(e, id) => setActiveTabId(id)}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="Login" classes={{ root: classes.tab }} />
              <Tab label="Registro" classes={{ root: classes.tab }} />
            </Tabs>
            {activeTabId === 0 && (
              <React.Fragment>
                <Typography variant="h1" className={classes.greeting}>
                  Bienvenido
                </Typography>
                {/* <FacebookLogin
                  appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                  autoLoad={true}
                  fields="name,email"
                  render={renderProps => (
                    <Button
                      size="large"
                      className={classes.fbButton}
                      onClick={renderProps.onClick}
                    >
                      <FacebookIcon />
                      &nbsp;&nbsp;&nbsp;Ingresá con Facebook
                    </Button>
                  )}
                  callback={handleFacebookResponse}
                />
                <GoogleLogin
                  clientId="48407676168-upot1lda23bf8l1qretljkgplqtns3m3.apps.googleusercontent.com"
                  render={renderProps => (
                    <Button
                      size="large"
                      className={classes.googleButton}
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                    >
                      <img
                        src={google}
                        alt="google"
                        className={classes.googleIcon}
                      />
                      &nbsp;Ingresá con Google
                    </Button>
                  )}
                  buttonText="Login"
                  onSuccess={handleGoogleSuccess}
                  onFailure={handleGoogleError}
                  cookiePolicy={"single_host_origin"}
                /> */}
                {/* <div className={classes.formDividerContainer}>
                  <div className={classes.formDivider} />
                  <Typography className={classes.formDividerWord}>
                    O
                  </Typography>
                  <div className={classes.formDivider} />
                </div> */}
                <Fade in={!!errorLogin}>
                  <Typography
                    color="secondary"
                    className={classes.errorMessage}
                  >
                    {errorLogin
                      ? errorLogin.message
                      : "Usuario o contraseña incorrecta :("}
                  </Typography>
                </Fade>
                <form onSubmit={handleSubmit(login)} noValidate={true}>
                  <TextField
                    id="email"
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                    }}
                    name="email"
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : ""}
                    InputLabelProps={{ required: true }}
                    inputRef={register({
                      required: "El email es requerido",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: "invalid email address",
                      },
                    })}
                    margin="normal"
                    placeholder="Email"
                    type="email"
                    fullWidth
                  />
                  <TextField
                    id="password"
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                    }}
                    error={!!errors.password}
                    helperText={errors.password ? errors.password.message : ""}
                    InputLabelProps={{ required: true }}
                    inputRef={register({
                      required: "La constraseña es requerida",
                    })}
                    name="password"
                    margin="normal"
                    placeholder="Contraseña"
                    type="password"
                    fullWidth
                  />
                  <div className={classes.formButtons}>
                    {isLoading ? (
                      <CircularProgress
                        size={26}
                        className={classes.loginLoader}
                      />
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        type="submit"
                      >
                        Login
                      </Button>
                    )}
                    <Button
                      color="primary"
                      size="large"
                      className={classes.forgetButton}
                      component={RouterLink}
                      to="reset-password"
                    >
                      Olvidé la contraseña
                    </Button>
                  </div>
                </form>
              </React.Fragment>
            )}
            {activeTabId === 1 && (
              <RegisterUser onSubmit={handleSubmitRegisterUser} />
            )}
          </div>
          <Typography color="primary" className={classes.copyright} style={{textAlign:"center"}}>
            © 2023 carzcloud.com. Todos los derechos reservados. <br/> <a target="_blank" rel="noopener noreferrer" href="https://carzcloud.com/privacy-policy">Privacidad</a>
          </Typography>
        </div>
      </Grid>
    </>
  );
}

export default Login;
