import React from "react";
import ReactGA from 'react-ga';

const EventTriggeredAnalytics = (category, action) => {
    ReactGA.event({
        category: category,
        action: action
      });
 }

 export default EventTriggeredAnalytics;