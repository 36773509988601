// store.js
import React, { createContext, useReducer, useContext } from "react";

const ToastStateContext = createContext();
const ToastDispatchContext = createContext();

const initialState = { isOpen: false, type: "", message: "" };
function toastReducer(state, action) {
  return action
}

function ToastProvider({ children }) {
  const [state, dispatch] = useReducer(toastReducer, initialState);

  return (
    <ToastStateContext.Provider value={state}>
      <ToastDispatchContext.Provider value={dispatch}>
        {children}
      </ToastDispatchContext.Provider>
    </ToastStateContext.Provider>
  );
}

function useToastState() {
  var context = useContext(ToastStateContext);
  if (context === undefined) {
    throw new Error("useToastState must be used within a ToastProvider");
  }
  return context;
}

function useToastDispatch() {
  var context = useContext(ToastDispatchContext);
  if (context === undefined) {
    throw new Error("useToastDispatch must be used within a ToastProvider");
  }
  return context;
}

export { ToastProvider, useToastState, useToastDispatch };