import React, { } from 'react';
import gql from 'graphql-tag';
import { useMutation } from "@apollo/client";
import { makeStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useForm } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
    },
    form: {
        display: 'flex',
        flexDirection: 'column'
    },
    formControl: {
        margin: '0.5rem 0 !important',
        width: '20rem'
    }
}));

const MARK_AS_SOLD = gql`
  mutation MarkAsSold($input: MarkAsSoldInput!) {
    markAsSold(input: $input)
  }
`;
export const MarkAsSoldDialog = ({ isOpen, handleClose, id }) => {
    const classes = useStyles();
    const { register, handleSubmit } = useForm();
    const [markAsSold] = useMutation(MARK_AS_SOLD);
    const onSubmit = data => {
        markAsSold({
            variables: {
                input: {
                    id: id,
                    sellingPrice: parseFloat(data.sellingPrice),
                }
            }
        }).then(() => handleClose())
    }
    return (
        <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle id="form-dialog-title">Ingrese precio de venta</DialogTitle>
                <DialogContent>
                    <TextField className={classes.formControl} name="sellingPrice" id="outlined-basic" label="precio de venta" variant="outlined" inputRef={register} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cancel</Button>
                    <Button color="primary" type="submit">Save</Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}
