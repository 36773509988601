import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  Breadcrumbs,
  Link,
} from "@material-ui/core";
import { Link as RouterLink, useParams } from "react-router-dom";
import { gql, useMutation, useQuery, useApolloClient } from "@apollo/client";
import { UserForm } from "../components";
import { useToastDispatch } from "../../../context/ToastContext";

const GET_USER = gql`
  query getUser($id: ID!) {
    user(id: $id) {
      id
      name
      phone
      email
      role
      active
      account {
        id
      }
      comments
      setup
    }
  }
`;

const EDIT_USER = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
    }
  }
`;
const GET_ACCOUNTS_LIST = gql`
  query getAccounts {
    accounts {
      nodes {
        id
        name
      }
    }
  }
`;

export const UserEdit = () => {
  const client = useApolloClient();
  const { id } = useParams();
  const toastDispatch = useToastDispatch();

  const {
    loading: loadingUser,
    error: errorLoadingUser,
    data: user,
  } = useQuery(GET_USER, {
    variables: {
      id,
    },
  });

  const {
    data: accounts,
    loading: loadingAccounts,
    error: errorLoadingAccounts,
  } = useQuery(GET_ACCOUNTS_LIST);

  const [updateUser, { loading: mutationLoading }] = useMutation(EDIT_USER);

  if (loadingUser || loadingAccounts) {
    return <p>Cargando...</p>;
  }
  if (errorLoadingUser || errorLoadingAccounts) {
    return <p>Error</p>;
  }
  const handleSubmit = value => {
    const input = {
      id: id,
      name: value.name,
      phone: value.phone,
      email: value.email,
      password: value.password,
      role: value.role,
      active: value.active,
      accountId: value.accountId ? parseInt(value.accountId) : null ,
      comments: value.comments,
      setup: value.setup,
    };
    updateUser({ variables: { input } }).then(() => {
      client.clearStore();
      toastDispatch({
        isOpen: true,
        type: "success",
        message: "El usuario fue creado exitosamente",
      });
    });
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={8}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" component={RouterLink} to="/admin/users">
            User
          </Link>
          <Link
            color="textPrimary"
            component={RouterLink}
            to={`/admin/users/edit/${id}`}
            aria-current="page"
          >
            Editar Usuario
          </Link>
        </Breadcrumbs>
        <Card>
          <CardHeader title="Editar Usuario" />
          <CardContent>
            {user.user ? (
              <UserForm
                accounts={accounts.accounts.nodes}
                data={user.user}
                loading={mutationLoading}
                submitText="Editar"
                onSubmit={handleSubmit}
              />
            ) : (
              <p>No se encontraron registros</p>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
