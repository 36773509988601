import React, { useEffect } from "react";
import Toasts from "./Toasts";
import Router from "./Router";
import ReactGA from 'react-ga';
import {
  Switch,
  useLocation,
  Route
} from "react-router-dom";

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_NO);

export default function App() {
  let location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.hash);
  }, [location]);
  return (
    <>
      <Toasts />
      <Router />
    </>
  );
}
