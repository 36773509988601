import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  split,
} from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";

import { setContext } from "@apollo/client/link/context";

import { createUploadLink } from "apollo-upload-client";

import Themes from "./themes";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
import { ToastProvider } from "./context/ToastContext";
import { getMainDefinition } from "@apollo/client/utilities";
import {
  BrowserRouter as Router,
  Switch,
  useLocation,
  Route
} from "react-router-dom";

const dotenv = require('dotenv');
const path = require('path');

function loadEnv() {
  const environment = process.env.NODE_ENV || 'development';
  // const environment = "production";
  const envFilePath = path.resolve(__dirname, `.env.${environment}`);
  try {
    dotenv.config( { path: envFilePath, overwrite: true });
  } catch(error) {
    console.log("ENV not defined");
  }
}

loadEnv();

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_SURBSCRIPTION_URL,
  options: { reconnect: true },
});
const uploadLink = createUploadLink({ uri: process.env.REACT_APP_SERVER_URL });
const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  uploadLink,
);
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("id_token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([authLink, link]),
});
ReactDOM.render(
  <ApolloProvider client={client}>
    <ToastProvider>
      <LayoutProvider>
        <UserProvider>
          <ThemeProvider theme={Themes.default}>
            <CssBaseline />
            <Router>
            <App />
            </Router>
          </ThemeProvider>
        </UserProvider>
      </LayoutProvider>
    </ToastProvider>
  </ApolloProvider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
