import React from "react";
import { Link } from '@material-ui/core';
import PageviewIcon from '@material-ui/icons/Pageview';
import { gql, useMutation, useQuery } from "@apollo/client";

const UPDATE_VISITED = gql`
    mutation updateVisited($input: UpdateVisitedInput!) {
        updateVisited(input: $input) 
    }
`;

const PROFILE = gql`
  query Profile {
    profile {
      carListingVisitedId
    }
  }
  `;

export default function DisplayDetailsLinkButton(props) {
    const { data: profile } = useQuery(PROFILE);
    const checkUnique = (id) => {
        if (profile !== undefined) {
            if (profile.profile.carListingVisitedId !== null) {
                var spiltString = JSON.parse(profile.profile.carListingVisitedId)
                return (spiltString.indexOf(id) !== -1) ? true : false;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    var classVisited = checkUnique(props.id);
    const [updateVisited, { loading: mutationLoading }] = useMutation(UPDATE_VISITED);

    return (
        <>
            <Link
                href={props.url}
                onClick={(val) => {
                    val.target.style.color = 'rgba(0, 0, 0, 0.26)'
                    updateVisited({ variables: { input: { id: props.id } } });
                }}
                target="_blank"
                rel="noopener"
            >
                <PageviewIcon color={(classVisited) ? 'disabled' : 'primary'} />
            </Link>
        </>
    )
}

