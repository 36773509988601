import React from "react";
import PropTypes from "prop-types";
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Switch,
  FormControlLabel,
  Button,
  FormHelperText,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { BrandForm } from "../../../../components/BrandForm/BrandForm";
import { ModelForm } from "../../../../components/ModelForm/ModelForm";
import { VersionForm } from "../../../../components/VersionForm/VersionForm";

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  form: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: "5px 5px",
    flex: "1 1 30%",
    textAlign: "left",
  },
  formControlSingle: {
    margin: "5px 5px",
    flex: "0 0 32.2%",
    textAlign: "left",
  },
  actionButtons: {
    flex: "1 1 100%",
    display: "flex",
    alignItems: "flex-end",
    marginTop: '1rem',
  },
}));

export const VehicleData = ({ pushInput, data }) => {
  const classes = useStyles();
  const { register, handleSubmit, errors, control } = useForm();

  return (
    <form onSubmit={handleSubmit(pushInput)} noValidate>
      <div className={classes.form}>
        <BrandForm control={control} errors={errors} data={data} />
        <ModelForm
          control={control}
          errors={errors}
          data={data}
        />
        <VersionForm
          control={control}
          errors={errors}
          data={data}
        />
        <TextField
          className={classes.formControl}
          defaultValue={data.year}
          error={errors.year ? true : false}
          helperText={errors.year ? errors.year.message : ""}
          InputLabelProps={{ required: true }}
          inputRef={register({ required: "year is required" })}
          label="Año"
          name="year"
          type="number"
          variant="outlined"
        />
        <FormControl
          variant="outlined"
          required
          className={classes.formControl}
        >
          <InputLabel>Combustible</InputLabel>
          <Controller
            as={
              <Select error={errors.condtion ? true : false}>
                <MenuItem value="Nafta">Nafta</MenuItem>
                <MenuItem value="Diesel">Diesel</MenuItem>
                <MenuItem value="GNC">GNC</MenuItem>
              </Select>
            }
            control={control}
            defaultValue={data.fuelType}
            label="Combustble"
            name="fuelType"
            rules={{ required: "combustble is required" }}
          />
          <FormHelperText error={true}>
            {errors.fuelType && errors.fuelType.message}
          </FormHelperText>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel>Puertas</InputLabel>
          <Controller
            as={
              <Select>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="4">4</MenuItem>
                <MenuItem value="5">5</MenuItem>
              </Select>
            }
            control={control}
            defaultValue={data.doors}
            label="Puertas"
            name="doors"
          />
        </FormControl>
        <FormControl
          variant="outlined"
          required
          className={classes.formControl}
        >
          <InputLabel>Condición (nuevo/usado)</InputLabel>
          <Controller
            as={
              <Select error={errors.condtion ? true : false}>
                <MenuItem value="Nuevo">Nuevo</MenuItem>
                <MenuItem value="Usado">Usado</MenuItem>
              </Select>
            }
            control={control}
            defaultValue={data.condtion}
            label="Condición (nuevo/usado)"
            name="condtion"
            rules={{ required: "condición is required" }}
          />
          <FormHelperText error={true}>
            {errors.condtion && errors.condtion.message}
          </FormHelperText>
        </FormControl>
        <TextField
          className={classes.formControl}
          defaultValue={data.mileage}
          inputRef={register}
          label="KM"
          name="mileage"
          type="number"
          variant="outlined"
        />
        <TextField
          className={classes.formControl}
          defaultValue={data.color}
          inputRef={register}
          label="Color"
          name="color"
          variant="outlined"
        />
        <TextField
          className={classes.formControl}
          defaultValue={data.carType}
          error={errors.carType ? true : false}
          helperText={errors.carType ? errors.carType.message : ""}
          InputLabelProps={{ required: true }}
          inputRef={register({ required: "tip de carrocería is required" })}
          label="Tipo de carrocería (sej. Sedan)"
          name="carType"
          variant="outlined"
        />
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel>Transmisión</InputLabel>
          <Controller
            as={
              <Select error={errors.transmissionType ? true : false}>
                <MenuItem value="Manual">Manual</MenuItem>
                <MenuItem value="Automático">Automático</MenuItem>
              </Select>
            }
            control={control}
            defaultValue={data.transmissionType}
            label="Transmisión"
            name="transmissionType"
            rules={{ required: "transmission is required" }}
          />
          <FormHelperText error={true}>
            {errors.transmissionType && errors.transmissionType.message}
          </FormHelperText>
        </FormControl>
        <FormControlLabel
          className={classes.formControl}
          control={<Switch defaultChecked={data.shareable} />}
          color="primary"
          inputRef={register}
          label="Compartir con otras concesionarias"
          name="shareable"
        />
        <FormControl variant="outlined" className={classes.formControlSingle}>
          <InputLabel>State</InputLabel>
          <Controller
            as={
              <Select>
                <MenuItem value="Publicado">Publicado</MenuItem>
                <MenuItem value="Vendido">Vendido</MenuItem>
                <MenuItem value="Cancelado">Cancelado</MenuItem>
              </Select>
            }
            control={control}
            defaultValue={data.state}
            label="State"
            name="state"
            rules={{ required: "state is required" }}
          />
          <FormHelperText error={true}>
            {errors.state && errors.state.message}
          </FormHelperText>
        </FormControl>
      </div>
      <div className={classes.actionButtons}>
        <Button disabled className={classes.backButton}>
          Atrás
        </Button>
        <Button color="primary" variant="contained" type="submit">
          Siguiente
        </Button>
      </div>
    </form>
  );
};

// VehicleData.propTypes = {
//   pushInput: PropTypes.func.isRequired,
// };
